import actions from './actions';

const initState = {
  allArchitecturalProjects: [],
  allReadyAndCustomProjects: [],
  currentArchitecturalProjects: {},
  allCommercialProjects: [],
  currentCommercialProjects: {},
  allInteriorDesignProjects: [],
  currentInteriorDesignProjects: {},
  allCustomProjects: [],
  currentCustomProjects: {},
  allInConstructionProjects: [],
  constructionID: '',
  currentInConstructionProjects: {},
  currentFloorsList: [],
  currentFloorsListCommercial: [],
  currentApartmentsList: [],
  currentApartmentsListCommercial: [],
  currentFloorApartmentsList: [],
  optionsAreaTypesList: [],
  currentAreasList: [],
  currentHotspotsList: [],
  currentMediasList: [],
  currentMediasTourList: [],
  current360MediasList : [],
  currentParalaxMediasList: [],
  currentPersonMediasList: [],
  currentPacoteMKTMediasList: [],
  currentCharacteristicsTypeList: [],
  currentCharacteristicsList: [],
  optionsCharacteristicsTypeList: [],
  optionsCharacteristicsList: [],
  enableEditViewArchitectural: false,
  backendError_newArchitecturalProjects: false,
  backendSuccess_newArchitecturalProjects: false,
  backendError_editArchitecturalProjects: false,
  backendSuccess_editArchitecturalProjects: false,
  backendError_newCommercialProjects: false,
  backendSuccess_newCommercialProjects: false,
  backendError_editCommercialProjects: false,
  backendSuccess_editCommercialProjects: false,
  backendError_newInteriorDesignProjects: false,
  backendSuccess_newInteriorDesignProjects: false,
  backendError_editInteriorDesignProjects: false,
  backendSuccess_editInteriorDesignProjects: false,
  backendError_newCustomProjects: false,
  backendSuccess_newCustomProjects: false,
  backendError_editCustomProjects: false,
  backendSuccess_editCustomProjects: false,
  enableEditViewInConstruction: false,
  backendError_newInConstructionProjects: false,
  backendSuccess_newInConstructionProjects: false,
  backendError_editInConstructionProjects: false,
  backendSuccess_editInConstructionProjects: false,
  enableEditViewCommercial: false,
  enableEditViewInteriorDesign: false,
  enableEditViewCustom: false,
  enableEditViewInteriorDesignStyle : false,
  currentInteriorDesignStylesList: [],
  optionsInteriorDesignStylesList: [],
  oldCharacteristicsList: [],
  floorsMedia: {}
};

export default function projectReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CURRENT_INTERIOR_DESIGN_STYLE_LIST:
      return {
        ...state,
        currentInteriorDesignStylesList: action.payload.styles,
      };
    case actions.UPDATE_EDIT_INTERIOR_DESIGN_STYLE_LIST:
      return {
        ...state,
        currentInteriorDesignStylesList: action.payload,
      };
    case actions.GET_OPTIONS_INTERIOR_DESIGN_STYLE:
      return {
        ...state,
        optionsInteriorDesignStylesList: action.payload.styles,
      };
    case actions.GET_ALL_ARCHITECTURAL_PROJECTS:
      return {
        ...state,
        allArchitecturalProjects: action.payload.results,
      };
    case actions.GET_ALL_READY_AND_CUSTOM_PROJECTS:
      return {
        ...state,
        allReadyAndCustomProjects: action.payload.projects,
      };
    case actions.GET_CURRENT_ARCHITECTURAL_PROJECTS:
      return {
        ...state,
        currentArchitecturalProjects: action.payload,
      };
    case actions.GET_ALL_COMMERCIAL_PROJECTS:
      return {
        ...state,
        allCommercialProjects: action.payload.projects,
      };
    case actions.GET_CURRENT_COMMERCIAL_PROJECTS:
      return {
        ...state,
        currentCommercialProjects: action.payload.project,
      };
    case actions.GET_ALL_INTERIOR_DESIGN_PROJECTS:
      return {
        ...state,
        allInteriorDesignProjects: action.payload.projects,
      };
    case actions.GET_CURRENT_INTERIOR_DESIGN_PROJECTS:
      return {
        ...state,
        currentInteriorDesignProjects: action.payload.project,
      };
    case actions.GET_ALL_CUSTOM_PROJECTS:
      return {
        ...state,
        allCustomProjects: action.payload.projects,
      };
    case actions.GET_CURRENT_CUSTOM_PROJECTS:
      return {
        ...state,
        currentCustomProjects: action.payload.project,
      };
    case actions.GET_ALL_IN_CONSTRUCTION_PROJECTS:
      return {
        ...state,
        allInConstructionProjects: action.payload.projects,
      };
    case actions.GET_CURRENT_IN_CONSTRUCTION_PROJECTS:
      return {
        ...state,
        currentInConstructionProjects: action.payload.project,
      };
    case actions.TOGGLE_VIEW_CUSTOM:
      return {
        ...state,
        enableEditViewCustom: action.view,
        backendError_editCustomProjects: false,
        backendSuccess_editCustomProjects: false
      };
    case actions.NEW_CUSTOM_PROJECTS_RESET:
      return {
        ...state,
        backendSuccess_newCustomProjects: false,
        backendError_newCustomProjects: false
      };
    case actions.NEW_CUSTOM_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_newCustomProjects: false,
        backendError_newCustomProjects: false
      };
    case actions.NEW_CUSTOM_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_newCustomProjects: true,
        backendError_newCustomProjects: false,
      };
    case actions.NEW_CUSTOM_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_newCustomProjects: false,
        backendError_newCustomProjects: true
      };
    case actions.EDIT_CUSTOM_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_editCustomProjects: false,
        backendError_editCustomProjects: false
      };
    case actions.EDIT_CUSTOM_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_editCustomProjects: true,
        backendError_editCustomProjects: false
      };
    case actions.EDIT_CUSTOM_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_editCustomProjects: false,
        backendError_editCustomProjects: true
      };
    case actions.TOGGLE_VIEW_INTERIOR_DESIGN:
      return {
        ...state,
        enableEditViewInteriorDesign: action.view,
        backendError_editInteriorDesignProjects: false,
        backendSuccess_editInteriorDesignProjects: false
      };
    case actions.NEW_INTERIOR_DESIGN_PROJECTS_RESET:
      return {
        ...state,
        backendSuccess_newInteriorDesignProjects: false,
        backendError_newInteriorDesignProjects: false
      };
    case actions.NEW_INTERIOR_DESIGN_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_newInteriorDesignProjects: false,
        backendError_newInteriorDesignProjects: false
      };
    case actions.NEW_INTERIOR_DESIGN_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_newInteriorDesignProjects: true,
        backendError_newInteriorDesignProjects: false,
      };
    case actions.NEW_INTERIOR_DESIGN_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_newInteriorDesignProjects: false,
        backendError_newInteriorDesignProjects: true
      };
    case actions.EDIT_INTERIOR_DESIGN_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_editInteriorDesignProjects: false,
        backendError_editInteriorDesignProjects: false
      };
    case actions.EDIT_INTERIOR_DESIGN_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_editInteriorDesignProjects: true,
        backendError_editInteriorDesignProjects: false
      };
    case actions.EDIT_INTERIOR_DESIGN_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_editInteriorDesignProjects: false,
        backendError_editInteriorDesignProjects: true
      };
    case actions.TOGGLE_VIEW_ARCHITECTURAL:
      return {
        ...state,
        enableEditViewArchitectural: action.view,
        backendError_editArchitecturalProjects: false,
        backendSuccess_editArchitecturalProjects: false
      };
    case actions.NEW_ARCHITECTURAL_PROJECTS_RESET:
      return {
        ...state,
        backendSuccess_newArchitecturalProjects: false,
        backendError_newArchitecturalProjects: false
      };
    case actions.NEW_ARCHITECTURAL_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_newArchitecturalProjects: false,
        backendError_newArchitecturalProjects: false
      };
    case actions.NEW_ARCHITECTURAL_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_newArchitecturalProjects: true,
        backendError_newArchitecturalProjects: false,
      };
    case actions.NEW_ARCHITECTURAL_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_newArchitecturalProjects: false,
        backendError_newArchitecturalProjects: true
      };
    case actions.EDIT_ARCHITECTURAL_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_editArchitecturalProjects: false,
        backendError_editArchitecturalProjects: false
      };
    case actions.EDIT_ARCHITECTURAL_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_editArchitecturalProjects: true,
        backendError_editArchitecturalProjects: false
      };
    case actions.EDIT_ARCHITECTURAL_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_editArchitecturalProjects: false,
        backendError_editArchitecturalProjects: true
      };
    case actions.TOGGLE_VIEW_COMMERCIAL:
      return {
        ...state,
        enableEditViewCommercial: action.view,
        backendError_editCommercialProjects: false,
        backendSuccess_editCommercialProjects: false
      };
    case actions.NEW_COMMERCIAL_PROJECTS_RESET:
      return {
        ...state,
        backendSuccess_newCommercialProjects: false,
        backendError_newCommercialProjects: false
      };
    case actions.NEW_COMMERCIAL_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_newCommercialProjects: false,
        backendError_newCommercialProjects: false
      };
    case actions.NEW_COMMERCIAL_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_newCommercialProjects: true,
        backendError_newArchitecturalProjects: false,
      };
    case actions.NEW_COMMERCIAL_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_newCommercialProjects: false,
        backendError_newCommercialProjects: true
      };
    case actions.EDIT_COMMERCIAL_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_editCommercialProjects: false,
        backendError_editCommercialProjects: false
      };
    case actions.EDIT_COMMERCIAL_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_editCommercialProjects: true,
        backendError_editCommercialProjects: false
      };
    case actions.EDIT_COMMERCIAL_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_editCommercialProjects: false,
        backendError_editCommercialProjects: true
      };
    case actions.TOGGLE_VIEW_IN_CONSTRUCTION:
      return {
        ...state,
        backendError_editInConstructionProjects: false,
        backendSuccess_editInConstructionProjects: false,
        enableEditViewInConstruction: action.view,
      };
    case actions.NEW_IN_CONSTRUCTION_PROJECTS_RESET:
      return {
        ...state,
        backendSuccess_newInConstructionProjects: false,
        backendError_newInConstructionProjects: false
      };
    case actions.NEW_IN_CONSTRUCTION_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_newInConstructionProjects: false,
        backendError_newInConstructionProjects: false
      };
    case actions.NEW_IN_CONSTRUCTION_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_newInConstructionProjects: true,
        backendError_newInConstructionProjects: false,
        constructionID: action.payload.project.constructionID
      };
    case actions.NEW_IN_CONSTRUCTION_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_newInConstructionProjects: false,
        backendError_newInConstructionProjects: true
      };
    case actions.EDIT_IN_CONSTRUCTION_PROJECTS_LOADING:
      return {
        ...state,
        backendSuccess_editInConstructionProjects: false,
        backendError_editInConstructionProjects: false
      };
    case actions.EDIT_IN_CONSTRUCTION_PROJECTS_SUCCESS:
      return {
        ...state,
        backendSuccess_editInConstructionProjects: true,
        backendError_editInConstructionProjects: false
      };
    case actions.EDIT_IN_CONSTRUCTION_PROJECTS_ERROR:
      return {
        ...state,
        backendSuccess_editInConstructionProjects: false,
        backendError_editInConstructionProjects: true
      };
    case actions.NEW_FLOORS_MEDIA:
      return {
        ...state,
        floorsMedia: actions.payload,
      }
    case actions.UPDATE_EDIT_FLOORS_LIST:
      return {
        ...state,
        currentFloorsList: action.payload,
      };
    case actions.GET_CURRENT_FLOORS_LIST:
      return {
        ...state,
        currentFloorsList: action.payload,
      };
    case actions.GET_CURRENT_FLOORS_LIST_COMMERCIAL:
      return {
        ...state,
        currentFloorsListCommercial: action.payload.floors,
      };
    case actions.UPDATE_EDIT_APARTMENTS_LIST:
      return {
        ...state,
        currentApartmentsList: action.payload,
      };
    case actions.GET_CURRENT_APARTMENTS_LIST_COMMERCIAL:
      return {
        ...state,
        currentApartmentsListCommercial: action.payload.apartments,
      };
    case actions.GET_CURRENT_FLOOR_APARTMENT_LIST:
      return {
        ...state,
        currentFloorApartmentsList: action.payload.apartments,
      };

    case actions.UPDATE_EDIT_FLOOR_APARTMENT_LIST:
      return {
        ...state,
        currentFloorApartmentsList: action.payload,
      };
    case actions.GET_OPTIONS_AREA_TYPES:
      return {
        ...state,
        optionsAreaTypesList: action.payload,
      };
    case actions.UPDATE_EDIT_AREAS_LIST:
      return {
        ...state,
        currentAreasList: action.payload,
      };
    case actions.GET_CURRENT_AREAS_LIST:
      return {
        ...state,
        currentAreasList: action.payload,
      };
    case actions.UPDATE_EDIT_HOTSPOTS_LIST:
      return {
        ...state,
        currentHotspotsList: action.payload,
      };
    case actions.GET_CURRENT_HOTSPOTS_LIST:
      return {
        ...state,
        currentHotspotsList: action.payload.hotspots,
      };
    case actions.UPDATE_EDIT_MEDIAS_LIST:
      return {

        ...state,
        currentMediasList: action.payload,
      };
    case actions.UPDATE_EDIT_PARALAX_MEDIAS_LIST:
      return {
        ...state,
        currentParalaxMediasList: action.payload,
      };
    case actions.UPDATE_EDIT_PERSON_MEDIAS_LIST:
      return {
        ...state,
        currentPersonMediasList: action.payload,
      };
    case actions.UPDATE_EDIT_PACOTEMKT_MEDIAS_LIST:
      return {
        ...state,
        currentPacoteMKTMediasList: action.payload,
      };
    case actions.GET_CURRENT_MEDIAS_LIST:
      return {
        ...state,
        currentMediasList: action.payload,
      };
    case actions.UPDATE_EDIT_MEDIAS_TOUR_LIST:
      return {
        ...state,
        currentMediasTourList: action.payload,
      };
    case actions.UPDATE_EDIT_360_MEDIAS_LIST:
      return {
        ...state,
        current360MediasList: action.payload,
      };
    case actions.GET_CURRENT_MEDIAS_TOUR_LIST:
      return {
        ...state,
        currentMediasTourList: action.payload.medias,
      }
    case actions.GET_CURRENT_360_MEDIAS_LIST:
      return {
        ...state,
        current360MediasList: action.payload.medias,
      }
    case actions.GET_CURRENT_PARALAX_MEDIAS_LIST:
      return {
        ...state,
        currentParalaxMediasList: action.payload.medias,
      };
    case actions.GET_CURRENT_PERSON_MEDIAS_LIST:
      return {
        ...state,
        currentPersonMediasList: action.payload.medias,
      };
    case actions.GET_CURRENT_PACOTEMKT_MEDIAS_LIST:
      return {
        ...state,
        currentPacoteMKTMediasList: action.payload.medias,
      };
    case actions.UPDATE_EDIT_CHARACTERISTICS_TYPES_LIST:
      return {
        ...state,
        currentCharacteristicsTypeList: action.payload,
      };
    case actions.GET_CURRENT_CHARACTERISTICS_TYPES_LIST:
      return {
        ...state,
        currentCharacteristicsTypeList: action.payload,
      };
    case actions.UPDATE_EDIT_CHARACTERISTICS_LIST:
      return {
        ...state,
        currentCharacteristicsList: action.payload,
      };
    case actions.GET_OLD_CHARACTERISTICS_LIST:
      return {
        ...state,
        oldCharacteristicsList: action.payload,
      };
    case actions.GET_CURRENT_CHARACTERISTICS_LIST:
      return {
        ...state,
        currentCharacteristicsList: action.payload,
      };
    case actions.GET_OPTIONS_CHARACTERISTICS_TYPE:
      return {
        ...state,
        optionsCharacteristicsTypeList: action.payload,
      };
    case actions.GET_OPTIONS_CHARACTERISTICS:
      return {
        ...state,
        optionsCharacteristicsList: action.payload,
      };
    case actions.CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        currentArchitecturalProjects: {}
      }
    default:
      return state;
  }
}