import areaTypeActions from './actions';

const initState = {
  allAreaTypes: []
};

export default function areaTypeReducer(state = initState, action) {
  switch (action.type) {
    case areaTypeActions.GET_ALL_AREA_TYPES:
      return {
        ...state,
        allAreaTypes: action.payload.areaTypes,
      };
    case areaTypeActions.UPDATE_EDIT_AREA_TYPES_LIST:
      return {
        ...state,
        allAreaTypes: action.payload,
      };
    default:
      return state;
  }
}
