import actions from './actions';

const initState = {
  backendError_newFeedback: false,
  backendSuccess_newFeedback: false,
  backendError_editFeedback: false,
  backendSuccess_editFeedback: false,
  currentFeedback : {},
  allFeedbacks : [],
  enableEditViewFeedback : false,
  currentMediaList : [],
  feedbackID : ''
}

export default function feedbackReducer(state = initState, action) {
  switch (action.type) {
    case actions.NEW_FEEDBACK_RESET:
      return {
        ...state,
        backendSuccess_newFeedback: false,
        backendError_newFeedback: false
      };
    case actions.NEW_FEEDBACK_LOADING:
      return {
        ...state,
        backendSuccess_newFeedback: false,
        backendError_newFeedback: false
      };
    case actions.NEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        backendSuccess_newFeedback: true,
        backendError_newFeedback: false,
        feedbackID : action.payload.feedback.feedbackID
      };
    case actions.NEW_FEEDBACK_ERROR:
      return {
        ...state,
        backendSuccess_newFeedback: false,
        backendError_newFeedback: true
      };
    case actions.EDIT_FEEDBACK_LOADING:
      return {
        ...state,
        backendSuccess_editFeedback: false,
        backendError_editFeedback: false
      };
    case actions.EDIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        backendSuccess_editFeedback: true,
        backendError_editFeedback: false,
      };
    case actions.EDIT_FEEDBACK_ERROR:
      return {
        ...state,
        backendSuccess_editFeedback: false,
        backendError_editFeedback: true
      };
    case actions.GET_CURRENT_FEEDBACK:
      return {
        ...state,
        backendSuccess_newFeedback: false,
        backendError_newFeedback: false,
        currentFeedback: action.payload.feedback,
      };
    case actions.GET_ALL_FEEDBACKS:
      return {
        ...state,
        allFeedbacks: action.payload.feedbacks,
      };
    case actions.GET_CURRENT_MEDIA_LIST_FEEDBACK:
      return {
        ...state,
        currentMediaList: action.payload.medias,
      };
    case actions.TOGGLE_VIEW_FEEDBACK:
      return {
        ...state,
        enableEditViewFeedback: action.view,
        backendError_editFeedback: false,
        backendSuccess_editFeedback: false,
      };
    case actions.UPDATE_EDIT_MEDIAS_LIST:
      return {

        ...state,
        currentMediaList: action.payload,
      };
    default:
      return state;
  }
}