import globals from "../../globals";
import axios from "axios";

const partnersActions = {
  GET_ALL_PHYSICAL_PARTNERS: "GET_ALL_PHYSICAL_PARTNERS",
  GET_ALL_LEGAL_PARTNERS: "GET_ALL_LEGAL_PARTNERS",
  GET_CURRENT_PHYSICAL_PARTNER: "GET_CURRENT_PHYSICAL_PARTNER",
  GET_CURRENT_PHYSICAL_PARTNER_LOADING: "GET_CURRENT_PHYSICAL_PARTNER_LOADING",
  GET_ALL_PARTNER_PLANS: "GET_ALL_PARTNER_PLANS",
  GET_ALL_PARTNER_PLANS_LOADING: "GET_ALL_PARTNER_PLANS_LOADING",
  GET_CURRENT_LEGAL_PARTNER: "GET_CURRENT_LEGAL_PARTNER",
  GET_CURRENT_LEGAL_PARTNER_LOADING: "GET_CURRENT_LEGAL_PARTNER_LOADING",

  getAllPhysicalPartners: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/physicalPartners/all`)
        .then((resp) => {
          dispatch({
            type: partnersActions.GET_ALL_PHYSICAL_PARTNERS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getAllLegalPartners: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/legalPartners/all`)
        .then((resp) => {
          dispatch({
            type: partnersActions.GET_ALL_LEGAL_PARTNERS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  getCurrentPhysicalPartner: (physicalPartnerID) => (dispatch) => {
    dispatch({ type: partnersActions.GET_CURRENT_PHYSICAL_PARTNER_LOADING });
    axios
      .get(`${globals.API_URL}/physicalPartners/id/${physicalPartnerID}`)
      .then((resp) =>
        dispatch({
          type: partnersActions.GET_CURRENT_PHYSICAL_PARTNER,
          payload: resp.data,
        })
      )
      .catch((error) => {
        console.log("Houve um erro interno. " + error);
      });
  },
  changeStatusOfCurrentPhysicalPartner: (physicalPartnerID, status) => (dispatch) => {
    axios
      .put(`${globals.API_URL}/physicalPartners/id/${physicalPartnerID}/status`, { status: status })
      .then((resp) =>
        dispatch(partnersActions.getCurrentPhysicalPartner(physicalPartnerID))
      )
      .catch((error) => {
        console.log("Houve um erro interno. " + error);
      });
  },
  getCurrentLegalPartner: (legalPartnerID) => (dispatch) => {
    dispatch({ type: partnersActions.GET_CURRENT_LEGAL_PARTNER_LOADING });
    axios
      .get(`${globals.API_URL}/legalPartners/id/${legalPartnerID}`)
      .then((resp) =>
        dispatch({
          type: partnersActions.GET_CURRENT_LEGAL_PARTNER,
          payload: resp.data,
        })
      )
      .catch((error) => {
        console.log("Houve um erro interno. " + error);
      });
  },
  changeStatusOfCurrentLegalPartner: (legalPartnerID, status) => (dispatch) => {
    axios
      .put(`${globals.API_URL}/legalPartners/id/${legalPartnerID}/status`, { status: status })
      .then((resp) =>
        dispatch(partnersActions.getCurrentLegalPartner(legalPartnerID))
      )
      .catch((error) => {
        console.log("Houve um erro interno. " + error);
      });
  },

  getAllPartnerPlans: () => (dispatch) => {
    dispatch({ type: partnersActions.GET_ALL_PARTNER_PLANS_LOADING });
    axios
      .get(`${globals.API_URL}/partnerPlans/all`)
      .then((resp) => {
        dispatch({
          type: partnersActions.GET_ALL_PARTNER_PLANS,
          payload: resp.data,
        });
      })
      .catch((error) => {
        console.log("Houve um erro interno. " + error);
      });
  },
};

export default partnersActions;
