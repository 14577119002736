import axios from 'axios';
import globals from '../../globals';


const actions = {
  GET_ALL_SALES: 'GET_ALL_SALES',
  GET_SALE: 'GET_SALE',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  UNLOAD_SALES: 'UNLOAD_SALES',

  unloadSales: () => ({ type: actions.UNLOAD_SALES }),

  getAllSales: () => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.get(`${globals.API_URL}/sales/all`)
        .then((resp) => {
          dispatch({
            type: actions.GET_ALL_SALES,
            payload: {
              sales: resp.data.sales
            }
          });
          dispatch({ type: actions.TOGGLE_LOADING });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  getSale: (saleID) => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.get(`${globals.API_URL}/sales/id/${saleID}`)
        .then((resp) => {
          dispatch({
            type: actions.GET_SALE,
            payload: {
              sale: resp.data.sale
            }
          })
          dispatch({ type: actions.TOGGLE_LOADING });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  }
};


export default actions;
