import productActions from './actions';

const initState = {
  allProducts: [],
  currentInteriorDesignProduct : {},
  backendError_newProduct: false,
  backendSuccess_newProduct: false,
  renderLoadingProduct : false,
  enableEditViewProduct : false,
  backendError_editProduct: false,
  backendSuccess_editProduct: false,
  currentMediasList : [],
  interiorDesignProductID : '',
  currentProductsList : [],
  currentDesignWithProductsAndHotspots : {},
  currentHotspot : []
};

export default function interiorDesignProductTypeReducer(state = initState, action) {
  switch (action.type) {
    case productActions.UPDATE_EDIT_PRODUCTS_LIST:
      return {
        ...state,
        currentProductsList: action.payload,
      };
    case productActions.UPDATE_EDIT_HOTSPOTS_LIST:
      return {
        ...state,
        currentHotspot: action.payload,
      };
    case productActions.GET_CURRENT_HOTSPOTS_AND_PRODUCTS_LIST:
      return {
        ...state,
        currentDesignWithProductsAndHotspots: action.payload.project,
      };
    case productActions.GET_CURRENT_HOTSPOT:
      return {
        ...state,
        currentHotspot: action.payload.hotspots,
      };
    case productActions.GET_CURRENT_PRODUCT_LIST:
      return {
        ...state,
        currentProductsList: action.payload.products,
      };
    case productActions.GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload.products,
        backendError_newProduct: false,
        backendSuccess_newProduct: false,
      };
    case productActions.GET_CURRENT_PRODUCT:
      return {
        ...state,
        currentInteriorDesignProduct : action.payload.product,
        renderLoadingProduct : false
      };
    case productActions.GET_CURRENT_PRODUCT_LOADING:
      return {
        ...state,
        renderLoadingProduct : true,
        currentInteriorDesignProduct : {},
      };
    case productActions.UPDATE_EDIT_PRODUCTS_LIST:
      return {
        ...state,
        allProducts: action.payload,
      };
    case productActions.NEW_PRODUCT_LOADING:
      return {
        ...state,
        backendError_newProduct: false,
        backendSuccess_newProduct: false,
      };
    case productActions.NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        backendError_newProduct: false,
        backendSuccess_newProduct: true,
        interiorDesignProductID : action.payload.product.interiorDesignProductID
      };
    case productActions.NEW_PRODUCT_ERROR:
      return {
        ...state,
        backendError_newProduct: true,
        backendSuccess_newProduct: false,
      };
    case productActions.EDIT_PRODUCT_LOADING:
      return {
        ...state,
        backendError_editProduct: false,
        backendSuccess_editProduct: false,
      };
    case productActions.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        backendError_editProduct: false,
        backendSuccess_editProduct: true,
      };
    case productActions.EDIT_PRODUCT_ERROR:
      return {
        ...state,
        backendError_editProduct: true,
        backendSuccess_editProduct: false,
      };
    case productActions.TOGGLE_VIEW_INTERIOR_DESIGN_PRODUCT:
      return {
        ...state,
        enableEditViewProduct : action.view,
        backendError_editProduct: false,
        backendSuccess_editProduct: false,
      };
    case productActions.GET_CURRENT_MEDIAS_LIST:
      return {
        ...state,
        currentMediasList: action.payload.medias
      };
    case productActions.UPDATE_EDIT_MEDIAS_LIST:
      return {
        ...state,
        currentMediasList: action.payload
      };
    default:
      return state;
  }
}