import globals from '../../globals';
import axios from 'axios'

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SESSION_VALIDATED: 'SESSION_VALIDATED',
  validateSession: (token) => {
    return dispatch => {
      if (token) {
        axios.post(`${globals.API_URL}/internalUsers/validateSession`, { token })
          .then(resp => {
            dispatch({ type: actions.SESSION_VALIDATED, payload: resp.data.valid })
          }).catch((error) => {
            console.log("Houve um erro interno. " + error);
            dispatch({ type: actions.SESSION_VALIDATED, payload: false })
          })
      } else {
        dispatch({ type: actions.SESSION_VALIDATED, payload: false })
      }
    }
  },
  login: (values) => {
    return dispatch => {
      axios.post(`${globals.API_URL}/internalUsers/login`, values)
        .then((resp) => {
          if (resp.status === 201) {
            dispatch({ type: actions.LOGIN_ERROR, payload: resp.data })
          } else {
            dispatch({ type: actions.LOGIN_SUCCESS, payload: resp.data })
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },
  logout: () => {
    return dispatch => {
      dispatch({ type: actions.SESSION_VALIDATED, payload: false })
    }
  },
};
export default actions;
