import actions from './actions';



const initState = {
  allElectricalAdditionals: []
};



export default function electricalAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_ELECTRICAL_ADDITIONALS:
            return {
                ...state,
                allElectricalAdditionals: action.payload.electricalAdditionals,
            };
        default:
            return state;
    }
}