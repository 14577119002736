import actions from './actions';


const initState = {
  allPersons: false,
  selectedPerson: null,
  personSuccess: false,
  personError: false,
  personMessage: ""
};


export default function couponReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_PERSONS:
            return {
                ...state,
                allPersons: action.persons,
            };

        case actions.GET_PERSON:
            return {
                ...state,
                selectedPerson: action.person
            }
        case actions.CLEAR_SELECTED_PERSON:
            return {
                ...state,
                selectedPerson: null
            }

        case actions.REPORT_ERROR:
            return {
                ...state,
                personError: true,
                personMessage: action.message
            }

        case actions.REPORT_SUCCESS:
            return {
                ...state,
                personSuccess: true,
                personMessage: action.message
            }

        case actions.CLEAR_STATUS:
            return {
                ...state,
                personSuccess: false,
                personError: false,
                personMessage: ""
            }
        
        default:
            return {
                ...state
            }
    }
}