import globals from "../../globals";
import axios from "axios";

const projectActions = {
  GET_ALL_COMMERCIAL_PROJECTS: "GET_ALL_COMMERCIAL_PROJECTS",
  GET_CURRENT_COMMERCIAL_PROJECTS: "GET_CURRENT_COMMERCIAL_PROJECTS",
  NEW_COMMERCIAL_PROJECTS_RESET: "NEW_COMMERCIAL_PROJECTS_RESET",
  NEW_COMMERCIAL_PROJECTS_LOADING: "NEW_COMMERCIAL_PROJECTS_LOADING",
  NEW_COMMERCIAL_PROJECTS_SUCCESS: "NEW_COMMERCIAL_PROJECTS_SUCCESS",
  NEW_COMMERCIAL_PROJECTS_ERROR: "NEW_COMMERCIAL_PROJECTS_ERROR",
  EDIT_COMMERCIAL_PROJECTS_LOADING: "EDIT_COMMERCIAL_PROJECTS_LOADING",
  EDIT_COMMERCIAL_PROJECTS_SUCCESS: "EDIT_COMMERCIAL_PROJECTS_SUCCESS",
  EDIT_COMMERCIAL_PROJECTS_ERROR: "EDIT_COMMERCIAL_PROJECTS_ERROR",
  GET_ALL_CUSTOM_PROJECTS: "GET_ALL_CUSTOM_PROJECTS",
  GET_CURRENT_CUSTOM_PROJECTS: "GET_CURRENT_CUSTOM_PROJECTS",
  NEW_CUSTOM_PROJECTS_RESET: "NEW_CUSTOM_PROJECTS_RESET",
  NEW_CUSTOM_PROJECTS_LOADING: "NEW_CUSTOM_PROJECTS_LOADING",
  NEW_CUSTOM_PROJECTS_SUCCESS: "NEW_CUSTOM_PROJECTS_SUCCESS",
  NEW_CUSTOM_PROJECTS_ERROR: "NEW_CUSTOM_PROJECTS_ERROR",
  EDIT_CUSTOM_PROJECTS_LOADING: "EDIT_CUSTOM_PROJECTS_LOADING",
  EDIT_CUSTOM_PROJECTS_SUCCESS: "EDIT_CUSTOM_PROJECTS_SUCCESS",
  EDIT_CUSTOM_PROJECTS_ERROR: "EDIT_CUSTOM_PROJECTS_ERROR",
  GET_ALL_INTERIOR_DESIGN_PROJECTS: "GET_ALL_INTERIOR_DESIGN_PROJECTS",
  GET_CURRENT_INTERIOR_DESIGN_PROJECTS: "GET_CURRENT_INTERIOR_DESIGN_PROJECTS",
  NEW_INTERIOR_DESIGN_PROJECTS_RESET: "NEW_INTERIOR_DESIGN_PROJECTS_RESET",
  NEW_INTERIOR_DESIGN_PROJECTS_LOADING: "NEW_INTERIOR_DESIGN_PROJECTS_LOADING",
  NEW_INTERIOR_DESIGN_PROJECTS_SUCCESS: "NEW_INTERIOR_DESIGN_PROJECTS_SUCCESS",
  NEW_INTERIOR_DESIGN_PROJECTS_ERROR: "NEW_INTERIOR_DESIGN_PROJECTS_ERROR",
  EDIT_INTERIOR_DESIGN_PROJECTS_LOADING:
    "EDIT_INTERIOR_DESIGN_PROJECTS_LOADING",
  EDIT_INTERIOR_DESIGN_PROJECTS_SUCCESS:
    "EDIT_INTERIOR_DESIGN_PROJECTS_SUCCESS",
  EDIT_INTERIOR_DESIGN_PROJECTS_ERROR: "EDIT_INTERIOR_DESIGN_PROJECTS_ERROR",
  TOGGLE_VIEW_INTERIOR_DESIGN: "TOGGLE_VIEW_INTERIOR_DESIGN",
  GET_ALL_IN_CONSTRUCTION_PROJECTS: "GET_ALL_IN_CONSTRUCTION_PROJECTS",
  GET_CURRENT_IN_CONSTRUCTION_PROJECTS: "GET_CURRENT_IN_CONSTRUCTION_PROJECTS",
  NEW_IN_CONSTRUCTION_PROJECTS_RESET: "NEW_IN_CONSTRUCTION_PROJECTS_RESET",
  NEW_IN_CONSTRUCTION_PROJECTS_LOADING: "NEW_IN_CONSTRUCTION_PROJECTS_LOADING",
  NEW_IN_CONSTRUCTION_PROJECTS_SUCCESS: "NEW_IN_CONSTRUCTION_PROJECTS_SUCCESS",
  NEW_IN_CONSTRUCTION_PROJECTS_ERROR: "NEW_IN_CONSTRUCTION_PROJECTS_ERROR",
  EDIT_IN_CONSTRUCTION_PROJECTS_LOADING:
    "EDIT_IN_CONSTRUCTION_PROJECTS_LOADING",
  EDIT_IN_CONSTRUCTION_PROJECTS_SUCCESS:
    "EDIT_IN_CONSTRUCTION_PROJECTS_SUCCESS",
  EDIT_IN_CONSTRUCTION_PROJECTS_ERROR: "EDIT_IN_CONSTRUCTION_PROJECTS_ERROR",
  GET_ALL_ARCHITECTURAL_PROJECTS: "GET_ALL_ARCHITECTURAL_PROJECTS",
  GET_ALL_READY_AND_CUSTOM_PROJECTS: "GET_ALL_READY_AND_CUSTOM_PROJECTS",
  GET_CURRENT_ARCHITECTURAL_PROJECTS: "GET_CURRENT_ARCHITECTURAL_PROJECTS",
  NEW_ARCHITECTURAL_PROJECTS_RESET: "NEW_ARCHITECTURAL_PROJECTS_RESET",
  NEW_ARCHITECTURAL_PROJECTS_LOADING: "NEW_ARCHITECTURAL_PROJECTS_LOADING",
  NEW_ARCHITECTURAL_PROJECTS_SUCCESS: "NEW_ARCHITECTURAL_PROJECTS_SUCCESS",
  NEW_ARCHITECTURAL_PROJECTS_ERROR: "NEW_ARCHITECTURAL_PROJECTS_ERROR",
  EDIT_ARCHITECTURAL_PROJECTS_LOADING: "EDIT_ARCHITECTURAL_PROJECTS_LOADING",
  EDIT_ARCHITECTURAL_PROJECTS_SUCCESS: "EDIT_ARCHITECTURAL_PROJECTS_SUCCESS",
  EDIT_ARCHITECTURAL_PROJECTS_ERROR: "EDIT_ARCHITECTURAL_PROJECTS_ERROR",
  NEW_FLOORS_MEDIA: "NEW_FLOORS_MEDIA",
  UPDATE_EDIT_FLOORS_LIST: "UPDATE_EDIT_FLOORS_LIST",
  GET_CURRENT_FLOORS_LIST: "GET_CURRENT_FLOORS_LIST",
  GET_CURRENT_FLOORS_LIST_COMMERCIAL: "GET_CURRENT_FLOORS_LIST_COMMERCIAL",
  UPDATE_EDIT_APARTMENTS_LIST: "UPDATE_EDIT_APARTMENTS_LIST",
  GET_CURRENT_APARTMENTS_LIST_COMMERCIAL:
    "GET_CURRENT_APARTMENTS_LIST_COMMERCIAL",
  GET_OPTIONS_AREA_TYPES: "GET_OPTIONS_AREA_TYPES",
  UPDATE_EDIT_AREAS_LIST: "UPDATE_EDIT_AREAS_LIST",
  GET_CURRENT_HOTSPOTS_LIST: "GET_CURRENT_HOTSPOTS_LIST",
  UPDATE_EDIT_HOTSPOTS_LIST: "UPDATE_EDIT_HOTSPOTS_LIST",
  GET_CURRENT_AREAS_LIST: "GET_CURRENT_AREAS_LIST",
  GET_CURRENT_FLOOR_APARTMENT_LIST: "GET_CURRENT_FLOOR_APARTMENT_LIST",
  UPDATE_EDIT_FLOOR_APARTMENT_LIST: "UPDATE_EDIT_FLOOR_APARTMENT_LIST",
  UPDATE_EDIT_MEDIAS_LIST: "UPDATE_EDIT_MEDIAS_LIST",
  UPDATE_EDIT_PARALAX_MEDIAS_LIST: "UPDATE_EDIT_PARALAX_MEDIAS_LIST",
  UPDATE_EDIT_PACOTEMKT_MEDIAS_LIST: "UPDATE_EDIT_PACOTEMKT_MEDIAS_LIST",
  GET_CURRENT_MEDIAS_LIST: "GET_CURRENT_MEDIAS_LIST",
  UPDATE_EDIT_MEDIAS_TOUR_LIST: "UPDATE_EDIT_MEDIAS_TOUR_LIST",
  GET_CURRENT_MEDIAS_TOUR_LIST: "GET_CURRENT_MEDIAS_TOUR_LIST",
  GET_CURRENT_PARALAX_MEDIAS_LIST: "GET_CURRENT_PARALAX_MEDIAS_LIST",
  GET_CURRENT_PERSON_MEDIAS_LIST: "GET_CURRENT_PERSON_MEDIAS_LIST",
  GET_CURRENT_PACOTEMKT_MEDIAS_LIST: "GET_CURRENT_PACOTEMKT_MEDIAS_LIST",
  UPDATE_EDIT_PERSON_MEDIAS_LIST: "UPDATE_EDIT_PERSON_MEDIAS_LIST",
  UPDATE_EDIT_CHARACTERISTICS_TYPES_LIST:
    "UPDATE_EDIT_CHARACTERISTICS_TYPES_LIST",
  GET_CURRENT_CHARACTERISTICS_TYPES_LIST:
    "GET_CURRENT_CHARACTERISTICS_TYPES_LIST",
  UPDATE_EDIT_CHARACTERISTICS_LIST: "UPDATE_EDIT_CHARACTERISTICS_LIST",
  GET_OLD_CHARACTERISTICS_LIST: "GET_OLD_CHARACTERISTICS_LIST",
  GET_CURRENT_CHARACTERISTICS_LIST: "GET_CURRENT_CHARACTERISTICS_LIST",
  GET_OPTIONS_CHARACTERISTICS_TYPE: "GET_OPTIONS_CHARACTERISTICS_TYPE",
  GET_OPTIONS_CHARACTERISTICS: "GET_OPTIONS_CHARACTERISTICS",
  TOGGLE_VIEW_ARCHITECTURAL: "TOGGLE_VIEW_ARCHITECTURAL",
  TOGGLE_VIEW_IN_CONSTRUCTION: "TOGGLE_VIEW_IN_CONSTRUCTION",
  TOGGLE_VIEW_COMMERCIAL: "TOGGLE_VIEW_COMMERCIAL",
  TOGGLE_VIEW_CUSTOM: "TOGGLE_VIEW_CUSTOM",
  CLEAR_CURRENT_PROJECT: "CLEAR_CURRENT_PROJECT",
  GET_CURRENT_INTERIOR_DESIGN_STYLE_LIST:
    "GET_CURRENT_INTERIOR_DESIGN_STYLE_LIST",
  UPDATE_EDIT_INTERIOR_DESIGN_STYLE_LIST:
    "UPDATE_EDIT_INTERIOR_DESIGN_STYLE_LIST",
  GET_OPTIONS_INTERIOR_DESIGN_STYLE: "GET_OPTIONS_INTERIOR_DESIGN_STYLE",
  GET_CURRENT_360_MEDIAS_LIST: "GET_CURRENT_360_MEDIAS_LIST",
  UPDATE_EDIT_360_MEDIAS_LIST: "UPDATE_EDIT_360_MEDIAS_LIST",
  //AÇÃO REFERENTE A PERMISSÃO
  getToken: async () => {
    const apiKey = globals.API_KEY;

    const token = await axios
        .post(`${globals.API_URL_BUSCADOR}/auth`, null, {
          headers: {
            apikey: apiKey,
          },
        })
        .then((token) => {
          return token.data.access_token;
        })
        .catch((error) => {
          console.error("Erro na autenticação:", error);
          throw error;
        });

        return token
  },
  //AÇÕES REFERENTES AOS PROJETOS PERSONALIZADOS
  newCustomProjectsReset: () => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_CUSTOM_PROJECTS_RESET,
      });
    };
  },
  newCustomProjects: (values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_CUSTOM_PROJECTS_LOADING,
      });
      axios
        .post(`${globals.API_URL}/customProjects/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.NEW_CUSTOM_PROJECTS_SUCCESS,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.NEW_CUSTOM_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  getAllCustomProjects: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/customProjects/all`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_CUSTOM_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentCustomProjects: (projectID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/customProjects/id/${projectID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_CUSTOM_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editCustomProjects: (projectID, values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.EDIT_CUSTOM_PROJECTS_LOADING,
      });
      axios
        .put(`${globals.API_URL}/customProjects/id/${projectID}`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.EDIT_CUSTOM_PROJECTS_SUCCESS,
            });
            dispatch(projectActions.getCurrentCustomProjects(values.projectID));
            dispatch(projectActions.toggleViewCustom(false));
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.EDIT_CUSTOM_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  deleteCustomProjects: (projectID) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/customProjects/id/${projectID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(projectActions.getAllCustomProjects());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //AÇÕES REFERENTES AOS PROJETOS COMERCIAIS
  newCommercialProjectsReset: () => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_COMMERCIAL_PROJECTS_RESET,
      });
    };
  },
  newCommercialProjects: (values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_COMMERCIAL_PROJECTS_LOADING,
      });
      axios
        .post(`${globals.API_URL}/commercialProjects/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.NEW_COMMERCIAL_PROJECTS_SUCCESS,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.NEW_COMMERCIAL_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  getAllCommercialProjects: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/commercialProjects/all`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_COMMERCIAL_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentCommercialProjects: (projectID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/commercialProjects/id/${projectID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_COMMERCIAL_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editCommercialProjects: (projectID, values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.EDIT_COMMERCIAL_PROJECTS_LOADING,
      });
      axios
        .put(`${globals.API_URL}/commercialProjects/id/${projectID}`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.EDIT_COMMERCIAL_PROJECTS_SUCCESS,
            });
            dispatch(
              projectActions.getCurrentCommercialProjects(values.projectID)
            );
            dispatch(projectActions.toggleViewCommercial(false));
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.EDIT_COMMERCIAL_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  deleteCommercialProjects: (projectID) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/commercialProjects/id/${projectID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(projectActions.getAllCommercialProjects());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS PROJETOS EM CONSTRUÇÃO
  newInConstructionProjectsReset: () => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_IN_CONSTRUCTION_PROJECTS_RESET,
      });
    };
  },
  newInConstructionProjects: (values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_IN_CONSTRUCTION_PROJECTS_LOADING,
      });
      axios
        .post(`${globals.API_URL}/inConstructionProjects/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.NEW_IN_CONSTRUCTION_PROJECTS_SUCCESS,
              payload: resp.data,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.NEW_IN_CONSTRUCTION_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  editInConstructionProjects: (projectID, values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.EDIT_IN_CONSTRUCTION_PROJECTS_LOADING,
      });
      axios
        .put(
          `${globals.API_URL}/inConstructionProjects/id/${projectID}`,
          values
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.EDIT_IN_CONSTRUCTION_PROJECTS_SUCCESS,
            });
            dispatch(projectActions.toggleViewInConstruction(false));
            dispatch(
              projectActions.getCurrentInConstructionProjects(projectID)
            );
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.EDIT_IN_CONSTRUCTION_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  getAllInConstructionProjects: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/inConstructionProjects/all`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_IN_CONSTRUCTION_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getAllInConstructionProjectsByPerson: (email) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/inConstructionProjects/email/${email}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_IN_CONSTRUCTION_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentInConstructionProjects: (projectID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/inConstructionProjects/id/${projectID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_IN_CONSTRUCTION_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteInConstructionProjects: (projectID) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/inConstructionProjects/id/${projectID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(projectActions.getAllInConstructionProjects());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS PROJETOS DE DESIGN DE INTERIORES
  newInteriorDesignProjectsReset: () => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_INTERIOR_DESIGN_PROJECTS_RESET,
      });
    };
  },
  newInteriorDesignProjects: (values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_INTERIOR_DESIGN_PROJECTS_LOADING,
      });
      axios
        .post(`${globals.API_URL}/interiorDesignProjects/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.NEW_INTERIOR_DESIGN_PROJECTS_SUCCESS,
              payload: resp.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: projectActions.NEW_IN_CONSTRUCTION_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  editInteriorDesignProjects: (designID, values) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.EDIT_INTERIOR_DESIGN_PROJECTS_LOADING,
      });
      axios
        .put(`${globals.API_URL}/interiorDesignProjects/id/${designID}`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.EDIT_INTERIOR_DESIGN_PROJECTS_SUCCESS,
            });
            dispatch(
              projectActions.getCurrentInteriorDesignProjects(values.designID)
            );
            dispatch(projectActions.toggleViewInteriorDesign(false));
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.EDIT_INTERIOR_DESIGN_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  getAllInteriorDesignProjects: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/interiorDesignProjects/all`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_INTERIOR_DESIGN_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentInteriorDesignProjects: (designID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/interiorDesignProjects/dashboard/id/${designID}`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_INTERIOR_DESIGN_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteInteriorDesignProjects: (designID) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/interiorDesignProjects/id/${designID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(projectActions.getAllInteriorDesignProjects());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //LISTA TODOS OS ESTILOS
  getOptionsStyleProjects: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/interiorDesignProjects/styles`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_OPTIONS_INTERIOR_DESIGN_STYLE,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE À RELAÇÃO DOS ESTILOS E PROJETOS DE DESIGN
  getCurrentStyleProjectList: (designID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/interiorDesignProjects/id/${designID}/styles`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_INTERIOR_DESIGN_STYLE_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newStyleProject: (designID, currentStylesList, optionsStylesList) => {
    var position, style;
    for (let i = 0; i < optionsStylesList.length; i++) {
      position = currentStylesList
        .map(function (styl) {
          return styl.styleID;
        })
        .indexOf(optionsStylesList[i].styleID);
      if (position < 0) {
        style = optionsStylesList[i];
        break;
      }
    }
    if (style) {
      let newStyleProject = {
        percentage: 0,
        InteriorDesignDesignID: designID,
        InteriorDesignProjectStyleStyleID: style.styleID,
      };
      return (dispatch) => {
        axios
          .post(
            `${globals.API_URL}/interiorDesignProjects/interiorDesignProjectStylesRelation`,
            newStyleProject
          )
          .then((resp) => {
            if (resp.status == 200) {
              dispatch(projectActions.getCurrentStyleProjectList(designID));
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais estilos para esse tipo.");
      };
    }
  },
  updateEditAllStyleProjects: (
    position,
    newStyle,
    oldStyle,
    currentStylesList
  ) => {
    if (position && newStyle && oldStyle) {
      for (let i = 0; i < currentStylesList.length; i++) {
        if (i != position && currentStylesList[i].styleID == newStyle.styleID) {
          currentStylesList[i].styleID = oldStyle.styleID;
          currentStylesList[i].name = oldStyle.name;
          currentStylesList[i].percentage = oldStyle.percentage;
        }
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_INTERIOR_DESIGN_STYLE_LIST,
        payload: currentStylesList,
      });
    };
  },
  editAllStyleProjects: (currentStylesList, designID) => {
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/interiorDesignProjects/id/${designID}/renewInteriorDesignProjectStylesRelation`,
          currentStylesList
        )
        .then((resp) => {
          dispatch(projectActions.getCurrentStyleProjectList(designID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteStyleProjects: (singleStyle, designID) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/interiorDesignProjects/id/${designID}/styles/${singleStyle.styleID}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(projectActions.getCurrentStyleProjectList(designID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS PROJETOS ARQUITETÔNICOS
  newArchitecturalProjectsReset: () => {
    return (dispatch) => {
      dispatch({
        type: projectActions.NEW_ARCHITECTURAL_PROJECTS_RESET,
      });
    };
  },
  newArchitecturalProjects: (values) => {
    return async (dispatch) => {
      dispatch({
        type: projectActions.NEW_ARCHITECTURAL_PROJECTS_LOADING,
      });

      const apiKey = globals.API_KEY;

      const token = await axios
        .post(`${globals.API_URL_BUSCADOR}/auth`, null, {
          headers: {
            apikey: apiKey,
          },
        })
        .then((token) => {
          return token.data.access_token;
        })
        .catch((error) => {
          console.error("Erro na autenticação:", error);
          throw error;
        });

      // axios.post(`${globals.API_URL}/architecturalProjects/`, values)
      await axios
        .post(`${globals.API_URL_BUSCADOR}/architecturalProjects/`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status == 201) {
            dispatch({
              type: projectActions.NEW_ARCHITECTURAL_PROJECTS_SUCCESS,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.NEW_ARCHITECTURAL_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  editArchitecturalProjects: (projectID, values) => {
    return async (dispatch) => {
      dispatch({
        type: projectActions.EDIT_ARCHITECTURAL_PROJECTS_LOADING,
      });
      const apiKey = globals.API_KEY;

      const token = await axios
        .post(`${globals.API_URL_BUSCADOR}/auth`, null, {
          headers: {
            apikey: apiKey,
          },
        })
        .then((token) => {
          return token.data.access_token;
        })
        .catch((error) => {
          console.error("Erro na autenticação:", error);
          throw error;
        });

      // axios.put(`${globals.API_URL}/architecturalProjects/id/${projectID}`, values)
      axios
        .patch(
          `${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: projectActions.EDIT_ARCHITECTURAL_PROJECTS_SUCCESS,
            });
            dispatch(
              projectActions.getCurrentArchitecturalProjects(values.projectID)
            );
            dispatch(projectActions.toggleViewArchitectural(false));
          }
        })
        .catch((error) => {
          dispatch({
            type: projectActions.EDIT_ARCHITECTURAL_PROJECTS_ERROR,
            error: error,
          });
        });
    };
  },
  getAllArchitecturalProjects: (skip) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/architecturalProjects/all`)
      axios
        .get(
          `${globals.API_URL_BUSCADOR}/architecturalProjects?limit=10&skip=${skip}&approximateResults=true&approximateLimit=10&orderBy=createdAt desc&statusOk=false`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_ARCHITECTURAL_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getAllReadyAndCustomProjects: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/architecturalProjects/readyAndCustom/all`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_ALL_READY_AND_CUSTOM_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentArchitecturalProjects: (projectID) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/architecturalProjects/id/${projectID}`)
      axios
        .get(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_ARCHITECTURAL_PROJECTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteArchitecturalProjects: (projectID) => {
    return async (dispatch) => {
      const apiKey = globals.API_KEY;

      const token = await axios
        .post(`${globals.API_URL_BUSCADOR}/auth`, null, {
          headers: {
            apikey: apiKey,
          },
        })
        .then((token) => {
          return token.data.access_token;
        })
        .catch((error) => {
          console.error("Erro na autenticação:", error);
          throw error;
        });

      // axios.delete(`${globals.API_URL}/architecturalProjects/id/${projectID}`)
      axios
        .delete(
          `${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(projectActions.getAllArchitecturalProjects());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS PAVIMENTOS
  getCurrentFloorsList: (projectID) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/floors/all/${projectID}/architecturalprojects`)
        axios.get(`${globals.API_URL_BUSCADOR}/floors?projectID=${projectID}&includeMedias=true`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_FLOORS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentFloorsListCommercialProjects: (projectID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/floors/all/${projectID}/commercialprojects`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_FLOORS_LIST_COMMERCIAL,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newFloor: (projectID, commercialProjectID) => {
    const env = process.env.NODE_ENV.includes("development") ? "test" : "ecommerce";

    const newFloor = {
      name: "Sem Nome",
      style: "Comum",
      commercialType: commercialProjectID ? "Residencial" : null,
      quantity: 1,
      projectID: projectID,
      commercialProjectID: commercialProjectID,
    };
    return async (dispatch) => {

      const token = await projectActions.getToken();

      // axios.post(`${globals.API_URL}/floors/`, newFloor)
      axios.post(`${globals.API_URL_BUSCADOR}/floors`, newFloor, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status == 201) {
            if (projectID) {
              dispatch(projectActions.getCurrentFloorsList(projectID));
            } else if (commercialProjectID) {
              dispatch(
                projectActions.getCurrentFloorsListCommercialProjects(
                  commercialProjectID
                )
              );
            }
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateEditAllFloors: (floorsList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_FLOORS_LIST,
        payload: floorsList,
      });
    };
  },
  editAllFloors: (floorsList) => {
    return (dispatch) => {
      floorsList.forEach((floor) => {
        dispatch(projectActions.editFloor(floor.floorID, floor));
      });
    };
  },
  editFloor: (floorID, values) => {
    const newValues = {
      "floorID": values.floorID,
      "name": values.name,
      "style": values.style,
      "quantity": +values.quantity,
      "updatedAt": new Date().toISOString(),
      "projectID": values.projectID,
    }
    return async (dispatch) => {
      
      const token = await projectActions.getToken();
          
      // .put(`${globals.API_URL}/floors/id/${floorID}`, values)
      axios.patch(`${globals.API_URL_BUSCADOR}/floors/${floorID}`, newValues, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          console.log("Pavimento atualizado com sucesso: " + values.name);
          if (values.projectID) {
            dispatch(projectActions.getCurrentFloorsList(values.projectID));
          } else if (values.commercialProjectID) {
            dispatch(
              projectActions.getCurrentFloorsListCommercialProjects(
                values.commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteFloor: (floor) => {
    return async (dispatch) => {

      const token = await projectActions.getToken();

      axios
        // .delete(`${globals.API_URL}/floors/id/${floor.floorID}`)
        .delete(`${globals.API_URL_BUSCADOR}/floors/${floor.floorID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status == 202) {
            if (floor.projectID) {
              dispatch(projectActions.getCurrentFloorsList(floor.projectID));
            } else if (floor.commercialProjectID) {
              dispatch(
                projectActions.getCurrentFloorsListCommercialProjects(
                  floor.commercialProjectID
                )
              );
            }
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS APARTAMENTOS
  getCurrentApartmentsListCommercialProjects: (projectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/apartments/all/${projectID}/commercialprojects`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_APARTMENTS_LIST_COMMERCIAL,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newApartment: (commercialProjectID) => {
    const newApartment = {
      name: "Sem Nome",
      buildingArea: 0,
      commercialProjectID: commercialProjectID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/apartments/`, newApartment)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentApartmentsListCommercialProjects(
                commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateEditAllApartments: (apartmentsList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_APARTMENTS_LIST,
        payload: apartmentsList,
      });
    };
  },
  editAllApartments: (apartmentsList) => {
    return (dispatch) => {
      apartmentsList.forEach((apartment) => {
        dispatch(
          projectActions.editApartment(apartment.apartmentID, apartment)
        );
      });
    };
  },
  editApartment: (apartmentID, values) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/apartments/id/${apartmentID}`, values)
        .then((resp) => {
          console.log("Apartamento atualizado com sucesso: " + values.name);
          dispatch(
            projectActions.getCurrentApartmentsListCommercialProjects(
              values.commercialProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteApartment: (apartment) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/apartments/id/${apartment.apartmentID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentApartmentsListCommercialProjects(
                apartment.commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS AREAS
  getOptionsAreaTypes: () => {
    return (dispatch) => {
      axios
      // .get(`${globals.API_URL}/areaTypes/all`)
        .get(`${globals.API_URL_BUSCADOR}/areaTypes`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_OPTIONS_AREA_TYPES,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  getCurrentFloorAreasList: (floorID) => {
    return (dispatch) => {
      axios
        // .get(`${globals.API_URL}/areas/all/${floorID}`)
        .get(`${globals.API_URL_BUSCADOR}/areas?floorID=${floorID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_AREAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  getCurrentApartmentAreasList: (apartmentID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/areas/apartment/all/${apartmentID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_AREAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newArea: (ID, type, areasList, from) => {
    var newArea;
    if (from == "Pavimento") {
      newArea = {
        numberOfLocation:
          areasList.length > 0
            ? (Number(areasList[areasList.length - 1].numberOfLocation) + 1).toString()
            : "1",
        name: "Sem Nome",
        size: 0,
        quantity: 1,
        style: "Comum",
        typeID: type.typeID,
        floorID: ID,
      };
      return async (dispatch) => {
        const token = await projectActions.getToken();

        axios
          // .post(`${globals.API_URL}/areas/`, newArea)
          .post(`${globals.API_URL_BUSCADOR}/areas`, newArea, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
              }
          })
          .then((resp) => {
            if (resp.status == 201) {
              dispatch(projectActions.getCurrentFloorAreasList(ID));
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      newArea = {
        numberOfLocation:
          areasList.length > 0
          ? (Number(areasList[areasList.length - 1].numberOfLocation) + 1).toString()
          : "1",
        name: "Sem Nome",
        size: 0,
        quantity: 1,
        style: "Comum",
        typeID: type.typeID,
        apartmentID: ID,
      };
      return async (dispatch) => {
        const token = await projectActions.getToken();

        axios
          .post(`${globals.API_URL}/areas/`, newArea, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
              }
          })
          .then((resp) => {
            if (resp.status == 201) {
              dispatch(projectActions.getCurrentApartmentAreasList(ID));
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    }
  },
  updateEditAllAreas: (areasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_AREAS_LIST,
        payload: areasList,
      });
    };
  },
  editAllAreas: (areasList, type) => {
    if (type == "Apartamento") {
      return (dispatch) => {
        areasList.forEach((area) => {
          dispatch(projectActions.editApartmentArea(area.areaID, area));
        });
      };
    } else {
      return (dispatch) => {
        areasList.forEach((area) => {
          dispatch(projectActions.editFloorArea(area.areaID, area));
        });
      };
    }
  },
  editFloorArea: (areaID, values) => {
    const newValues = {
      "areaID": values.areaID,
      "floorID": values.floorID,
      "typeID": values.typeID,
      "name": values.name,
      "numberOfLocation": values.numberOfLocation,
      "style": values.style,
      "quantity": +values.quantity,
      "size": +values.size,
      "updatedAt": new Date().toISOString(),
    }
    return async (dispatch) => {
      const token = await projectActions.getToken();
      
      // axios.put(`${globals.API_URL}/areas/id/${areaID}`, values)
      axios.patch(`${globals.API_URL_BUSCADOR}/areas/${areaID}`, newValues, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
          }
      })
        .then((resp) => {
          console.log("Área atualizada com sucesso: " + values.name);
          dispatch(projectActions.getCurrentFloorAreasList(values.floorID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editApartmentArea: (areaID, values) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/areas/id/${areaID}`, values)
        .then((resp) => {
          console.log("Área atualizada com sucesso: " + values.name);
          dispatch(
            projectActions.getCurrentApartmentAreasList(values.apartmentID)
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteArea: (area, type) => {
    return async (dispatch) => {
      const token = await projectActions.getToken();


      axios
        // .delete(`${globals.API_URL}/areas/id/${area.areaID}`)
        .delete(`${globals.API_URL_BUSCADOR}/areas/${area.areaID}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
            }
        })
        .then((resp) => {
          if (resp.status == 202) {
            if (type == "Apartamento") {
              dispatch(
                projectActions.getCurrentApartmentAreasList(area.apartmentID)
              );
            } else {
              dispatch(projectActions.getCurrentFloorAreasList(area.floorID));
            }
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE À RELAÇÃO DOS PAVIMENTOS E APARTAMENTOS

  getCurrentFloorApartmentsList: (floorID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/apartments/all/${floorID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_FLOOR_APARTMENT_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newFloorApartment: (ID, apartmentsList, optionsApartmentsList) => {
    var found = false;
    var apartmentID;
    if (apartmentsList.length > 0) {
      for (let i = 0; i < optionsApartmentsList.length; i++) {
        for (let j = 0; j < apartmentsList.length; j++) {
          if (
            apartmentsList[j].apartmentID !=
            optionsApartmentsList[i].apartmentID
          ) {
            apartmentID = optionsApartmentsList[i].apartmentID;
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    } else {
      apartmentID = optionsApartmentsList[0].apartmentID;
    }
    var newFloorApartment;
    newFloorApartment = {
      numberOfLocation:
        apartmentsList.length > 0
          ? Number(apartmentsList[apartmentsList.length - 1].numberOfLocation) +
            1
          : 1,
      quantity: 1,
      ApartmentApartmentID: apartmentID,
      FloorFloorID: ID,
    };
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/floors/apartmentFloorRelation`,
          newFloorApartment
        )
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(projectActions.getCurrentFloorApartmentsList(ID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateEditAllFloorApartments: (apartmentsList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_FLOOR_APARTMENT_LIST,
        payload: apartmentsList,
      });
    };
  },
  editAllFloorApartments: (apartmentsList, floorID) => {
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/floors/renewApartmentFloorRelation/id/${floorID}`,
          apartmentsList
        )
        .then((resp) => {
          console.log("Relações atualizadas com sucesso");
          dispatch(projectActions.getCurrentFloorApartmentsList(floorID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteFloorApartment: (floorID, apartment) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/floors/deleteApartmentFloorRelation/id/${floorID}/apartment/${apartment.apartmentID}`
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(projectActions.getCurrentFloorApartmentsList(floorID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS EM GERAL
  updateEditAllMediasIndex: (mediaID, oldIndex, newIndex, mediasList) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DOS PROJETOS PERSONALIZADOS
  getCurrentMediasListCustomProject: (customProjectID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/medias/all/${customProjectID}/customProjects`)

        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaCustomProject: (customProjectID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/architecturalProjects/custom/${customProjectID}/images/project_${customProjectID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      architecturalProjectID: customProjectID,
    };
    return async (dispatch) => {
      const token = await projectActions.getToken();

      axios
        .post(`${globals.API_URL}/medias/`, newMedia, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasListCustomProject(customProjectID)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasCustomProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(projectActions.editMediaCustomProject(media.mediaID, media));
      });
    };
  },
  editMediaCustomProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/medias/id/${mediaID}/customProjects`, values)
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasListCustomProject(
              values.architecturalProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaCustomProject: (media) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/medias/id/${media.mediaID}/customProjects`)
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasListCustomProject(
                media.architecturalProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DO TOUR DO PROJETO
  updateEditAllMediasTourDefault: (mediaID, mediasList) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID) {
        mediasList[i].isDefault = 0;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_MEDIAS_TOUR_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllMediasTour: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_MEDIAS_TOUR_LIST,
        payload: mediasList,
      });
    };
  },

  getCurrentMediasTourListArchitecturalProject: (architecturalProjectID) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/medias/tour/all/${architecturalProjectID}/architecturalProjects`)
      axios
        .get(
          `${globals.API_URL_BUSCADOR}/architecturalProjects/${architecturalProjectID}/tour`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_MEDIAS_TOUR_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaTourArchitecturalProject: (architecturalProjectID, index) => {
    const env = process.env.NODE_ENV.includes("development") ? "test" : "ecommerce";
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/${env}/architecturalProjects/ready/${architecturalProjectID}/images/project_${architecturalProjectID}_image__tour_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: `Essa é uma imagem 360° do projeto ${architecturalProjectID}`,
      architecturalProjectID: architecturalProjectID,
    };
    return async (dispatch) => {
      const token = await projectActions.getToken();

      axios
        .post(`${globals.API_URL_BUSCADOR}/medias`, newMedia, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasTourListArchitecturalProject(
                architecturalProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasTourArchitecturalProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaTourArchitecturalProject(media.mediaID, media)
        );
      });
    };
  },
  editMediaTourArchitecturalProject: (mediaID, values) => {
    return async (dispatch) => {
      const token = await projectActions.getToken();

      // axios.put(`${globals.API_URL}/medias/tour/id/${mediaID}/architecturalProjects`, values)
      axios.patch(`${globals.API_URL_BUSCADOR}/medias/${mediaID}`, values, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasTourListArchitecturalProject(
              values.architecturalProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaTourArchitecturalProject: (media) => {
    return async (dispatch) => {
      const token = await projectActions.getToken();

      // axios.delete(`${globals.API_URL}/medias/tour/id/${media.mediaID}/architecturalProjects`)
      axios.delete(`${globals.API_URL_BUSCADOR}/medias/${media.mediaID}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasTourListArchitecturalProject(
                media.architecturalProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS HOTSPOTS
  getCurrentHotspotsList: (mediaID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/hotspots/all/${mediaID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_HOTSPOTS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newHotspot: (mediaID, optionsScenesList) => {
    var targetMedia;
    for (let i = 0; i < optionsScenesList.length; i++) {
      if (optionsScenesList[i].mediaID != mediaID) {
        targetMedia = optionsScenesList[i];
        break;
      }
    }
    const newHotspot = {
      pitch: 0,
      yaw: 0,
      type: "scene",
      text: targetMedia.title,
      mediaID: mediaID,
      targetMediaID: targetMedia.mediaID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/hotspots/`, newHotspot)
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(projectActions.getCurrentHotspotsList(mediaID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateEditAllHotspots: (hotspotsList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_HOTSPOTS_LIST,
        payload: hotspotsList,
      });
    };
  },
  editAllHotspots: (hotspotsList) => {
    return (dispatch) => {
      hotspotsList.forEach((hotspot) => {
        dispatch(projectActions.editHotspot(hotspot.hotspotID, hotspot));
      });
    };
  },
  editHotspot: (hotspotID, values) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/hotspots/id/${hotspotID}`, values)
        .then((resp) => {
          console.log("Hotspot atualizado com sucesso: " + values.text);
          dispatch(projectActions.getCurrentHotspotsList(values.mediaID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteHotspot: (hotspot) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/hotspots/id/${hotspot.hotspotID}`)
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(projectActions.getCurrentHotspotsList(hotspot.mediaID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DOS CLIENTES DOS PROJETOS PERSONALIZADOS
  updateEditAllPersonMediasIndex: (mediaID, oldIndex, newIndex, mediasList) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_PERSON_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllPersonMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_PERSON_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  getCurrentMediasListPerson: (customProjectID, personEmail) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/medias/all/${customProjectID}/customProjects/person`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_PERSON_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaPerson: (customProjectID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/architecturalProjects/custom/${customProjectID}/images/project_${customProjectID}_image__client_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      architecturalProjectID: customProjectID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentMediasListPerson(customProjectID)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasPerson: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(projectActions.editMediaPerson(media.mediaID, media));
      });
    };
  },
  editMediaPerson: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/id/${mediaID}/customProjects/person`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasListPerson(
              values.architecturalProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaPerson: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/id/${media.mediaID}/customProjects/person`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentMediasListPerson(
                media.architecturalProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DOS PROJETOS ARQUITETONICOS
  getCurrentMediasListArchitecturalProject: (architecturalProjectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL_BUSCADOR}/medias?where=architecturalProjectID='${architecturalProjectID}' and source not 'image__tour' and source not 'floor'`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaArchitecturalProject: (architecturalProjectID, index) => {
    const env = process.env.NODE_ENV.includes("development") ? "test" : "ecommerce";

    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/${env}/architecturalProjects/ready/${architecturalProjectID}/images/project_${architecturalProjectID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      architecturalProjectID: architecturalProjectID,
    };
    return async (dispatch) => {
      const token = await projectActions.getToken();

      axios
        .post(`${globals.API_URL_BUSCADOR}/medias`, newMedia, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasListArchitecturalProject(
                architecturalProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasArchitecturalProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaArchitecturalProject(media.mediaID, media)
        );
      });
    };
  },
  editMediaArchitecturalProject: (mediaID, values) => {
    return async (dispatch) => {
      const token = await projectActions.getToken();

      // axios.put(`${globals.API_URL}/medias/id/${mediaID}/architecturalProjects`, values)
      axios
        .patch(`${globals.API_URL_BUSCADOR}/medias/${mediaID}`, values, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasListArchitecturalProject(
              values.architecturalProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaArchitecturalProject: (media) => {
    return async (dispatch) => {
      const token = await projectActions.getToken();

      // axios.delete(`${globals.API_URL}/medias/id/${media.mediaID}/architecturalProjects`)
      axios.delete(`${globals.API_URL_BUSCADOR}/medias/${media.mediaID}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasListArchitecturalProject(
                media.architecturalProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DOS PROJETOS COMERCIAIS
  getCurrentMediasListCommercialProject: (commercialProjectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/medias/all/${commercialProjectID}/commercialProjects`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaCommercialProject: (commercialProjectID, index) => {
    console.log("---newMediaCommercialProject----");
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/commercialProjects/${commercialProjectID}/images/project_${commercialProjectID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      commercialProjectID: commercialProjectID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasListCommercialProject(
                commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasCommercialProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaCommercialProject(media.mediaID, media)
        );
      });
    };
  },
  editMediaCommercialProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/id/${mediaID}/commercialProjects`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasListCommercialProject(
              values.commercialProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaCommercialProject: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/id/${media.mediaID}/commercialProjects`
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasListCommercialProject(
                media.commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DO PARALAX DOS PROJETOS COMERCIAIS
  updateEditAllParalaxMediasIndex: (
    mediaID,
    oldIndex,
    newIndex,
    mediasList
  ) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_PARALAX_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllParalaxMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_PARALAX_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  getCurrentMediasParalaxListCommercialProject: (commercialProjectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/medias/paralax/all/${commercialProjectID}/commercialProjects`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_PARALAX_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaParalaxCommercialProject: (commercialProjectID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/commercialProjects/${commercialProjectID}/images/project_${commercialProjectID}_image__paralax_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      commercialProjectID: commercialProjectID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasParalaxListCommercialProject(
                commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasParalaxCommercialProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaParalaxCommercialProject(media.mediaID, media)
        );
      });
    };
  },
  editMediaParalaxCommercialProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/paralax/id/${mediaID}/commercialProjects`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasParalaxListCommercialProject(
              values.commercialProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaParalaxCommercialProject: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/paralax/id/${media.mediaID}/commercialProjects`
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasParalaxListCommercialProject(
                media.commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DO PACOTE DE MARKETING DOS PROJETOS COMERCIAIS
  updateEditAllPacoteMKTMediasIndex: (
    mediaID,
    oldIndex,
    newIndex,
    mediasList
  ) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_PACOTEMKT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllPacoteMKTMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_PACOTEMKT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  getCurrentMediasPacoteMKTListCommercialProject: (commercialProjectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/medias/pacoteMKT/all/${commercialProjectID}/commercialProjects`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_PACOTEMKT_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaPacoteMKTCommercialProject: (commercialProjectID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/commercialProjects/${commercialProjectID}/images/project_${commercialProjectID}_image__pacoteMKT_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      commercialProjectID: commercialProjectID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasPacoteMKTListCommercialProject(
                commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasPacoteMKTCommercialProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaPacoteMKTCommercialProject(
            media.mediaID,
            media
          )
        );
      });
    };
  },
  editMediaPacoteMKTCommercialProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/pacoteMKT/id/${mediaID}/commercialProjects`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasPacoteMKTListCommercialProject(
              values.commercialProjectID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaPacoteMKTCommercialProject: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/pacoteMKT/id/${media.mediaID}/commercialProjects`
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasPacoteMKTListCommercialProject(
                media.commercialProjectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DOS PROJETOS EM CONSTRUÇÃO
  getCurrentMediasListInConstructionProject: (constructionID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/medias/all/${constructionID}/inConstructionProjects`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaInConstructionProject: (constructionID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/inConstructionProjects/${constructionID}/images/project_${constructionID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      constructionID: constructionID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasListInConstructionProject(
                constructionID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasInConstructionProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaInConstructionProject(media.mediaID, media)
        );
      });
    };
  },
  editMediaInConstructionProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/id/${mediaID}/inConstructionProjects`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasListInConstructionProject(
              values.constructionID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaInConstructionProject: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/id/${media.mediaID}/inConstructionProjects`
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasListInConstructionProject(
                media.constructionID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS MEDIAS DOS PROJETOS DE DESIGN DE INTERIORES
  getCurrentMediasListInteriorDesignProject: (designID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/medias/all/${designID}/interiorDesignProjects`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newMediaInteriorDesignProject: (designID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/interiorDesignProjects/${designID}/images/project_${designID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      designID: designID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentMediasListInteriorDesignProject(designID)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAllMediasInteriorDesignProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.editMediaInteriorDesignProject(media.mediaID, media)
        );
      });
    };
  },
  editMediaInteriorDesignProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/id/${mediaID}/interiorDesignProjects`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrentMediasListInteriorDesignProject(
              values.designID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteMediaInteriorDesignProject: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/id/${media.mediaID}/interiorDesignProjects`
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentMediasListInteriorDesignProject(
                media.designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //AÇÕES REFERENTES AS MÍDIAS 360 dos projetos de design de interiores
  updateEditAll360Medias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_360_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },

  new360Media: (designID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/interiorDesignProjects/${designID}/images/project_${designID}_image__360_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      designID: designID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrent360MediasListInteriorDesignProject(
                designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrent360MediasListInteriorDesignProject: (designID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/medias/all/360image/${designID}/interiorDesignProjects`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_360_MEDIAS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editAll360MediasInteriorDesignProject: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach((media) => {
        dispatch(
          projectActions.edit360MediaInteriorDesignProject(media.mediaID, media)
        );
      });
    };
  },
  edit360MediaInteriorDesignProject: (mediaID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/medias/id/${mediaID}/interiorDesignProjects/360image`,
          values
        )
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(
            projectActions.getCurrent360MediasListInteriorDesignProject(
              values.designID
            )
          );
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  delete360MediaInteriorDesignProject: (media) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/medias/id/${media.mediaID}/interiorDesignProjects/360image`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrent360MediasListInteriorDesignProject(
                media.designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE AOS TIPOS DE CARACTERISTICAS DOS PROJETOS
  getOptionsCharacteristicsTypes: () => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/characteristicTypes/all`)
      axios.get(`${globals.API_URL_BUSCADOR}/characteristicTypes`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_OPTIONS_CHARACTERISTICS_TYPE,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentCharacteristicsTypesList: (projectID) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/characteristicTypes/all/architecturalProjectID/${projectID}`)
      axios.get(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristics`)
        .then((resp) => {


          const filterUniqueNames = (data) => {
            const seenNames = new Set();
            return data.filter((item) => {
              const name = item.characteristics.characteristicTypes.name;
              if (seenNames.has(name)) {
                return false;
              } else {
                seenNames.add(name);
                return true;
              }
            });
          };

          const data = filterUniqueNames(resp.data)

          dispatch({
            type: projectActions.GET_CURRENT_CHARACTERISTICS_TYPES_LIST,
            payload: data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newCharacteristicsType: (
    optionsCharacteristicsTypeList,
    currentCharacteristicsTypeList,
    projectID
  ) => {
    var position, characteristicType;
    for (let i = 0; i < optionsCharacteristicsTypeList.length; i++) {
      position = currentCharacteristicsTypeList
        .map(function (type) {
          return type.characteristics.characteristicTypes.typeID;
        })
        .indexOf(optionsCharacteristicsTypeList[i].typeID);
      if (position < 0) {
        characteristicType = optionsCharacteristicsTypeList[i];
        break;
      }
    }    
    if (characteristicType) {
      return async (dispatch) => {

        const token = await projectActions.getToken();

        // axios.get(`${globals.API_URL}/characteristicTypes/id/${characteristicType.typeID}/characteristics`)
        axios.get(`${globals.API_URL_BUSCADOR}/characteristics?typeID=${characteristicType.typeID}`)
        .then((resp) => {
            if (resp.status == 200) {
              
              var characteristics = resp.data[0]


              axios.post(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristic`,
                {
                    characteristicId:
                      characteristics.characteristicID,
                  }, 
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                    }
                  }
                )
                .then((resp) => {
                  if (resp.status == 201) {
                    dispatch(
                      projectActions.getCurrentCharacteristicsTypesList(
                        projectID
                      )
                    );
                  }
                })
                .catch((error) => {
                  console.log("Houve um erro interno. " + error);
                });
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais tipos de características para esse tipo.");
      };
    }
  },
  updateEditAllCharacteristicsTypes: (
    position,
    newType,
    oldType,
    characteristicsTypesList
  ) => {
    for (let i = 0; i < characteristicsTypesList.length; i++) {
      if (
        i != position &&
        characteristicsTypesList[i].typeID == newType.typeID
      ) {
        characteristicsTypesList[i].typeID = oldType.typeID;
        characteristicsTypesList[i].name = oldType.name;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_CHARACTERISTICS_TYPES_LIST,
        payload: characteristicsTypesList,
      });
    };
  },
  editAllCharacteristicsTypes: (characteristicsTypesList, projectID) => {
    return (dispatch) => {
      // Edita TODOS
      axios.post(
          `${globals.API_URL}/architecturalProjects/renewCharacteristicTypesProjectRelation`,
          {
            characteristicsTypesList: characteristicsTypesList,
            projectID: projectID,
          }
        )
        .then((resp) => {
          if (resp.status == 201) {
            dispatch(
              projectActions.getCurrentCharacteristicsTypesList(projectID)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteCharacteristicsType: (characteristicsTypeID, projectID) => {
    return async (dispatch) => {
      const token = await projectActions.getToken();

      //Exclusão de tipo de caracteristica, a partir da caracteristica.

      // axios.delete(`${globals.API_URL}/characteristicTypes/id/${characteristicsTypeID}/architecturalProjectID/${projectID}`)
      axios.delete(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristic/${characteristicsTypeID}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
          },
      })
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentCharacteristicsTypesList(projectID)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //AÇÕES REFERENTES AO TIPOS DE CARACTERÍSTICAS DOS PROJETOS COMERCIAIS
  getCurrentCharacteristicsTypesListCommercialProject: (projectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/characteristicTypes/all/commercialProjectID/${projectID}`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_CHARACTERISTICS_TYPES_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newCharacteristicsTypeCommecialProject: (
    optionsCharacteristicsTypeList,
    currentCharacteristicsTypeList,
    projectID
  ) => {
    var position, characteristicType;
    for (let i = 0; i < optionsCharacteristicsTypeList.length; i++) {
      position = currentCharacteristicsTypeList
        .map(function (type) {
          return type.typeID;
        })
        .indexOf(optionsCharacteristicsTypeList[i].typeID);
      if (position < 0) {
        characteristicType = optionsCharacteristicsTypeList[i];
        break;
      }
    }
    if (characteristicType) {
      return (dispatch) => {
        axios
          .get(
            `${globals.API_URL}/characteristicTypes/id/${characteristicType.typeID}/characteristics`
          )
          .then((resp) => {
            if (resp.status == 200) {
              var characteristics = resp.data.characteristics;
              axios
                .post(
                  `${globals.API_URL}/commercialProjects/characteristicProjectRelation`,
                  {
                    CharacteristicCharacteristicID:
                      characteristics[0].characteristicID,
                    CommercialProjectProjectID: projectID,
                  }
                )
                .then((resp) => {
                  if (resp.status == 200) {
                    dispatch(
                      projectActions.getCurrentCharacteristicsTypesListCommercialProject(
                        projectID
                      )
                    );
                  }
                })
                .catch((error) => {
                  console.log("Houve um erro interno. " + error);
                });
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais tipos de características para esse tipo.");
      };
    }
  },
  editAllCharacteristicsTypesCommercialProject: (
    characteristicsTypesList,
    projectID
  ) => {
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/commercialProjects/renewCharacteristicTypesProjectRelation`,
          {
            characteristicsTypesList: characteristicsTypesList,
            projectID: projectID,
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsTypesListCommercialProject(
                projectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteCharacteristicsTypeCommercialProject: (
    characteristicsTypeID,
    projectID
  ) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/characteristicTypes/id/${characteristicsTypeID}/commercialProjectID/${projectID}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsTypesListCommercialProject(
                projectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //AÇÕES REFERENTES AO TIPOS DE CARACTERÍSTICAS DOS PROJETOS DESIGN DE INTERIOR
  getCurrentCharacteristicsTypesListInteriorDesignProject: (designID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/characteristicTypes/all/interiorDesignProjectID/${designID}`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_CHARACTERISTICS_TYPES_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newCharacteristicsTypeInteriorDesignProject: (
    optionsCharacteristicsTypeList,
    currentCharacteristicsTypeList,
    designID
  ) => {
    var position, characteristicType;
    for (let i = 0; i < optionsCharacteristicsTypeList.length; i++) {
      position = currentCharacteristicsTypeList
        .map(function (type) {
          return type.typeID;
        })
        .indexOf(optionsCharacteristicsTypeList[i].typeID);
      if (position < 0) {
        characteristicType = optionsCharacteristicsTypeList[i];
        break;
      }
    }
    if (characteristicType) {
      return (dispatch) => {
        axios
          .get(
            `${globals.API_URL}/characteristicTypes/id/${characteristicType.typeID}/characteristics`
          )
          .then((resp) => {
            if (resp.status == 200) {
              var characteristics = resp.data.characteristics;
              axios
                .post(
                  `${globals.API_URL}/interiorDesignProjects/characteristicProjectRelation`,
                  {
                    CharacteristicCharacteristicID:
                      characteristics[0].characteristicID,
                    InteriorDesignDesignID: designID,
                  }
                )
                .then((resp) => {
                  if (resp.status == 200) {
                    dispatch(
                      projectActions.getCurrentCharacteristicsTypesListInteriorDesignProject(
                        designID
                      )
                    );
                  }
                })
                .catch((error) => {
                  console.log("Houve um erro interno. " + error);
                });
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais tipos de características para esse tipo.");
      };
    }
  },
  editAllCharacteristicsTypesInteriorDesignProject: (
    characteristicsTypesList,
    designID
  ) => {
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/interiorDesignProjects/renewCharacteristicTypesProjectRelation`,
          {
            characteristicsTypesList: characteristicsTypesList,
            designID: designID,
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsTypesListInteriorDesignProject(
                designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteCharacteristicsTypeInteriorDesignProject: (
    characteristicsTypeID,
    designID
  ) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/characteristicTypes/id/${characteristicsTypeID}/interiorDesignProjectID/${designID}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsTypesListInteriorDesignProject(
                designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  // AÇÕES REFERENTE ÀS CARACTERISTICAS DOS PROJETOS
  getOptionsCharacteristics: (typeID) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/characteristicTypes/id/${typeID}/characteristics`)
      axios.get(`${globals.API_URL_BUSCADOR}/characteristics?typeID=${typeID}`)
        .then((resp) => {
          dispatch({
            type: projectActions.GET_OPTIONS_CHARACTERISTICS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentCharacteristicsList: (typeID, projectID) => {
    return (dispatch) => {
      // axios.get(`${globals.API_URL}/characteristicTypes/all/characteristicTypeID/${typeID}/architecturalProjectID/${projectID}`)
      axios.get(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristics?typeID=${typeID}`)
      .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_CHARACTERISTICS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newCharacteristic: (
    optionsCharacteristicsList,
    currentCharacteristicsList,
    projectID
  ) => {
    var position, characteristic;
    for (let i = 0; i < optionsCharacteristicsList.length; i++) {
      position = currentCharacteristicsList
        .map(function (charac) {
          return charac.characteristicID;
        })
        .indexOf(optionsCharacteristicsList[i].characteristicID);
      if (position < 0) {
        characteristic = optionsCharacteristicsList[i];
        break;
      }
    }
    if (characteristic) {
      return async (dispatch) => {

        const token = await projectActions.getToken();

          // axios.post(`${globals.API_URL}/architecturalProjects/characteristicProjectRelation`,
        axios.post(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristic`,
            {
              characteristicId: characteristic.characteristicID,
            }, 
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
            }
          )
          .then((resp) => {
            if (resp.status == 201) {
              dispatch(
                projectActions.getCurrentCharacteristicsList(
                  characteristic.typeID,
                  projectID
                )
              );
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais características para esse tipo.");
      };
    }
  },
  updateEditAllCharacteristics: (
    position,
    newCharac,
    oldCharac,
    characteristicsList
  ) => {
    for (let i = 0; i < characteristicsList.length; i++) {
      if (i != position && characteristicsList[i].characteristicID == newCharac.characteristicID) {
        characteristicsList[i].characteristicID = oldCharac.characteristicID;
        characteristicsList[i].characteristics.description = oldCharac.description;
      }
    }
    return (dispatch) => {
      dispatch({
        type: projectActions.UPDATE_EDIT_CHARACTERISTICS_LIST,
        payload: characteristicsList,
      });
    };
  },
  editAllCharacteristics: (characteristicsList, projectID, oldCharac) => {
    return async (dispatch) => {
      // Edita TODOS

      // axios
      //   .post(
        //     `${globals.API_URL}/architecturalProjects/renewCharacteristicProjectRelation`,
        //     {
          //       characteristicsList: characteristicsList,
          //       projectID: projectID,
          //     }
          //   )
        characteristicsList.forEach(async (character, i) => {

        const token = await projectActions.getToken()
        
        if (oldCharac[i]) {
        axios.patch(`${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristic/${oldCharac[i].characteristicID}`, { 
          "newCharacteristicId": character.characteristicID 
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
        .then((resp) => {
          
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsList(
                character.characteristics.characteristicTypes.typeID,
                projectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
      }
      })
    };
  },
  deleteCharacteristic: (characteristic, projectID) => {
    return async (dispatch) => {
      const token = await projectActions.getToken()
      axios
        .delete(
          // `${globals.API_URL}/characteristics/id/${characteristic.characteristicID}/architecturalProjectID/${projectID}`
          `${globals.API_URL_BUSCADOR}/architecturalProjects/${projectID}/characteristic/${characteristic.characteristicID}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          }
        )
        .then((resp) => {
          if (resp.status == 202) {
            dispatch(
              projectActions.getCurrentCharacteristicsList(
                characteristic.typeID,
                projectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //AÇÕES REFERENTES AS CARACTERISTICAS DOS PROJETOS COMERCIAIS
  getCurrentCharacteristicsListCommercialProject: (typeID, projectID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/characteristicTypes/all/characteristicTypeID/${typeID}/commercialProjectID/${projectID}`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_CHARACTERISTICS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newCharacteristicCommercialProject: (
    optionsCharacteristicsList,
    currentCharacteristicsList,
    projectID
  ) => {
    var position, characteristic;
    for (let i = 0; i < optionsCharacteristicsList.length; i++) {
      position = currentCharacteristicsList
        .map(function (charac) {
          return charac.characteristicID;
        })
        .indexOf(optionsCharacteristicsList[i].characteristicID);
      if (position < 0) {
        characteristic = optionsCharacteristicsList[i];
        break;
      }
    }
    if (characteristic) {
      return (dispatch) => {
        axios
          .post(
            `${globals.API_URL}/commercialProjects/characteristicProjectRelation`,
            {
              CharacteristicCharacteristicID: characteristic.characteristicID,
              CommercialProjectProjectID: projectID,
            }
          )
          .then((resp) => {
            if (resp.status == 200) {
              dispatch(
                projectActions.getCurrentCharacteristicsListCommercialProject(
                  characteristic.typeID,
                  projectID
                )
              );
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais características para esse tipo.");
      };
    }
  },
  editAllCharacteristicsCommercialProject: (characteristicsList, projectID) => {
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/commercialProjects/renewCharacteristicProjectRelation`,
          {
            characteristicsList: characteristicsList,
            projectID: projectID,
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsListCommercialProject(
                characteristicsList[0].typeID,
                projectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteCharacteristicCommercialProject: (characteristic, projectID) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/characteristics/id/${characteristic.characteristicID}/commercialProjectID/${projectID}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsListCommercialProject(
                characteristic.typeID,
                projectID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  //AÇÕES REFERENTES AS CARACTERISTICAS DOS PROJETOS DESIGN DE INTERiores
  getCurrentCharacteristicsListInteriorDesignProject: (typeID, designID) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/characteristicTypes/all/characteristicTypeID/${typeID}/interiorDesignProjectID/${designID}`
        )
        .then((resp) => {
          dispatch({
            type: projectActions.GET_CURRENT_CHARACTERISTICS_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newCharacteristicInteriorDesignProject: (
    optionsCharacteristicsList,
    currentCharacteristicsList,
    designID
  ) => {
    var position, characteristic;
    for (let i = 0; i < optionsCharacteristicsList.length; i++) {
      position = currentCharacteristicsList
        .map(function (charac) {
          return charac.characteristicID;
        })
        .indexOf(optionsCharacteristicsList[i].characteristicID);
      if (position < 0) {
        characteristic = optionsCharacteristicsList[i];
        break;
      }
    }
    if (characteristic) {
      return (dispatch) => {
        axios
          .post(
            `${globals.API_URL}/interiorDesignProjects/characteristicProjectRelation`,
            {
              CharacteristicCharacteristicID: characteristic.characteristicID,
              InteriorDesignDesignID: designID,
            }
          )
          .then((resp) => {
            if (resp.status == 200) {
              dispatch(
                projectActions.getCurrentCharacteristicsListInteriorDesignProject(
                  characteristic.typeID,
                  designID
                )
              );
            }
          })
          .catch((error) => {
            console.log("Houve um erro interno. " + error);
          });
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais características para esse tipo.");
      };
    }
  },
  editAllCharacteristicsInteriorDesignProject: (
    characteristicsList,
    designID
  ) => {
    return (dispatch) => {
      axios
        .post(
          `${globals.API_URL}/interiorDesignProjects/renewCharacteristicProjectRelation`,
          {
            characteristicsList: characteristicsList,
            designID: designID,
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsListInteriorDesignProject(
                characteristicsList[0].typeID,
                designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteCharacteristicInteriorDesignProject: (characteristic, designID) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/characteristics/id/${characteristic.characteristicID}/interiorDesignID/${designID}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(
              projectActions.getCurrentCharacteristicsListInteriorDesignProject(
                characteristic.typeID,
                designID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },

  toggleViewArchitectural: (view) => ({
    type: projectActions.TOGGLE_VIEW_ARCHITECTURAL,
    view,
  }),
  toggleViewInConstruction: (view) => ({
    type: projectActions.TOGGLE_VIEW_IN_CONSTRUCTION,
    view,
  }),
  toggleViewCommercial: (view) => ({
    type: projectActions.TOGGLE_VIEW_COMMERCIAL,
    view,
  }),
  toggleViewCustom: (view) => ({
    type: projectActions.TOGGLE_VIEW_CUSTOM,
    view,
  }),
  toggleViewInteriorDesign: (view) => ({
    type: projectActions.TOGGLE_VIEW_INTERIOR_DESIGN,
    view,
  }),
  clearCurrentProject: () => ({ type: projectActions.CLEAR_CURRENT_PROJECT }),
};
export default projectActions;
