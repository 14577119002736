/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { useCallback } from "react";

export const DiscountContextOther = createContext();

export function DiscountContextProvider({ children }) {
  const [originalPrice, setOriginalPrice] = useState(0); // Preço Final
  const [finalPrice, setFinalPrice] = useState(0); // Preço Final
  const [priceCart, setPriceCart] = useState(0);
  const [priceBoleto, setPriceBoleto] = useState(0);
  const [discountSeller, setDiscountSeller] = useState(0);
  const [discountCash, setDiscountCash] = useState(0);
  const [discount, setDiscount] = useState(0); // Desconto
  const [coupon, setCoupon] = useState("");

  const calculateFinalPrice = useCallback(() => {
    let totalPrice = originalPrice * (1 - discount / 100);
    totalPrice = totalPrice * (1 - discountSeller / 100);
    if (discountCash !== 0) {
      handlerPriceBoleto(totalPrice);
      // handlerPriceBoleto(totalPrice * (1 - discountCash / 100));
    } else {
      handlerPriceBoleto(totalPrice);
    }
    handlePriceChange(totalPrice);
    handlerPriceCart(totalPrice);
  });

  useEffect(() => {
    calculateFinalPrice();
  }, [originalPrice, discount, discountSeller, discountCash, coupon]);

  const handlerPriceCart = (value) => {
    setPriceCart(value);
  };
  const handlerCoupon = (value) => {
    setCoupon(value);
  };
  const handlerPriceBoleto = (value) => {
    setPriceBoleto(value);
  };
  const handlerPriceOrigin = (value) => {
    setOriginalPrice(value);
  };
  const handleDiscountCashChange = (value) => {
    setDiscountCash(value);
  };
  const handleDiscountSellerChange = (value) => {
    setDiscountSeller(value);
  };
  const handleDiscountChange = (value) => {
    setDiscount(value);
  };
  const handlePriceChange = (value) => {
    setFinalPrice(value);
  };
  const handleLoadingValue = (data) => {
    setPriceCart(data.priceCart);
    setCoupon(data.coupon);
    setPriceBoleto(data.priceBoleto);
    setOriginalPrice(data.priceOrigin);
    setDiscountCash(data.discountCash);
    setDiscountSeller(data.discountSeller);
    setDiscount(data.discount);
    setFinalPrice(data.finalPrice);
  };

  return (
    <DiscountContextOther.Provider
      value={{
        originalPrice,
        finalPrice,
        discount,
        coupon,
        priceCart,
        priceBoleto,
        handlerPriceCart,
        handlerPriceBoleto,
        discountSeller,
        discountCash,
        handleDiscountChange,
        handleDiscountCashChange,
        handleDiscountSellerChange,
        handlePriceChange,
        handlerPriceOrigin,
        handlerCoupon,
        handleLoadingValue
      }}
    >
      {children}
    </DiscountContextOther.Provider>
  );
}
