import actions from './actions';
const userKey = '_admin_user'
const sessionKey = '_admin_sessionKey'

const initState = {
  user: localStorage.getItem(userKey) ? JSON.parse(localStorage.getItem(userKey)) : null,
  session: localStorage.getItem(sessionKey) ? JSON.parse(localStorage.getItem(sessionKey)) : null,
  validToken: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      localStorage.setItem(userKey, JSON.stringify(action.payload.user));
      localStorage.setItem(sessionKey, JSON.stringify(action.payload.token));
      return {
        ...state,
        session: action.payload.token,
        user: action.payload.user,
        validToken: true
      };
    case actions.SESSION_VALIDATED:
      if (action.payload) {
        return { ...state, validToken: true }
      } else {
        localStorage.removeItem(userKey)
        localStorage.removeItem(sessionKey)
        return { ...state, validToken: false, user: null }
      }
    default:
      return state;
  }
}
