import actions from './actions';


const initState = {
  sales: [],
  initialSales: false,
  currentSale: null,
  salesLoading: false
};


export default function cardReducer(state = initState, action) {
  switch (action.type) {
    case actions.UNLOAD_SALES: {
      return {
        ...state,
        initialSales: false,
        currentSale: false
      }
    }

    case actions.GET_SALE: {
      return {
        ...state,
        currentSale: action.payload.sale
      };
    }

    case actions.GET_ALL_SALES: {
      return {
        ...state,
        sales: action.payload.sales,
        initialSales: true
      }
    }

    case actions.TOGGLE_LOADING: {
      return {
        ...state,
        salesLoading: !state.salesLoading
      }
    }
      
    default:
      return state;
  }
}
