/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";

export const DiscountContextMain = createContext();

const INITIAL_STATE = {
  originalPrice: 0,
  finalPrice: 0,
  consultantDiscount: 0,
  couponID: "",
  inclusionStatus: false,
};
const INITIAL_NEGOTIATION = {
  architectural: false,
  structural: false,
  electrical: false,
  hydrosanitary: false,
  airConditioning: false,
  interiorDesign: false,
  landscaping: false,
  seller: false,
  cash: false,
};
export function DiscountContextMainProvider({ children }) {
  const [architecturalNegotiation, setArchitecturalNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do projeto arquitetônico
  const [structuralNegotiation, setStructuralNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do projeto estrutural
  const [electricalNegotiation, setElectricalNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do projeto elétrico
  const [hydrosanitaryNegotiation, setHydrosanitaryNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do projeto hidrossanitário
  const [airConditioningNegotiation, setAirConditioningNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do projeto de climatização
  const [landscapingNegotiation, setLandscapingNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do projeto hidrossanitário
  const [interiorDesignNegotiation, setInteriorDesignNegotiation] =
    useState(INITIAL_STATE); // Configuração da negociação do design de interiores
  const [sellerNegotiation, setSellerNegotiation] = useState(INITIAL_STATE); // Configuração da negociação do vendedor
  const [cashNegotiation, setCashNegotiation] = useState(INITIAL_STATE); // Configuração da negociação do pagamento à vista

  const [negotiationChanged, setNegotiationChanged] =
    useState(INITIAL_NEGOTIATION);

  /* ----- Funções que tratam a mudança de descontos no menu lateral ----- */
  const handleDiscountFunctions = {
    architectural: (value) => {
      setArchitecturalNegotiation({
        ...architecturalNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, architectural: true });
    },
    structural: (value) => {
      setStructuralNegotiation({
        ...structuralNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, structural: true });
    },
    electrical: (value) => {
      setElectricalNegotiation({
        ...electricalNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, electrical: true });
    },
    hydrosanitary: (value) => {
      setHydrosanitaryNegotiation({
        ...hydrosanitaryNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, hydrosanitary: true });
    },
    airConditioning: (value) => {
      setAirConditioningNegotiation({
        ...airConditioningNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, airConditioning: true });
    },
    interiorDesign: (value) => {
      setInteriorDesignNegotiation({
        ...interiorDesignNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, interiorDesign: true });
    },
    landscaping: (value) => {
      setLandscapingNegotiation({
        ...landscapingNegotiation,
        consultantDiscount: value,
      });
      setNegotiationChanged({ ...negotiationChanged, landscaping: true });
    },
    seller: (value) => {
      setSellerNegotiation({ ...sellerNegotiation, consultantDiscount: value });
      setNegotiationChanged({ ...negotiationChanged, seller: true });
    },
    cash: (value) => {
      setCashNegotiation({ ...cashNegotiation, consultantDiscount: value });
      setNegotiationChanged({ ...negotiationChanged, cash: true });
    },
  };
  /* --------------------------------------------------------------------- */
  return (
    <DiscountContextMain.Provider value={{}}>
      {children}
    </DiscountContextMain.Provider>
  );
}
