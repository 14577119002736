import globals from '../../globals';
import axios from 'axios'

const productTypeActions = {
  GET_ALL_PRODUCT_TYPES: 'GET_ALL_PRODUCT_TYPES',
  GET_ALL_PRODUCT_TYPES_LOADING: 'GET_ALL_PRODUCT_TYPES_LOADING',
  UPDATE_EDIT_PRODUCT_TYPES_LIST: 'UPDATE_EDIT_PRODUCT_TYPES_LIST',
  GET_CURRENT_PRODUCT_TYPE: 'GET_CURRENT_PRODUCT_TYPE',
  GET_CURRENT_PRODUCT_TYPE_LIST: 'GET_CURRENT_PRODUCT_TYPE_LIST',
  GET_CURRENT_PRODUCT_TYPE_LOADING : 'GET_CURRENT_PRODUCT_TYPE_LOADING',
  UPDATE_EDIT_PRODUCTS_TYPES_LIST : 'UPDATE_EDIT_PRODUCTS_TYPES_LIST',
  // AÇÕES REFERENTE AOS TIPOS DE PRODUTOS
  getAllProductTypes: () => {
    return (dispatch) => {
      dispatch({type : productTypeActions.GET_ALL_PRODUCT_TYPES_LOADING})
      axios.get(`${globals.API_URL}/interiorDesignProductTypes/all`)
        .then((resp) => {
          dispatch({
            type: productTypeActions.GET_ALL_PRODUCT_TYPES,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  getCurrentProductType: (typeID) => {
    return (dispatch) => {
      dispatch({type : productTypeActions.GET_CURRENT_PRODUCT_TYPE_LOADING})
      axios.get(`${globals.API_URL}/interiorDesignProductTypes/id/${typeID}`)
        .then((resp) => {
          dispatch({
            type: productTypeActions.GET_CURRENT_PRODUCT_TYPE,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newProductType: () => {
    const newProductType = {
      name: "Sem Nome",
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/interiorDesignProductTypes/`, newProductType)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productTypeActions.getAllProductTypes())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllProductTypes: (productTypesList) => {
    return (dispatch) => {
      dispatch({
        type: productTypeActions.UPDATE_EDIT_PRODUCT_TYPES_LIST,
        payload: productTypesList,
      });
    };
  },
  editAllProductTypes: (productTypesList) => {
    return (dispatch) => {
      productTypesList.forEach(productType => {
        dispatch(productTypeActions.editProductType(productType.typeID, productType))
      });
      dispatch(productTypeActions.getAllProductTypes())
    };
  },
  editProductType: (typeID, values) => {

    return (dispatch) => {
      axios.put(`${globals.API_URL}/interiorDesignProductTypes/id/${typeID}`, values)
        .then((resp) => {
          
          console.log("Tipo de produto atualizada com sucesso: " + values.name);
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteProductType: (productType) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/interiorDesignProductTypes/id/${productType.typeID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productTypeActions.getAllProductTypes())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },


  //AÇOẼS REFERENTES AOS TIPOS DE PRODUTOS DE DESIGN DE INTERIORES
  getCurrentProductTypesListByDesignID : (designID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/interiorDesignProductTypes/all/${designID}/interiorDesignProjects`)
        .then((resp) => {
          dispatch({
            type: productTypeActions.GET_CURRENT_PRODUCT_TYPE_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newProductTypeInteriorDesignProject : (optionsProductsTypeList, currentProductsTypeList, designID) => {
    var position, productType
    for (let i = 0; i < optionsProductsTypeList.length; i++) {
      position = currentProductsTypeList.map(function (type) {
        return type.typeID
      }).indexOf(optionsProductsTypeList[i].typeID)
      if (position < 0) {
        productType = optionsProductsTypeList[i]
        break
      }
    }

    if(productType){
      return (dispatch) => {
        axios.get(`${globals.API_URL}/interiorDesignProductTypes/id/${productType.typeID}/products`)
          .then((resp) => {
            if (resp.status == 200) {
              var products = resp.data.products
              axios.post(`${globals.API_URL}/interiorDesignProjects/interiorDesignProductsProjectRelation`, {
                InteriorDesignProductInteriorDesignProductID: products[0].interiorDesignProductID,
                InteriorDesignDesignID: designID
              })
                .then((resp) => {
                  if (resp.status == 200) {
                    dispatch(productTypeActions.getCurrentProductTypesListByDesignID(designID))
                  }
                }).catch((error) => {
                  console.log("Houve um erro interno. " + error);
                })
            }
          }).catch((error) => {
            console.log("Houve um erro interno. " + error);
          })
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais tipos de características para esse tipo.");
      };
    }
  },
  deleteProductsTypeInteriorDesignProject: (typeID, designID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/interiorDesignProductTypes/id/${typeID}/interiorDesignProjects/${designID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productTypeActions.getCurrentProductTypesListByDesignID(designID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  editAllProductsTypesInteriorDesignProject: (productsTypesList, designID) => {
    return (dispatch) => {
      axios.post(`${globals.API_URL}/interiorDesignProjects/renewInteriorDesignProductTypesProjectRelation`, {
        productsTypesList: productsTypesList,
        designID: designID
      })
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productTypeActions.getCurrentProductTypesListByDesignID(designID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllProductsTypes: (position, newType, oldType, productsTypesList) => {
    for (let i = 0; i < productsTypesList.length; i++) {
      if (i != position && productsTypesList[i].typeID == newType.typeID) {
        productsTypesList[i].typeID = oldType.typeID
        productsTypesList[i].name = oldType.name
      }
    }
    return (dispatch) => {
      dispatch({
        type: productTypeActions.UPDATE_EDIT_PRODUCT_TYPES_LIST,
        payload: productsTypesList,
      });
    };
  },
};
export default productTypeActions;
