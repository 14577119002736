import React, { createContext, useState, useContext, useEffect } from 'react'
import axios from 'axios';
import globals from '../globals';
import _ from 'lodash';
import { clearObject } from '../library/helpers/clearObject'

export const BudgetContext = createContext()

export function BudgetContextProvider({ children }) {
  const [budgets, setBudgets] = useState([])
  const [currentPaginatedBudget, setCurrentPaginatedBudget] = useState(1)
  const [budgetPerPage, setBudgetPerPage] = useState(10)
  const [mustShow, setMustShow] = useState(true)
  const [filterChanged, setFilterChanged] = useState(false)
  const [hasTypingFinished, setHasTypingFinished] = useState(false)
  const [pagination, setPagination] = useState({
    limit: 100,
    skip: 0
  })
  const [filters, setFilters] = useState({
    budgetID: undefined,
    collaborator: undefined,
    status: undefined,
    createdAt: undefined,
    customer: undefined
  })
  const [hasMore, setHasMore] = useState(true)

  const formatCurrentTab = () => {
    switch (filters.status) {
      case 'Todos':
        return undefined
      case 'Em Andamento':
        return 'Em Andamento'
      case "Em Desenvolvimento":
        return 'Em Desenvolvimento'
      case "Em Negociação":
        return 'Em Negociação'
      case "Concluído":
        return 'Concluído'
      case "Cancelado":
        return 'Cancelado'
      default:
        return undefined
    }
  }

  const handleGetBudgets = async (hasChanged, filter = undefined) => {
    let newFilters = {}
    if (filter) {
      if (hasChanged) {
        newFilters = {
          ...filter,
          status: filter?.status ? filter.status : formatCurrentTab(),
          budgetID: filter.budgetID ? Number(filter.budgetID) : undefined,
          limit: 100,
          skip: 0
        }
      } else {
        newFilters = {
          ...filter,
          status: filter?.status ? filter.status : formatCurrentTab(),
          budgetID: filter.budgetID ? Number(filter.budgetID) : undefined,
          limit: pagination.limit,
          skip: pagination.skip
        }
      }
    } else {
      newFilters = {
        ...filters,
        status: formatCurrentTab(),
        budgetID: filters.budgetID ? Number(filters.budgetID) : undefined,
        limit: pagination.limit,
        skip: pagination.skip
      }
    }

    const params = clearObject(newFilters, 'clear')
    const queryString = new URLSearchParams(params).toString()

    try {
      let tempBudgets = []
      const res = await axios.get(`${globals.API_URL}/budgets/all/table?${queryString}`)
      if (res.data.budgets.length < 100) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      if (hasChanged) {
        tempBudgets = res.data.budgets
        setBudgets(tempBudgets)
      } else {
          tempBudgets = [...budgets, ...res.data.budgets]
          const filteredArray = _.uniqBy(tempBudgets, 'budgetID')
          setBudgets(filteredArray)
      }

      setPagination({ ...pagination, skip: tempBudgets.length })

      if (tempBudgets === 0) {
        setMustShow(false)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeFilters = (type, value) => {
    switch (type) {
      case 'status':
        setFilters({ ...filters, status: value })
        break;
      case 'collaborator':
        setFilters({ ...filters, collaborator: value })
        break;
      case 'activeTab':
        setFilters({ ...filters, activeTab: value })
        break;
      case 'initialDate':
        setFilters({ ...filters, initialDate: value })
        break;
      case 'clientName':
        setFilters({ ...filters, clientName: value })
        break;
      default:
        break;
    }
  }

  const reset = () => {
    // setBudgets([])
    setBudgetPerPage(10)
    setCurrentPaginatedBudget(1)
    setFilterChanged(true)
    setPagination({ limit: 100, skip: 0 })
    setFilters({
      budgetID: undefined,
      collaborator: undefined,
      status: undefined,
      initialDate: undefined,
      customer: undefined
    })
  }

  useEffect(() => {
    setBudgetPerPage(10)
    setCurrentPaginatedBudget(1)
    setFilterChanged(true)
  }, [filters])

  // useEffect(() => {
  //   if (filterChanged) {
  //     setCurrentPaginatedBudget(1)
  //     setPagination({ limit: 100, skip: 0 })
  //   }
  // }, [filterChanged])


  return (
    <BudgetContext.Provider
      value={{
        budgets,
        setBudgets,
        currentPaginatedBudget,
        setCurrentPaginatedBudget,
        budgetPerPage,
        setBudgetPerPage,
        filters,
        setFilters,
        handleGetBudgets,
        handleChangeFilters,
        reset,
        filterChanged,
        setFilterChanged,
        setHasTypingFinished,
        mustShow,
        hasMore
      }}
    >
      {children}
    </BudgetContext.Provider>
  )
}

export const useBudget = () => {
  const context = useContext(BudgetContext)
  return context
}
