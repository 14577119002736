import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Mails from "@iso/redux/mail/reducer";
import Calendar from "@iso/redux/calendar/reducer";
import Box from "@iso/redux/box/reducer";
import Notes from "@iso/redux/notes/reducer";
import Todos from "@iso/redux/todos/reducer";
import Contacts from "@iso/redux/contacts/reducer";
import Cards from "@iso/redux/card/reducer";
import Chat from "@iso/redux/chat/reducers";
import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
import Ecommerce from "@iso/redux/ecommerce/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import Invoices from "@iso/redux/invoice/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import YoutubeSearch from "@iso/redux/youtubeSearch/reducers";
import Articles from "@iso/redux/articles/reducers";
import Investors from "@iso/redux/investors/reducers";
import scrumBoard from "@iso/redux/scrumBoard/reducer";
import drawer from "@iso/redux/drawer/reducer";
import modal from "@iso/redux/modal/reducer";
import profile from "@iso/redux/profile/reducer";
import githubSearch from "@iso/redux/githubSearch/reducers";
import quiz from "@iso/redux/quiz/reducer";

import Projects from "@iso/redux/projects/reducer";
import Comments from "@iso/redux/comment/reducer";
import Attendances from "@iso/redux/attendance/reducer";
import Coupons from "@iso/redux/coupons/reducer";
import Collaborators from "@iso/redux/collaborators/reducer";
import Characteristics from "@iso/redux/characteristics/reducer";
import Budgets from "@iso/redux/budget/reducer";
import Sales from "@iso/redux/sale/reducer";
import ArchitecturalAdditionals from "@iso/redux/architecturalAdditionals/reducer";
import ConstruMaisAdditionals from "@iso/redux/construMaisAdditionals/reducer";
import StructuralAdditionals from "@iso/redux/structuralAdditionals/reducer";
import ElectricalAdditionals from "@iso/redux/electricalAdditionals/reducer";
import HydrosanitaryAdditionals from "@iso/redux/hydrosanitaryAdditionals/reducer";
import AirConditioningAdditionals from "@iso/redux/airConditioningAdditionals/reducer";
import Additionals from "@iso/redux/additional/reducer";
import InteriorDesignAdditionals from "@iso/redux/interiorDesignAdditionals/reducer";
import LandscapingAdditionals from "@iso/redux/landscapingAdditionals/reducer";
import OldBudgets from "@iso/redux/oldBudget/reducer";
import Persons from "@iso/redux/person/reducer";
import AreaTypes from "@iso/redux/areaTypes/reducer";
import Gallery from "@iso/redux/gallery/reducer";
import InteriorDesignProductstypes from "@iso/redux/interiorDesignProductsTypes/reducer";
import InteriorDesignProducts from "@iso/redux/interiorDesignProducts/reducer";
import Feedbacks from "@iso/redux/feedbacks/reducer";
import Stores from "@iso/redux/stores/reducer";
import Promotions from "@iso/redux/promotions/reducer";
import PopUps from "@iso/redux/popUps/reducer";
import Partners from "@iso/redux/partners/reducer";
import WhatsAppGroups from "@iso/redux/whatsAppGroups/reducer";
import CustomerManagement from "@iso/redux/customerManagement/reducer";

export default combineReducers({
  App,
  Auth,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
  Projects,
  Comments,
  Collaborators,
  Characteristics,
  Coupons,
  Budgets,
  Sales,
  ArchitecturalAdditionals,
  ConstruMaisAdditionals,
  StructuralAdditionals,
  ElectricalAdditionals,
  HydrosanitaryAdditionals,
  AirConditioningAdditionals,
  Additionals,
  InteriorDesignAdditionals,
  LandscapingAdditionals,
  OldBudgets,
  Persons,
  AreaTypes,
  Gallery,
  InteriorDesignProductstypes,
  InteriorDesignProducts,
  Feedbacks,
  Stores,
  Promotions,
  PopUps,
  Partners,
  Attendances,
  WhatsAppGroups,
  CustomerManagement,
});
