import actions from './actions';



const initState = {
    allConstruMaisAdditionals: []
};



export default function construMaisAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_CONSTRU_MAIS_ADDITIONALS:
            return {
                ...state,
                allConstruMaisAdditionals: action.payload.construMaisAdditionals,
            };
        default:
            return state;
    }
}