import globals from '../../globals';
import axios from 'axios';


const landscapingAdditionalActions = {
    GET_ALL_LANDSCAPING_ADDITIONALS: 'GET_ALL_LANDSCAPING_ADDITIONALS',

    getAllLandscapingAdditionals: () => {
        return (dispatch) => {
            axios.get(`${globals.API_URL}/landscapingAdditionals/all`)
                .then((resp) => {
                    dispatch({
                        type: landscapingAdditionalActions.GET_ALL_LANDSCAPING_ADDITIONALS,
                        payload: {
                            landscapingAdditionals: resp.data.landscapingAdditionals
                        }
                    });
                }).catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        };
    }
};



export default landscapingAdditionalActions;