import globals from "../../globals";
import axios from "axios";

import authActions from "@iso/redux/auth/actions";

function ascendingSort(collaborator1, collaborator2) {
  const name1 = collaborator1.name ? collaborator1.name.toUpperCase() : "~";
  const name2 = collaborator2.name ? collaborator2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const collaboratorActions = {
  GET_ALL_CURRENT_COLLABORATORS: "GET_ALL_CURRENT_COLLABORATORS",
  GET_OPTIONS_MODULES: "GET_OPTIONS_MODULES",
  GET_CURRENT_MODULES_LIST: "GET_CURRENT_MODULES_LIST",
  GET_ALL_TEAMS: "GET_ALL_TEAMS",
  UPDATE_EDIT_TEAMS_LIST: "UPDATE_EDIT_TEAMS_LIST",
  ADD_COLLABORATOR: "ADD_COLLABORATOR",
  EDIT_COLLABORATOR: "EDIT_COLLABORATOR",
  DELETE__COLLABORATOR: "DELETE__COLLABORATOR",
  CHANGE_COLLABORATOR: "CHANGE_COLLABORATOR",
  EDIT_VIEW: "EDIT_VIEW",
  UPDATE_ALL_ROULETTE_COLLABORATOR: "UPDATE_ALL_ROULETTE_COLLABORATOR",
  RESET_ROULETTES: "RESET_ROULETTES",
  getAllCurrentCollaborators: (teamID) => {
    console.log("\n################");
    console.log(globals.API_URL);
    console.log("################\n");
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/teams/id/${teamID}/collaborators`)
        .then((resp) => {
          dispatch({
            type: collaboratorActions.GET_ALL_CURRENT_COLLABORATORS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getOptionsModulesList: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/modules/all`)
        .then((resp) => {
          dispatch({
            type: collaboratorActions.GET_OPTIONS_MODULES,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  relationAllModules: (team, modulesList) => {
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/teams/moduleTeamRelation`, {
          TeamTeamID: team.teamID,
          modulesList: modulesList,
        })
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(collaboratorActions.getCurrentModulesList(team.teamID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getCurrentModulesList: (teamID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/modules/all/teams/${teamID}`)
        .then((resp) => {
          dispatch({
            type: collaboratorActions.GET_CURRENT_MODULES_LIST,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getAllTeams: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/teams/all`)
        .then((resp) => {
          dispatch({
            type: collaboratorActions.GET_ALL_TEAMS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newTeam: () => {
    const newTeam = {
      name: "Sem Nome",
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/teams/`, newTeam)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(collaboratorActions.getAllTeams());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateEditAllTeams: (teamsList) => {
    return (dispatch) => {
      dispatch({
        type: collaboratorActions.UPDATE_EDIT_TEAMS_LIST,
        payload: teamsList,
      });
    };
  },
  editAllTeams: (teamsList) => {
    return (dispatch) => {
      teamsList.forEach((team) => {
        dispatch(collaboratorActions.editTeam(team.teamID, team));
      });
    };
  },
  editTeam: (teamID, values) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/teams/id/${teamID}`, values)
        .then((resp) => {
          console.log("Equipe atualizada com sucesso: " + values.name);
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteTeam: (team) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/teams/id/${team.teamID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(collaboratorActions.getAllTeams());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  changeCollaborator: (email) => ({
    type: collaboratorActions.CHANGE_COLLABORATOR,
    email,
  }),

  addCollaborator: (teamID) => {
    const newCollaborator = {
      name: "Sem Nome",
      email: "novocolaborador@email.com",
      phone: "(99) 9 9999-9999",
      birthDate: "12/12/2012",
      cpf: "000.000.000-00",
      passiveLock: 1,
      activeLock: 1,
      intermediaryLock: 1,
      socialLock: 1,
      fbLeadsLock: 1,
      whatsappLock: 1,
      chatLock: 1,
      teamID: teamID,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/collaborators/`, newCollaborator)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: collaboratorActions.ADD_COLLABORATOR,
              selectedEmail: newCollaborator.email,
            });
            dispatch(collaboratorActions.getAllCurrentCollaborators(teamID));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editCollaborator: (email, teamID, values) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/collaborators/email/${email}`, values)
        .then((resp) => {
          console.log("Colaborador atualizado com sucesso: " + values.name);
          dispatch(collaboratorActions.getAllCurrentCollaborators(teamID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteCollaborator: (collaborator) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/collaborators/email/${collaborator.email}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: collaboratorActions.DELETE__COLLABORATOR,
            });
            dispatch(
              collaboratorActions.getAllCurrentCollaborators(
                collaborator.teamID
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  viewChange: (view) => ({
    type: collaboratorActions.EDIT_VIEW,
    view,
  }),
  newInternalUser: (teamID, values) => {
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/internalUsers/register`, values)
        .then((resp) => {
          console.log("Usuário interno criado com sucesso.");
          dispatch(collaboratorActions.getAllCurrentCollaborators(teamID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  editInternalUser: (values) => {
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/internalUsers/edit`, values)
        .then((resp) => {
          console.log("Usuário interno atualizado com sucesso.");
          dispatch(authActions.logout());
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateAllRouletteCollaborator: (email, teamID, values) => {
    return (dispatch) => {
      axios
        .put(
          `${globals.API_URL}/collaborators/email/${email}/roulettes`,
          values
        )
        .then((resp) => {
          console.log("Colaborador atualizado com sucesso: " + values.name);
          dispatch(collaboratorActions.getAllCurrentCollaborators(teamID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  resetRoulette: (teamID) => {
    return (dispatch) => {
      axios
        .put(`${globals.API_URL}/collaborators/roulettes/reset`, [])
        .then((resp) => {
          console.log("Roleta Zerada com sucesso");
          dispatch(collaboratorActions.getAllCurrentCollaborators(teamID));
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
};
export default collaboratorActions;
