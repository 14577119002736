import React, { createContext, useState, useContext, useEffect } from 'react'
import axios from 'axios';
import globals from '../globals';
import _ from 'lodash';
import { clearObject } from '../library/helpers/clearObject'

export const SaleContext = createContext()

export function SaleContextProvider({ children }) {
  const [sales, setSales] = useState([])
  const [currentPaginatedSale, setCurrentPaginatedSale] = useState(1)
  const [salePerPage, setSalePerPage] = useState(10)
  const [mustShow, setMustShow] = useState(true)
  const [filterChanged, setFilterChanged] = useState(false)
  const [hasTypingFinished, setHasTypingFinished] = useState(false)
  const [pagination, setPagination] = useState({
    limit: 100,
    skip: 0
  })
  const [filters, setFilters] = useState({
    saleID: undefined,
    status: undefined,
    type: undefined,
    createdAt: undefined,
    customer: undefined
  })
  const [hasMore, setHasMore] = useState(true)

  const formatCurrentTab = () => {
    switch (filters.status) {
      case 'Todos':
        return undefined
      case 'Em Andamento':
        return 'Em Andamento'
      case "Em Desenvolvimento":
        return 'Em Desenvolvimento'
      case "Em Negociação":
        return 'Em Negociação'
      case "Concluído":
        return 'Concluído'
      case "Cancelado":
        return 'Cancelado'
      default:
        return undefined
    }
  }

  const handleGetSales = async (hasChanged, filter = undefined) => {
    let newFilters = {}
    if (filter) {
      if (hasChanged) {
        newFilters = {
          ...filter,
          status: filter?.status ? filter.status : formatCurrentTab(),
          saleID: filter.saleID ? Number(filter.saleID) : undefined,
          limit: 100,
          skip: 0
        }
      } else {
        newFilters = {
          ...filter,
          status: filter?.status ? filter.status : formatCurrentTab(),
          saleID: filter.saleID ? Number(filter.saleID) : undefined,
          limit: pagination.limit,
          skip: pagination.skip
        }
      }
    } else {
      newFilters = {
        ...filters,
        status: formatCurrentTab(),
        saleID: filters.saleID ? Number(filters.saleID) : undefined,
        limit: pagination.limit,
        skip: pagination.skip
      }
    }

    const params = clearObject(newFilters, 'clear')
    const queryString = new URLSearchParams(params).toString()

    try {
      let tempSales = []
      const res = await axios.get(`${globals.API_URL}/sales/all?${queryString}`)
      if (res.data.sales.length < 100) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      if (hasChanged) {
        tempSales = res.data.sales
        setSales(tempSales)
      } else {
          tempSales = [...sales, ...res.data.sales]
          const filteredArray = _.uniqBy(tempSales, 'saleID')
          setSales(filteredArray)
      }

      setPagination({ ...pagination, skip: tempSales.length < 100 ? 100 : tempSales.length})

      if (tempSales === 0) {
        setMustShow(false)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeFilters = (type, value) => {
    switch (type) {
      case 'status':
        setFilters({ ...filters, status: value })
        break;
      case 'activeTab':
        setFilters({ ...filters, activeTab: value })
        break;
      case 'initialDate':
        setFilters({ ...filters, initialDate: value })
        break;
      case 'clientName':
        setFilters({ ...filters, clientName: value })
        break;
      default:
        break;
    }
  }

  const reset = () => {
    // setSales([])
    setSalePerPage(10)
    setCurrentPaginatedSale(1)
    setFilterChanged(true)
    setPagination({ limit: 100, skip: 0 })
    setFilters({
      saleID: undefined,
      status: undefined,
      initialDate: undefined,
      customer: undefined,
      type: undefined
    })
  }

  useEffect(() => {
    setSalePerPage(10)
    setCurrentPaginatedSale(1)
    setFilterChanged(true)
    setHasMore(true)
  }, [filters])

  // useEffect(() => {
  //   if (filterChanged) {
  //     setCurrentPaginatedBudget(1)
  //     setPagination({ limit: 100, skip: 0 })
  //   }
  // }, [filterChanged])


  return (
    <SaleContext.Provider
      value={{
        sales,
        setSales,
        currentPaginatedSale,
        setCurrentPaginatedSale,
        salePerPage,
        setSalePerPage,
        filters,
        setFilters,
        handleGetSales,
        handleChangeFilters,
        reset,
        filterChanged,
        setFilterChanged,
        setHasTypingFinished,
        mustShow,
        hasMore
      }}
    >
      {children}
    </SaleContext.Provider>
  )
}

export const useSale = () => {
  const context = useContext(SaleContext)
  return context
}
