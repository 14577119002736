import globals from "../../globals";
import axios from "axios";
import moment from "moment";

const commentActions = {
  GET_ALL_COMMENTS: "GET_ALL_COMMENTS",
  LOADING_GET_ALL_COMMENTS: "LOADING_GET_ALL_COMMENTS",
  GET_ALL_CURRENT_REPLIES: "GET_ALL_CURRENT_REPLIES",
  FILTER_ATTRIBUTE: "FILTER_ATTRIBUTE",
  SELECTED_COMMENT: "SELECTED_COMMENT",
  COMPOSE_COMMENT: "COMPOSE_COMMENT",
  REPLY_COMMENT: "REPLY_COMMENT",
  SEARCH_STRING: "SEARCH_STRING",
  getAllComments: () => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/comments/all`)
        .then((resp) => {
          var newComments = resp.data.comments;
          var dateNow = new Date();
          var dateCheck, replyCheck;
          newComments.forEach((comment) => {
            axios
              .get(
                `${globals.API_URL}/comments/id/${comment.commentID}/replies`
              )
              .then((resp) => {
                if (resp.data.replies.length > 0) {
                  replyCheck = true;
                } else {
                  replyCheck = false;
                }
                if (replyCheck) {
                  comment.bucket = "Respondidos";
                } else {
                  comment.bucket = "Sem Resposta";
                }
                comment.createdAt = new Date(
                  new Date(comment.createdAt).getTime() + 3 * 3600 * 1000
                );
                dateCheck = (dateNow - moment(comment.createdAt)) / 1000 / 3600;
                if (comment.bucket == "Sem Resposta") {
                  if (dateCheck < 2) {
                    comment.tags = "Não Urgência";
                  } else if (dateCheck >= 2 && dateCheck < 24) {
                    comment.tags = "Pouca Urgência";
                  } else if (dateCheck >= 24 && dateCheck < 48) {
                    comment.tags = "Urgência";
                  } else if (dateCheck >= 48 && dateCheck < 72) {
                    comment.tags = "Muita Urgência";
                  } else if (dateCheck >= 72) {
                    comment.tags = "Emergência";
                  }
                }
                axios
                  .get(
                    `${globals.API_URL}/comments/id/${comment.commentID}/externalUser`
                  )
                  .then((resp) => {
                    comment.name = resp.data.user[0].name;
                    comment.email = resp.data.user[0].email;
                    comment.phone = resp.data.user[0].phone;
                    resp.data.comments = newComments;
                    dispatch({
                      type: commentActions.GET_ALL_COMMENTS,
                      payload: resp.data,
                    });
                  })
                  .catch((error) => {
                    console.log("Houve um erro interno. " + error);
                  });
              })
              .catch((error) => {
                console.log("Houve um erro interno. " + error);
              });
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getAllCurrentReplies: (commentID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/comments/id/${commentID}/replies`)
        .then((resp) => {
          dispatch({
            type: commentActions.GET_ALL_CURRENT_REPLIES,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteComment: (commentID) => {
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/comments/id/${commentID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(commentActions.getAllComments());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  newReply: (values) => {
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/commentsreplies/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(commentActions.getAllCurrentReplies(values.commentID));
            dispatch(commentActions.getAllComments());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteReply: (reply) => {
    console.log("chegamos aqui?");
    console.log(reply);
    return (dispatch) => {
      axios
        .delete(`${globals.API_URL}/commentsreplies/id/${reply.replyID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(commentActions.getAllCurrentReplies(reply.commentID));
            dispatch(commentActions.getAllComments());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  filterAction: (newFilterAttr) => {
    return (dispatch, getState) => {
      const filterAttr = getState().Comments.filterAttr;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
          // filterAttr.tag = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
        }
      }
      dispatch({
        type: commentActions.FILTER_ATTRIBUTE,
        filterAttr,
      });
    };
  },
  selectComment: (selectedComment) => {
    return (dispatch, getState) => {
      const allComments = getState().Comments.allComments;
      allComments[
        allComments.findIndex(
          (comment) => comment.commentID === selectedComment
        )
      ].read = true;
      dispatch(commentActions.getAllCurrentReplies(selectedComment));
      dispatch({
        type: commentActions.SELECTED_COMMENT,
        selectedComment,
        allComments,
      });
    };
  },
  changeComposeComment: (composeComment) => ({
    type: commentActions.COMPOSE_COMMENT,
    composeComment,
  }),
  changeReplyComment: (replyComment) => ({
    type: commentActions.REPLY_COMMENT,
    replyComment,
  }),
  changeSearchString: (searchString) => ({
    type: commentActions.SEARCH_STRING,
    searchString,
  }),
};
export default commentActions;
