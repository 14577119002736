import React from 'react';

const PendingPool = {};
const ReadyPool = {};

export default function ProjectMediaCell({ src }) {
  const [srcState, setSrcState] = React.useState(false);

  const getYouTubeThumbnail = (url) => {
    const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
    return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : null;
  };

  const poster = src.includes("youtube") && getYouTubeThumbnail(src);

  const onLoadImage = React.useCallback(
    (source) => {
      ReadyPool[src] = true;
      if (source === src) {
        setSrcState(source);
      }
    },
    [src]
  );
  const loadImage = React.useCallback(
    (src) => {
      if (ReadyPool[src]) {
        setSrcState(src);
        return;
      }

      if (PendingPool[src]) {
        PendingPool[src].push(onLoadImage);
        return;
      }
      PendingPool[src] = [onLoadImage];

      const img = new Image();
      img.onload = () => {
        PendingPool[src].forEach((callback) => {
          callback(src);
        });
        delete PendingPool[src];
        img.onload = null;
        src = undefined;
      };
      img.src = srcState;
    },
    [srcState, onLoadImage]
  );
  React.useEffect(() => {
    loadImage(src);
  }, [loadImage, src]);
  const style = src.includes("imagem.123projetei")
    ? {
        backgroundImage: `url(${src.replace(/ /g, "%20")})`,
        width: '150px',
        height: '75px',
        backgroundSize: 'cover',
      }
    : {
        width: '150px',
        height: '75px',
        objectFit: "cover",
      };
  return (
    src.includes("imagem.123projetei") 
    ? (
    <div className="exampleImage" style={style} /> 
    )
    : (
    <div>
      <video
          src={src}
          poster={poster}
          autoPlay
          loop
          muted
          style={style}
        />
      </div>
    )
  ) 
}
