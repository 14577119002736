import globals from "../../globals";
import axios from "axios";

const customerManagementActions = {
  NEW_REFERRAl_LEAD: "NEW_REFERRAl_LEAD",

  newReferralLead: (values) => {
    const newLead = {
      name: `${values.firstname} ${values.lastname}`.trim(),
      origin: values.origin,
      email: values.email,
      phone: values.phone,
      comment: values.comment,
    };
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/customerManagement/referral/new`, newLead)
        .then((resp) => {
          dispatch({
            type: customerManagementActions.NEW_REFERRAl_LEAD,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
};

export default customerManagementActions;
