import globals from '../../globals';
import axios from 'axios'

const characteristicActions = {
  GET_ALL_CHARACTERISTICS_TYPE: 'GET_ALL_CHARACTERISTICS_TYPE',
  UPDATE_EDIT_CHARACTERISTIC_TYPES_LIST: 'UPDATE_EDIT_CHARACTERISTIC_TYPES_LIST',
  GET_CURRENT_CHARACTERISTICS_LIST: 'GET_CURRENT_CHARACTERISTICS_LIST',
  UPDATE_EDIT_CHARACTERISTICS_LIST: 'UPDATE_EDIT_CHARACTERISTICS_LIST',

  // AÇÕES REFERENTE AOS TIPOS DE CARACTERÍSTICAS
  getAllCharacteristicTypes: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/characteristicTypes/all`)
        .then((resp) => {
          dispatch({
            type: characteristicActions.GET_ALL_CHARACTERISTICS_TYPE,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newCharacteristicType: () => {
    const newCharacteristicType = {
      name: "Sem Nome",
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/characteristicTypes/`, newCharacteristicType)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(characteristicActions.getAllCharacteristicTypes())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllCharacteristicTypes: (characteristicTypesList) => {
    return (dispatch) => {
      dispatch({
        type: characteristicActions.UPDATE_EDIT_CHARACTERISTIC_TYPES_LIST,
        payload: characteristicTypesList,
      });
    };
  },
  editAllCharacteristicTypes: (characteristicTypesList) => {
    return (dispatch) => {
      characteristicTypesList.forEach(characteristicType => {
        dispatch(characteristicActions.editCharacteristicType(characteristicType.typeID, characteristicType))
      });
    };
  },
  editCharacteristicType: (typeID, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/characteristicTypes/id/${typeID}`, values)
        .then((resp) => {
          console.log("Tipo de característica atualizada com sucesso: " + values.name);
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteCharacteristicType: (characteristicType) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/characteristicTypes/id/${characteristicType.typeID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(characteristicActions.getAllCharacteristicTypes())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  // AÇÕES REFERENTE ÀS CARACTERÍSTICAS
  getCurrentCharacteristicsList: (typeID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/characteristicTypes/id/${typeID}/characteristics`)
        .then((resp) => {
          dispatch({
            type: characteristicActions.GET_CURRENT_CHARACTERISTICS_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newCharacteristic: (typeID) => {
    const newCharacteristic = {
      description: "Sem Descrição",
      projectType: "Nulo",
      typeID: typeID
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/characteristics/`, newCharacteristic)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(characteristicActions.getCurrentCharacteristicsList(typeID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllCharacteristics: (characteristicsList) => {
    return (dispatch) => {
      dispatch({
        type: characteristicActions.UPDATE_EDIT_CHARACTERISTICS_LIST,
        payload: characteristicsList,
      });
    };
  },
  editAllCharacteristics: (characteristicsList) => {
    return (dispatch) => {
      characteristicsList.forEach(characteristic => {
        dispatch(characteristicActions.editCharacteristic(characteristic.characteristicID, characteristic))
      });
    };
  },
  editCharacteristic: (characteristicID, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/characteristics/id/${characteristicID}`, values)
        .then((resp) => {
          console.log("Característica atualizada com sucesso: " + values.description);
          dispatch(characteristicActions.getCurrentCharacteristicsList(values.typeID))
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteCharacteristic: (characteristic) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/characteristics/id/${characteristic.characteristicID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(characteristicActions.getCurrentCharacteristicsList(characteristic.typeID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
};
export default characteristicActions;
