import actions from "./actions";

const initState = {
  groups: [],
  isSuccess: false,
  isError: false,
  selectedGroupIndex: 0,
  feedbackMessage: "",
  isLoading: false,
  currentPage: 1,
  totalPages: 1,
  total: 0,
  instanceInfo:  {
    status: {
      accountStatus: "Loading",
    },
    phoneInfo: {
      id: "???????????@c.us",
      battery: "???????",
      locale: "??????",
      name: "??????",
      wa_version: "???????",
      device: {},
      avatar: "????",
    },
  },
};

export default function whatsAppGroupsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL:
      return {
        ...state,
        groups: action.payload.groups,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        total: action.payload.total,
        isSuccess: true,
      };

    case actions.IS_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSuccess: true,
        feedbackMessage: action.payload.message,
      };

    case actions.IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case actions.IS_ERROR:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isError: true,
        feedbackMessage: action.payload.message,
      };

    case actions.CLEAR_ERRORS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
      };

    case actions.GROUP_SELECTED:
      return {
        ...state,
        selectedGroupIndex: action.payload.index,
      };

    case actions.GET_INSTANCE_INFO:
      return {
        ...state,
        instanceInfo: action.payload.instanceInfo
      }
    default:
      return state;
  }
}
