import actions from './actions';



const initState = {
    allAirConditioningAdditionals: []
};



export default function airConditioningAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_AIRCONDITIONING_ADDITIONALS:
            return {
                ...state,
                allAirConditioningAdditionals: action.payload.airConditioningAdditionals,
            };
        default:
            return state;
    }
}