import globals from '../../globals';
import axios from 'axios';


const construMaisAdditionalActions = {
    GET_ALL_CONSTRU_MAIS_ADDITIONALS: 'GET_ALL_CONSTRU_MAIS_ADDITIONALS',

    getAllConstruMaisAdditionals: () => {
        return (dispatch) => {
            axios.get(`${globals.API_URL}/construMaisAdditionals/all`)
                .then((resp) => {
                    dispatch({
                        type: construMaisAdditionalActions.GET_ALL_CONSTRU_MAIS_ADDITIONALS,
                        payload: {
                            construMaisAdditionals: resp.data.construMaisAdditionals
                        }
                    });
                }).catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        };
    }
};



export default construMaisAdditionalActions;