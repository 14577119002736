import actions from './actions';



const initState = {
  allStructuralAdditionals: []
};



export default function structuralAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_STRUCTURAL_ADDITIONALS:
            return {
                ...state,
                allStructuralAdditionals: action.payload.structuralAdditionals,
            };
        default:
            return state;
    }
}