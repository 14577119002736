import globals from '../../globals';
import axios from 'axios';


const additionalActions = {
    GET_ALL_ADDITIONALS: 'GET_ALL_ADDITIONALS',

    getAllAdditional: () => {
        return (dispatch) => {
            axios.get(`${globals.API_URL}/additional/all`)
                .then((resp) => {
                    // console.log({data:resp.data})
                    dispatch({
                        type: additionalActions.GET_ALL_ADDITIONALS,
                        payload: {
                            allAdditionals: resp.data.additional
                        }
                    });
                }).catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        };
    }
};



export default additionalActions;