import globals from '../../globals';
import axios from 'axios';


const couponActions = {
  GET_ALL_COUPONS: 'GET_ALL_COUPONS',
  CREATE_COUPON: 'CREATE_COUPON',
  FILTER_ATTRIBUTE: 'FILTER_ATTRIBUTE',
  SELECTED_COUPON: 'SELECTED_COUPON',
  COMPOSE_COUPON: 'COMPOSE_COUPON',
  SEARCH_STRING: 'SEARCH_STRING',
  EDIT_VIEW: 'EDIT_VIEW',
  CREATE_VIEW: 'CREATE_VIEW',
  REPORT_ERROR: 'REPORT_ERROR',
  REPORT_SUCCESS: 'REPORT_SUCCESS',
  CLEAR_STATUS: 'CLEAR_STATUS',

  getAllCoupons: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/coupons/all`)
        .then((resp) => {
          dispatch({
            type: couponActions.GET_ALL_COUPONS,
            payload: {
              coupons: resp.data.coupons
            }
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getSingleCoupon: async (couponID) => {
    try {
      let resp = await axios.get(`${globals.API_URL}/coupons/id/${couponID}`)
      return resp.data.coupon[0];
    } catch (error) {
      console.log("Houve um erro interno. " + error);
      return null;
    }
  },

  editCoupon: (coupon, oldID) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/coupons/id/${oldID}`, coupon)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(couponActions.getAllCoupons());
            dispatch(couponActions.selectCoupon(coupon));
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  deleteCoupon: (couponID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/coupons/id/${couponID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(couponActions.getAllCoupons())
            dispatch(couponActions.reportSuccess());
          }
        }).catch((error) => {
          dispatch(couponActions.reportError());
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  createCoupon: (coupon) => {
    return (dispatch) => {
      axios.post(`${globals.API_URL}/coupons/`, coupon)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(couponActions.getAllCoupons())
            dispatch(couponActions.selectCoupon(coupon))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  filterAction: newFilterAttr => {
    return (dispatch, getState) => {
      const filterAttr = getState().Coupons.filterAttr;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
          filterAttr.tag = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
        }
      }
      dispatch({
        type: couponActions.FILTER_ATTRIBUTE,
        filterAttr,
      });
    };
  },

  selectCoupon: selectedCoupon => {
    return (dispatch) => {
      dispatch({
        type: couponActions.SELECTED_COUPON,
        selectedCoupon
      });
    };
  },

  changeComposeCoupon: composeCoupon => ({
    type: couponActions.COMPOSE_COUPON,
    composeCoupon,
  }),

  changeSearchString: searchString => ({
    type: couponActions.SEARCH_STRING,
    searchString,
  }),

  editViewChange: view => ({
    type: couponActions.EDIT_VIEW,
    view
  }),

  createViewChange: view => ({
    type: couponActions.CREATE_VIEW,
    view
  }),

  reportError: () => ({
    type: couponActions.REPORT_ERROR
  }),

  reportSuccess: () => ({
    type: couponActions.REPORT_SUCCESS
  }),

  clearCouponStatus: () => ({
    type: couponActions.CLEAR_STATUS
  })
};



export default couponActions;