import actions from './actions';

const initState = {
  allComments: [],
  allCurrentReplies: [],
  tag: undefined,
  selectedComment: -1,
  filterAttr: { bucket: 'Sem Resposta' },
  composeComment: false,
  replyComment: false,
  searchString: '',
};

export default function commentReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_COMMENTS:
      return {
        ...state,
        allComments: action.payload.comments,
      };
    case actions.GET_ALL_CURRENT_REPLIES:
      return {
        ...state,
        allCurrentReplies: action.payload.replies,
      };
    case actions.FILTER_ATTRIBUTE:
      return {
        ...state,
        composeComment: false,
        replyComment: false,
        selectedComment: -1,
        filterAttr: { ...action.filterAttr },
      };
    case actions.SELECTED_COMMENT:
      return {
        ...state,
        replyComment: false,
        selectedComment: action.selectedComment,
        allComments: action.allComments,
      };
    case actions.COMPOSE_COMMENT:
      return {
        ...state,
        replyComment: false,
        composeComment: action.composeComment,
      };
    case actions.REPLY_COMMENT:
      return {
        ...state,
        replyComment: action.replyComment,
      };
    case actions.SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    default:
      return state;
  }
}
