import actions from './actions';



const initState = {
  allLandscapingAdditionals: []
};



export default function landscapingAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_LANDSCAPING_ADDITIONALS:
            return {
                ...state,
                allLandscapingAdditionals: action.payload.landscapingAdditionals,
            };
        default:
            return state;
    }
}