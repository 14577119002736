import globals from '../../globals';
import axios from 'axios'

const feedbackActions = {
  NEW_FEEDBACK_RESET: 'NEW_FEEDBACK_RESET',
  NEW_FEEDBACK_LOADING: 'NEW_FEEDBACK_LOADING',
  NEW_FEEDBACK_SUCCESS: 'NEW_FEEDBACK_SUCCESS',
  NEW_FEEDBACK_ERROR: 'NEW_FEEDBACK_ERROR',
  GET_CURRENT_FEEDBACK : 'GET_CURRENT_FEEDBACK',
  GET_ALL_FEEDBACKS : 'GET_ALL_FEEDBACKS',
  EDIT_FEEDBACK_LOADING : 'EDIT_FEEDBACK_LOADING',
  EDIT_FEEDBACK_SUCCESS : 'EDIT_FEEDBACK_SUCCESS',
  EDIT_FEEDBACK_ERROR : 'EDIT_FEEDBACK_ERROR',
  TOGGLE_VIEW_FEEDBACK : 'TOGGLE_VIEW_FEEDBACK',
  GET_CURRENT_MEDIA_LIST_FEEDBACK : 'GET_CURRENT_MEDIA_LIST_FEEDBACK',
  UPDATE_EDIT_MEDIAS_LIST : 'UPDATE_EDIT_MEDIAS_LIST',
  newFeedbackReset: () => {
    return (dispatch) => {
      dispatch({
        type: feedbackActions.NEW_FEEDBACK_RESET
      });
    }
  },
  newFeedback: (values) => {
    return (dispatch) => {
      dispatch({
        type: feedbackActions.NEW_FEEDBACK_LOADING
      });
      axios.post(`${globals.API_URL}/feedbacks/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              payload : resp.data,
              type: feedbackActions.NEW_FEEDBACK_SUCCESS
            });
          } 
        }).catch((error) => {
          dispatch({
            type: feedbackActions.NEW_FEEDBACK_ERROR,
            error: error,
          });
        })
    };
  },

  getCurrentFeedback : (id) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/feedbacks/id/${id}`)
        .then((resp) => {
          dispatch({
            type: feedbackActions.GET_CURRENT_FEEDBACK,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  editFeedback : (id, values) => {
    return (dispatch) => {
      dispatch({
        type: feedbackActions.EDIT_FEEDBACK_LOADING
      });
      axios.put(`${globals.API_URL}/feedbacks/id/${id}`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: feedbackActions.EDIT_FEEDBACK_SUCCESS
            });
            dispatch(feedbackActions.toggleViewFeedback(false))
            // dispatch(feedbackActions.getCurrentArchitecturalProjects(values.projectID))
            // dispatch(feedbackActions.toggleViewArchitectural(false))
          }
        }).catch((error) => {
          dispatch({
            type: feedbackActions.EDIT_FEEDBACK_ERROR,
            error: error,
          });
        })
    };
  },

  getAllFeedbacks : () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/feedbacks/all`)
        .then((resp) => {
          dispatch({
            type: feedbackActions.GET_ALL_FEEDBACKS,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  
  removeFeedback : (id) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/feedbacks/id/${id}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(feedbackActions.getAllFeedbacks())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  updateEditAllMediasIndex: (mediaID, oldIndex, newIndex, mediasList) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex
      }
    }
    return (dispatch) => {
      dispatch({
        type: feedbackActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: feedbackActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },

  newMediaFeedback: (feedbackID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/feedbacks/${feedbackID}/images/image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      feedbackID: feedbackID
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(feedbackActions.getMediaByFeedbackID(feedbackID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getMediaByFeedbackID : (id) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/medias/${id}/feedbacks`)
        .then((resp) => {
          console.log(resp.data)
          dispatch({
            type: feedbackActions.GET_CURRENT_MEDIA_LIST_FEEDBACK,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  editAllMediasFeedbacks: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach(media => {
        dispatch(feedbackActions.updateMedia(media.mediaID, media))
      });
    };
  },
  updateMedia : (id, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/medias/id/${id}`, values)
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(feedbackActions.getMediaByFeedbackID(values.feedbackID))
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteMedia: (media) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/medias/id/${media.mediaID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(feedbackActions.getMediaByFeedbackID(media.feedbackID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  toggleViewFeedback: view => ({ type: feedbackActions.TOGGLE_VIEW_FEEDBACK, view })
}

export default feedbackActions