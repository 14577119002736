import clone from 'clone';
import actions from './actions';

const initState = {
  budgets: [],
  tableBudgets: false,
  initialBudgets: false,
  systemPrices: false,
  currentBudget: null,
  editableBudget: {},
  isNewBudget: false,
  enableEditView: false,
  budgetSuccess: false,
  budgetError: false,
  budgetMessage: "",
  isValidToken: false,
  renewing: false
};

export default function cardReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_BUDGETS:
      return {
        ...state,
        initialBudgets: true,
        budgets: action.payload.budgets,
      };

    case actions.GET_BUDGET_WITH_ALL_INFOS:
      return {
        ...state,
        currentBudget: action.budget
      }

    case actions.GET_TABLE_BUDGETS:
      return {
        ...state,
        initialBudgets: true,
        tableBudgets: action.payload.budgets,
      };

    case actions.UPDATE_BUDGET: {
      const currentBudget = action.budget
        ? action.budget
        : state.currentBudget;
      return {
        ...state,
        budgets: action.budgets,
        currentBudget: clone(currentBudget),
        initialBudgets: true,
        isNewBudget: false,
        enableEditView: false,
      };
    }

    case actions.GET_BUDGET: {
      return {
        ...state,
        currentBudget: action.budget
      }
    }

    case actions.SELECT_CURRENT_BUDGET: {
      const budgets = state.budgets;
      const index = budgets.map(budget => budget.id).indexOf(action.id);
      const isNewBudget = index === -1;
      const currentBudget = isNewBudget
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
          }
        : budgets[index];
      const enableEditView = isNewBudget;
      return {
        ...state,
        currentBudget,
        isNewBudget,
        enableEditView,
        editableBudget: clone(currentBudget),
      };
    }

    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableBudget: clone(state.currentBudget),
      };

    case actions.UPDATE_EDIT_BUDGET:
      return {
        ...state,
        editableBudget: clone(action.budget),
      };

    case actions.REPORT_ERROR:
      return {
        ...state,
        budgetError: true,
        budgetMessage: action.message
      }

    case actions.REPORT_SUCCESS:
      return {
        ...state,
        budgetSuccess: true,
        budgetMessage: action.message
      }

    case actions.CLEAR_STATUS:
      return {
        ...state,
        budgetSuccess: false,
        budgetError: false,
        budgetMessage: ""
      }

    case actions.GET_SYSTEM_PRICES:
      return {
        ...state,
        systemPrices: action.prices
      }

    case actions.TOKEN_BUDGET_VALIDATED:
      return {
        ...state,
        isValidToken: action.valid
      }

    case actions.TOGGLE_RENEWING:
      return {
        ...state,
        renewing: !state.renewing
      }

    case actions.UNLOAD_BUDGETS:
      return {
        ...state,
        initialBudgets: false
      }

    default:
      return state;
  }
}
