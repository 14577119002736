import actions from "./actions";

const initState = {};

export default function customerManagementReducer(state = initState, action) {
  switch (action.type) {
    case actions.NEW_REFERRAl_LEAD:
      return { ...state };
    default:
      return state;
  }
}
