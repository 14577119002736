import globals from '../../globals';
import axios from 'axios'

const popUpsActions = {
  GET_ALL_POP_UPS : 'GET_ALL_POP_UPS',
  LOADING_CURRENT_POP_UP: 'LOADING_CURRENT_POP_UP',
  GET_CURRENT_POP_UP: 'GET_CURRENT_POP_UP',
  NEW_POP_UP_RESET: 'NEW_POP_UP_RESET',
  NEW_POP_UP_LOADING: 'NEW_POP_UP_LOADING',
  NEW_POP_UP_SUCCESS: 'NEW_POP_UP_SUCCESS',
  NEW_POP_UP_ERROR: 'NEW_POP_UP_ERROR',
  EDIT_POP_UP_LOADING: 'EDIT_POP_UP_LOADING',
  EDIT_POP_UP_SUCCESS: 'EDIT_POP_UP_SUCCESS',
  EDIT_POP_UP_ERROR: 'EDIT_POP_UP_ERROR',


  getAllPopUps : () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/popUps/all`)
        .then((resp) => {
          dispatch({
            type: popUpsActions.GET_ALL_POP_UPS,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getCurrentPopUp : (popupID) => {
    return (dispatch) => {
      dispatch({type : popUpsActions.LOADING_CURRENT_POP_UP})
      axios.get(`${globals.API_URL}/popUps/id/${popupID}`)
        .then((resp) => {
          dispatch({
            type: popUpsActions.GET_CURRENT_POP_UP,
            payload : resp.data
          })
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  newPopUp: (values) => {
    return (dispatch) => {
      dispatch({type : popUpsActions.NEW_POP_UP_LOADING})

      axios.post(`${globals.API_URL}/popUps/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: popUpsActions.NEW_POP_UP_SUCCESS
            });
            dispatch(popUpsActions.getAllPopUps())
          }
        }).catch((error) => {
          dispatch({
            type: popUpsActions.NEW_POP_UP_ERROR,
            error: error,
          });
        })

    }
  },

  newPopUpReset: () => {
    return (dispatch) => {
      dispatch({
        type: popUpsActions.NEW_POP_UP_RESET
      });
    }
  },

  editPopUp: (popupID, values) => {
    return (dispatch) => {
      dispatch({
        type: popUpsActions.EDIT_POP_UP_LOADING
      });
      axios.put(`${globals.API_URL}/popUps/id/${popupID}`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: popUpsActions.EDIT_POP_UP_SUCCESS
            });
            dispatch(popUpsActions.getAllPopUps())
          }
        }).catch((error) => {
          dispatch({
            type: popUpsActions.EDIT_POP_UP_ERROR,
            error: error,
          });
        })
    };
  },

  deletePopUp: (popupID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/popUps/id/${popupID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(popUpsActions.getAllPopUps())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
}

export default popUpsActions