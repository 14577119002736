import globals from '../../globals';
import axios from 'axios'

const productActions = {
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  UPDATE_EDIT_PRODUCTS_LIST: 'UPDATE_EDIT_PRODUCTS_LIST',
  NEW_PRODUCT_RESET: 'NEW_PRODUCT_RESET',
  NEW_PRODUCT_LOADING: 'NEW_PRODUCT_LOADING',
  NEW_PRODUCT_SUCCESS: 'NEW_PRODUCT_SUCCESS',
  NEW_PRODUCT_ERROR: 'NEW_PRODUCT_ERROR',
  EDIT_PRODUCT_LOADING: 'EDIT_PRODUCT_LOADING',
  EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',
  EDIT_PRODUCT_ERROR: 'EDIT_PRODUCT_ERROR',
  GET_CURRENT_PRODUCT_LOADING: 'GET_CURRENT_PRODUCT_LOADING',
  GET_CURRENT_PRODUCT: 'GET_CURRENT_PRODUCT',
  GET_CURRENT_PRODUCT_LIST: 'GET_CURRENT_PRODUCT_LIST',
  TOGGLE_VIEW_INTERIOR_DESIGN_PRODUCT: 'TOGGLE_VIEW_INTERIOR_DESIGN_PRODUCT',
  UPDATE_EDIT_MEDIAS_LIST: 'UPDATE_EDIT_MEDIAS_LIST',
  GET_CURRENT_MEDIAS_LIST: 'GET_CURRENT_MEDIAS_LIST',
  UPDATE_EDIT_PRODUCTS_LIST: 'UPDATE_EDIT_PRODUCTS_LIST',
  GET_CURRENT_HOTSPOTS_AND_PRODUCTS_LIST: 'GET_CURRENT_HOTSPOTS_AND_PRODUCTS_LIST',
  GET_CURRENT_HOTSPOT: 'GET_CURRENT_HOTSPOT',
  UPDATE_EDIT_HOTSPOTS_LIST: 'UPDATE_EDIT_HOTSPOTS_LIST',
  // AÇÕES REFERENTE AOS PRODUTOS
  getAllProducts: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/interiorDesignProducts/all`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_ALL_PRODUCTS,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  getCurrentProduct: (interiorDesignProductID) => {
    return (dispatch) => {
      dispatch({ type: productActions.GET_CURRENT_PRODUCT_LOADING })
      axios.get(`${globals.API_URL}/interiorDesignProducts/id/${interiorDesignProductID}`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_CURRENT_PRODUCT,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newProductReset: () => {
    return (dispatch) => {
      dispatch({ type: productActions.NEW_PRODUCT_RESET })
    }
  },
  newProduct: (values) => {
    const newProduct = {
      name: values.name,
      description: values.description,
      color: values.color,
      dimensions: values.dimensions,
      interiorDesignProductID: values.type,
      soldBy: values.soldBy ? values.soldBy : null,
      comment: values.comment ? values.comment : null,
      price: values.price ? values.price : null,
      typeID: values.typeID

    };
    return (dispatch) => {
      dispatch({ type: productActions.NEW_PRODUCT_LOADING })

      axios.post(`${globals.API_URL}/interiorDesignProducts/`, newProduct)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({ type: productActions.NEW_PRODUCT_SUCCESS, payload: resp.data })
            dispatch(productActions.getAllProducts())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
          dispatch({ type: productActions.NEW_PRODUCT_ERROR })
        })
    };
  },
  updateEditAllProducts: (productsList) => {
    return (dispatch) => {
      dispatch({
        type: productActions.UPDATE_EDIT_PRODUCTS_LIST,
        payload: productsList,
      });
    };
  },
  editAllProducts: (productsList) => {
    return (dispatch) => {
      productsList.forEach(product => {
        dispatch(productActions.editProduct(product.interiorDesignProductID, product))
      });
      dispatch(productActions.getAllProducts())
    };
  },
  editProduct: (interiorDesignProductID, values) => {

    return (dispatch) => {
      dispatch({
        type: productActions.EDIT_PRODUCT_LOADING
      });
      axios.put(`${globals.API_URL}/interiorDesignProducts/id/${interiorDesignProductID}`, values)
        .then((resp) => {
          dispatch({
            type: productActions.EDIT_PRODUCT_SUCCESS
          });
          dispatch(productActions.toggleViewInteriorDesignProduct(false))
          dispatch(productActions.getCurrentProduct(interiorDesignProductID))
          console.log("produto atualizada com sucesso: ");
        }).catch((error) => {
          dispatch({
            type: productActions.EDIT_PRODUCT_ERROR
          });
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteProduct: (interiorDesignProductID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/interiorDesignProducts/id/${interiorDesignProductID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productActions.getAllProducts())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },


  updateEditAllMediasIndex: (mediaID, oldIndex, newIndex, mediasList) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex
      }
    }
    return (dispatch) => {
      dispatch({
        type: productActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: productActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },

  getCurrentMediasListInteriorDesignProduct: (interiorDesignProductID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/medias/all/${interiorDesignProductID}/interiorDesignProducts`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newMediaInteriorDesignProduct: (interiorDesignProductID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/interiorDesignProducts/ready/${interiorDesignProductID}/images/project_${interiorDesignProductID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      interiorDesignProductID: interiorDesignProductID
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 200) {

            dispatch(productActions.getCurrentMediasListInteriorDesignProduct(interiorDesignProductID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  editAllMediasInteriorDesignProduct: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach(media => {
        dispatch(productActions.editMediaInteriorDesignProduct(media.mediaID, media))
      });
    };
  },
  editMediaInteriorDesignProduct: (mediaID, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/medias/id/${mediaID}/interiorDesignProducts`, values)
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(productActions.getCurrentMediasListInteriorDesignProduct(values.interiorDesignProductID))
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteMediaInteriorDesignProduct: (media) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/medias/id/${media.mediaID}/interiorDesignProducts`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productActions.getCurrentMediasListInteriorDesignProduct(media.interiorDesignProductID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },


  //AÇOẼS REFERENTES AOS PRODUTOS DE DESIGN DE INTERIORES
  getCurrentProductList: (typeID, designID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/interiorDesignProductTypes/all/${typeID}/interiorDesignProjectID/${designID}`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_CURRENT_PRODUCT_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newProductInteriorDesignProject: (optionsProductsList, currentProductsList, designID) => {
    var position, product
    for (let i = 0; i < optionsProductsList.length; i++) {
      position = currentProductsList.map(function (product) {
        return product.interiorDesignProductID
      }).indexOf(optionsProductsList[i].interiorDesignProductID)
      if (position < 0) {
        product = optionsProductsList[i]
        break
      }
    }
    if (product) {
      return (dispatch) => {
        axios.post(`${globals.API_URL}/interiorDesignProjects/interiorDesignProductsProjectRelation`, {
          InteriorDesignProductInteriorDesignProductID: product.interiorDesignProductID,
          InteriorDesignDesignID: designID
        })
          .then((resp) => {
            if (resp.status == 200) {
              dispatch(productActions.getCurrentProductList(product.typeID, designID))
            }
          }).catch((error) => {
            console.log("Houve um erro interno. " + error);
          })
      };
    } else {
      return (dispatch) => {
        console.log("Não há mais tipos de características para esse tipo.");
      };
    }
  },
  deleteProductsInteriorDesignProject: (interiorDesignProduct, designID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/interiorDesignProducts/id/${interiorDesignProduct.interiorDesignProductID}/interiorDesignProjects/${designID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productActions.getCurrentProductList(interiorDesignProduct.typeID, designID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  editAllProductsInteriorDesignProject: (productsList, designID) => {
    return (dispatch) => {
      axios.post(`${globals.API_URL}/interiorDesignProjects/renewInteriorDesignProductsProjectRelation`, {
        productsList: productsList,
        designID: designID
      })
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productActions.getCurrentProductList(productsList[0].typeID, designID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllProducts: (position, newProduct, oldProduct, productsList) => {
    for (let i = 0; i < productsList.length; i++) {
      if (i != position && productsList[i].interiorDesignProductID == newProduct.interiorDesignProductID) {
        productsList[i].interiorDesignProductID = oldProduct.interiorDesignProductID
        productsList[i].name = oldProduct.name
      }
    }
    return (dispatch) => {
      dispatch({
        type: productActions.UPDATE_EDIT_PRODUCTS_LIST,
        payload: productsList,
      });
    };
  },
  getOptionsInteriorDesignProductsByTypeID: (typeID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/interiorDesignProductTypes/id/${typeID}/products`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_ALL_PRODUCTS,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  //AÇÕES REFERENTES AOS HOTSPOTS
  getCurrentDesignProductAndHotspotList: (designID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/interiorDesignProjects/id/${designID}/hotspots`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_CURRENT_HOTSPOTS_AND_PRODUCTS_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  getCurrentHotspotByDesingIDandProductID: (productID, designID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/interiorDesignProjects/id/${designID}/product/${productID}/hotspots`)
        .then((resp) => {
          dispatch({
            type: productActions.GET_CURRENT_HOTSPOT,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newHotspot: (mediaID, index, associationID, productID, designID) => {
    const newHotspot = {
      index: index,
      pitch: 0,
      yaw: 0,
      type: 'point',
      mediaID: mediaID
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/hotspots/products`, {
        hotspot: newHotspot,
        associationID: associationID
      })
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productActions.getCurrentHotspotByDesingIDandProductID(productID, designID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteHotspot: (hotspot, associationID, productID, designID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/hotspots/id/products/${hotspot.hotspotID}/${associationID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(productActions.getCurrentHotspotByDesingIDandProductID(productID, designID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllHotspots: (hotspotsList) => {
    return (dispatch) => {
      dispatch({
        type: productActions.UPDATE_EDIT_HOTSPOTS_LIST,
        payload: hotspotsList,
      });
    };
  },
  editAllHotspots: (hotspotsList, productID, designID) => {
    return (dispatch) => {
      hotspotsList.forEach(hotspot => {
        dispatch(productActions.editHotspot(hotspot.hotspotID, hotspot, productID, designID))
      });
    };
  },
  editHotspot: (hotspotID, values, productID, designID) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/hotspots/id/products/${hotspotID}`, values)
        .then((resp) => {
          console.log(resp)
          console.log("Hotspot atualizado com sucesso: " + values.text);
          dispatch(productActions.getCurrentHotspotByDesingIDandProductID(productID, designID))
        }).catch((error) => {
          console.log(error)
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  toggleViewInteriorDesignProduct: view => ({ type: productActions.TOGGLE_VIEW_INTERIOR_DESIGN_PRODUCT, view })
}

export default productActions;