import actions from './actions';

const initState = {
  renderEditPromotionLoading: false,
  allPromotions: [],
  currentPromotion: {},
  backendError_newPromotion: false,
  backendSuccess_newPromotion: false,
  backendError_editPromotion: false,
  backendSuccess_editPromotion: false
};

export default function promotionReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_PROMOTIONS:
      return {
        ...state,
        backendError_newPromotion: false,
        backendSuccess_newPromotion: false,
        backendError_editPromotion: false,
        backendSuccess_editPromotion: false,
        allPromotions: action.payload.promotions,
      };
    case actions.LOADING_CURRENT_PROMOTION:
      return {
        ...state,
        renderEditPromotionLoading: true
      };
    case actions.GET_CURRENT_PROMOTION:
      return {
        ...state,
        renderEditPromotionLoading: false,
        currentPromotion: action.payload.promotion,
      };
    case actions.NEW_PROMOTION_RESET:
      return {
        ...state,
        backendSuccess_newPromotion: false,
        backendError_newPromotion: false
      };
    case actions.NEW_PROMOTION_LOADING:
      return {
        ...state,
        backendSuccess_newPromotion: false,
        backendError_newPromotion: false
      };
    case actions.NEW_PROMOTION_SUCCESS:
      return {
        ...state,
        backendSuccess_newPromotion: true,
        backendError_newPromotion: false,
      };
    case actions.NEW_PROMOTION_ERROR:
      return {
        ...state,
        backendSuccess_newPromotion: false,
        backendError_newPromotion: true
      };
    case actions.EDIT_PROMOTION_LOADING:
      return {
        ...state,
        backendSuccess_editPromotion: false,
        backendError_editPromotion: false
      };
    case actions.EDIT_PROMOTION_SUCCESS:
      return {
        ...state,
        backendSuccess_editPromotion: true,
        backendError_editPromotion: false
      };
    case actions.EDIT_PROMOTION_ERROR:
      return {
        ...state,
        backendSuccess_editPromotion: false,
        backendError_editPromotion: true
      };
    default:
      return state;
  }
}
