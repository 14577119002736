import storeActions from './actions'

const initialState = {
  allStores : [],
  currentStore : {},
  currentCouponsList : [],
  backendSuccess_newCouponStore: false,
  backendError_newCouponStore: false
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case storeActions.GET_ALL_STORES:
      return {
        ...state,
        allStores : action.payload.stores
      };
    case storeActions.GET_CURRENT_STORE:
      return {
        ...state,
        currentStore : action.payload.store
      };
    case storeActions.UPDATE_EDIT_STORES_LIST:
      return {
        ...state,
        allStores : action.payload
      };
    case storeActions.GET_CURRENT_COUPONS_STORE_LIST:
      return {
        ...state,
        currentCouponsList : action.payload.coupons
      };
    case storeActions.GET_CURRENT_COUPONS_STORE_LIST_LOADING:
      return {
        ...state,
        currentCouponsList : [],
        backendSuccess_newCouponStore: false,
        backendError_newCouponStore: false

      };
    default:
      return state;
  }
}