import globals from '../../globals';
import axios from 'axios'

const storeActions = {
  GET_ALL_STORES: 'GET_ALL_STORES',
  GET_CURRENT_STORE: 'GET_CURRENT_STORE',
  UPDATE_EDIT_STORES_LIST : 'UPDATE_EDIT_STORES_LIST',
  GET_CURRENT_COUPONS_STORE_LIST : 'GET_CURRENT_COUPONS_STORE_LIST',
  GET_CURRENT_COUPONS_STORE_LIST_LOADING : 'GET_CURRENT_COUPONS_STORE_LIST_LOADING',
  getAllStores: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/stores/all`)
        .then((resp) => {
          dispatch({
            type: storeActions.GET_ALL_STORES,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  getCurrentStore: (storeID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/stores/id;${storeID}`)
        .then((resp) => {
          dispatch({
            type: storeActions.GET_CURRENT_STORE,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newStore: () => {
    const newStore = {
      name: "Sem Nome",
      storeURL:'Loja sem Link',
      description:'Loja Sem Descriçãp'
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/stores/`, newStore)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(storeActions.getAllStores())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllStores : (storeList) => {
    return (dispatch) => {
      dispatch({
        type: storeActions.UPDATE_EDIT_STORES_LIST,
        payload: storeList,
      });
    };
  },
  editAllStores : (storeList) => {
    return (dispatch) => {
      storeList.forEach(store => {
        dispatch(storeActions.editStore(store.storeID, store))
      });
      dispatch(storeActions.getAllStores())
    };
  },
  editStore : (storeID, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/stores/id/${storeID}`, values)
        .then((resp) => {
          console.log("Loja atualizada com sucesso: " + values.name);
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteStore : (store) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/stores/id/${store.storeID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(storeActions.getAllStores())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },



  //Ações referentes aos cupons das lojas
  getCurrentCouponsList: (storeID) => {
    return (dispatch) => {
      dispatch({type : storeActions.GET_CURRENT_COUPONS_STORE_LIST_LOADING})
      axios.get(`${globals.API_URL}/stores/all/${storeID}/coupons`)
        .then((resp) => {
          dispatch({
            type: storeActions.GET_CURRENT_COUPONS_STORE_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
};

export default storeActions;
