import actions from './actions';



const initState = {
  allArchitecturalAdditionals: []
};



export default function architecturalAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_ARCHITECTURAL_ADDITIONALS:
            return {
                ...state,
                allArchitecturalAdditionals: action.payload.architecturalAdditionals,
            };
        default:
            return state;
    }
}