import collaboratorActions from './actions';

const initState = {
  currentAllCollaborators: [],
  allTeams: [],
  modulesList: [],
  optionsModulesList: [],
  selectedEmail: '',
  editView: false,
  selectedCollaborator: null,
  allRoulettesCollaborator : [],
  allRoulettesCountCollaborator : []
};

export default function collaboratorReducer(state = initState, action) {
  switch (action.type) {
    case collaboratorActions.GET_ALL_CURRENT_COLLABORATORS:
      return {
        ...state,
        currentAllCollaborators: action.payload.collaborators,
      };
    case collaboratorActions.GET_OPTIONS_MODULES:
      return {
        ...state,
        optionsModulesList: action.payload.modules,
      };
    case collaboratorActions.GET_CURRENT_MODULES_LIST:
      return {
        ...state,
        modulesList: action.payload.modules,
      };
    case collaboratorActions.GET_ALL_TEAMS:
      return {
        ...state,
        allTeams: action.payload.teams,
      };
    case collaboratorActions.UPDATE_EDIT_TEAMS_LIST:
      return {
        ...state,
        allTeams: action.payload,
      };
    case collaboratorActions.CHANGE_COLLABORATOR:
      return {
        ...state,
        selectedEmail: action.email,
        editView: false,
      };
    case collaboratorActions.ADD_COLLABORATOR:
      return {
        ...state,
        selectedEmail: action.selectedEmail,
        editView: true,
      };
    case collaboratorActions.EDIT_COLLABORATOR:
      return {
        ...state,
        collaborators: action.collaborators,
      };
    case collaboratorActions.DELETE__COLLABORATOR:
      return {
        ...state,
        selectedEmail: '',
      };
    case collaboratorActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
      };
    case collaboratorActions.SELECT_COLLABORATOR:
      return {
        ...state,
        selectedCollaborator: action.collaborator
      }
    case collaboratorActions.UPDATE_ALL_ROULETTE_COLLABORATOR:
      return {
        ...state,
        allRoulettesCollaborator: action.collaborator
      }
    case collaboratorActions.RESET_ROULETTES:
      return {
        ...state,
        allRoulettesCountCollaborator: action.collaborators
      }
    default:
      return state;
  }
}
