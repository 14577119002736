import actions from './actions';

const initState = {
  currentMediasList: [],
};

export default function projectReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CURRENT_MEDIAS_LIST:
      return {
        ...state,
        currentMediasList: action.payload.medias,
      };
    case actions.UPDATE_EDIT_MEDIAS_LIST:
      return {
        ...state,
        currentMediasList: action.payload,
      };
    default:
      return state;
  }
}
