import actions from './actions';

const initState = {
    allAdditionals: []
};

export default function additionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_ADDITIONALS:
            return {
                ...state,
                allAdditionals: action.payload.allAdditionals,
            };
        default:
            return state;
    }
}