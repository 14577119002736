import axios from 'axios';
import globals from '../../globals';


const actions = {
  GET_BUDGET: 'GET_BUDGET',
  GET_ALL_BUDGETS: 'GET_ALL_BUDGETS',
  GET_BUDGET_WITH_ALL_INFOS: 'GET_BUDGET_WITH_ALL_INFOS',
  GET_TABLE_BUDGETS: 'GET_TABLE_BUDGETS',
  UPDATE_BUDGET: 'UPDATE_BUDGET',
  CREATE_BUDGET: 'CREATE_BUDGET',
  SELECT_CURRENT_BUDGET: 'SELECT_CURRENT_BUDGET',
  TOGGLE_VIEW: 'BUDGET_TOGGLE_VIEW',
  UPDATE_EDIT_BUDGET: 'BUDGET_UPDATE_EDIT_BUDGET',
  REPORT_ERROR: 'REPORT_ERROR',
  REPORT_SUCCESS: 'REPORT_SUCCESS',
  CLEAR_STATUS: 'CLEAR_STATUS',
  GET_SYSTEM_PRICES: 'GET_SYSTEM_PRICES',
  TOKEN_BUDGET_VALIDATED: 'TOKEN_BUDGET_VALIDATED',
  TOGGLE_RENEWING: 'TOGGLE_RENEWING',
  UNLOAD_BUDGETS: 'UNLOAD_BUDGETS',

  getAllBudgets: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/oldBudgets/all`)
        .then((resp) => {
          dispatch({
            type: actions.GET_ALL_BUDGETS,
            payload: {
              budgets: resp.data.budgets
            }
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getAllTableBudgets: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/oldBudgets/all/table`)
        .then((resp) => {
          dispatch({
            type: actions.GET_TABLE_BUDGETS,
            payload: {
              budgets: resp.data.budgets
            }
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getMyTableBudgets: (collaboratorEmail) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/oldBudgets/all/${collaboratorEmail}/table`)
        .then((resp) => {
          dispatch({
            type: actions.GET_TABLE_BUDGETS,
            payload: {
              budgets: resp.data.budgets
            }
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getBudget: (budgetID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/oldBudgets/id/${budgetID}`)
        .then((resp) => {
          dispatch({
            type: actions.GET_BUDGET,
            budget: resp.data.budget
          })
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  getBudgetWithAllInfos: (budgetID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/oldBudgets/id/${budgetID}/infos`)
        .then((resp) => {
          dispatch({
            type: actions.GET_BUDGET_WITH_ALL_INFOS,
            budget: resp.data.budget
          })
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  createBudget: (values) => {
    return (dispatch => {
      axios.post(`${globals.API_URL}/oldBudgets`, values)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento criado com sucesso!"));
            dispatch(actions.getTableBudgets());
          } else {
            dispatch(actions.reportError("Houve um erro ao criar o orçamento!"));
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao criar o orçamento!"));
        })
    })
  },

  deleteBudget: (budgetID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/oldBudgets/id/${budgetID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(actions.getTableBudgets());
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  updateBudget: (budgetID, newData) => {
    return dispatch => {
      axios.put(`${globals.API_URL}/oldBudgets/id/${budgetID}`, newData)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento atualizado com sucesso!"));
            dispatch(actions.getBudgetWithAllInfos(budgetID))
          } else {
            dispatch(actions.reportError("Houve um erro ao atualizar o orçamento!"));
          }
        }).catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o orçamento!"));
        })
    }
  },

  selectCurrentBudget: id => ({ type: actions.SELECT_CURRENT_BUDGET, id }),

  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),

  editBudget: budget => ({ type: actions.UPDATE_EDIT_BUDGET, budget }),

  reportError: (message) => {
    return (dispatch) => {
      dispatch({ 
        type: actions.REPORT_ERROR,
        message: message
      })
    }
  },

  reportSuccess: (message) => {
    return (dispatch) => {
      dispatch({ 
        type: actions.REPORT_SUCCESS,
        message: message
      })
    }
  },

  clearBudgetStatus: () => ({ type: actions.CLEAR_STATUS }),

  getSystemPrices: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/oldBudgets/prices`)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch({
              type: actions.GET_SYSTEM_PRICES,
              prices: resp.data.prices
            })
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  validateBudgetToken: (token) => {
    return dispatch => {
      axios.post(`${globals.API_URL}/oldBudgets/validateBudgetToken/`, { token: token }, { cancelToken: globals.SOURCE_CANCEL.token }, { cache: true })
          .then((_) => {
              dispatch({ type: 'TOKEN_BUDGET_VALIDATED', valid: true })
          }).catch((error) => {
              console.log("Houve um erro interno. FUNÇÃO: validateBudgetToken. " + error);
              dispatch({ type: 'TOKEN_BUDGET_VALIDATED', valid: false })
          })
    }
  },

  renewToken: (budgetID, expirationDays) => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_RENEWING });
      axios.post(`${globals.API_URL}/oldBudgets/id/${budgetID}/renew`, { expirationDays: expirationDays })
        .then((_) => {
          dispatch(actions.getBudgetWithAllInfos(budgetID));
          dispatch(actions.reportSuccess("Proposta renovada com sucesso!"));
          dispatch({ type: actions.TOGGLE_RENEWING });
        })
        .catch((error) => {
          console.log("Houve um erro interno. FUNÇÃO: renewToken. " + error);
          dispatch(actions.reportError("Houve um erro ao renovar a proposta!"));
          dispatch({ type: actions.TOGGLE_RENEWING });
        })
    }
  },

  unloadBudgets: () => ({ type: actions.UNLOAD_BUDGETS })
};
export default actions;
