import globals from '../../globals';
import axios from 'axios';


const hydrosanitaryAdditionalActions = {
    GET_ALL_HYDROSANITARY_ADDITIONALS: 'GET_ALL_HYDROSANITARY_ADDITIONALS',

    getAllHydrosanitaryAdditionals: () => {
        return (dispatch) => {
            axios.get(`${globals.API_URL}/hydrosanitaryAdditionals/all`)
                .then((resp) => {
                    dispatch({
                        type: hydrosanitaryAdditionalActions.GET_ALL_HYDROSANITARY_ADDITIONALS,
                        payload: {
                            hydrosanitaryAdditionals: resp.data.hydrosanitaryAdditionals
                        }
                    });
                }).catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        };
    }
};



export default hydrosanitaryAdditionalActions;