
import globals from '../../globals';
import axios from 'axios'

const promotionsActions = {
  GET_ALL_PROMOTIONS: 'GET_ALL_PROMOTIONS',
  LOADING_CURRENT_PROMOTION: 'LOADING_CURRENT_PROMOTION',
  GET_CURRENT_PROMOTION: 'GET_CURRENT_PROMOTION',
  NEW_PROMOTION_RESET: 'NEW_PROMOTION_RESET',
  NEW_PROMOTION_LOADING: 'NEW_PROMOTION_LOADING',
  NEW_PROMOTION_SUCCESS: 'NEW_PROMOTION_SUCCESS',
  NEW_PROMOTION_ERROR: 'NEW_PROMOTION_ERROR',
  EDIT_PROMOTION_LOADING: 'EDIT_PROMOTION_LOADING',
  EDIT_PROMOTION_SUCCESS: 'EDIT_PROMOTION_SUCCESS',
  EDIT_PROMOTION_ERROR: 'EDIT_PROMOTION_ERROR',

  // AÇÕES REFERENTE ÀS PROMOÇÕES
  newPromotionReset: () => {
    return (dispatch) => {
      dispatch({
        type: promotionsActions.NEW_PROMOTION_RESET
      });
    }
  },
  newPromotion: (values) => {
    return (dispatch) => {
      dispatch({
        type: promotionsActions.NEW_PROMOTION_LOADING
      });
      axios.post(`${globals.API_URL}/promotions/`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: promotionsActions.NEW_PROMOTION_SUCCESS
            });
            dispatch(promotionsActions.getAllPromotions())
          }
        }).catch((error) => {
          dispatch({
            type: promotionsActions.NEW_PROMOTION_ERROR,
            error: error,
          });
        })
    };
  },
  editPromotion: (promotionID, values) => {
    return (dispatch) => {
      dispatch({
        type: promotionsActions.EDIT_PROMOTION_LOADING
      });
      axios.put(`${globals.API_URL}/promotions/id/${promotionID}`, values)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch({
              type: promotionsActions.EDIT_PROMOTION_SUCCESS
            });
            dispatch(promotionsActions.getAllPromotions())
          }
        }).catch((error) => {
          dispatch({
            type: promotionsActions.EDIT_PROMOTION_ERROR,
            error: error,
          });
        })
    };
  },
  getAllPromotions: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/promotions/all`)
        .then((resp) => {
          dispatch({
            type: promotionsActions.GET_ALL_PROMOTIONS,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  getCurrentPromotion: (promotionID) => {
    return (dispatch) => {
      dispatch({ type: promotionsActions.LOADING_CURRENT_PROMOTION });
      axios.get(`${globals.API_URL}/promotions/id/${promotionID}`)
        .then((resp) => {
          dispatch({
            type: promotionsActions.GET_CURRENT_PROMOTION,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deletePromotion: (promotionID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/promotions/id/${promotionID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(promotionsActions.getAllPromotions())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
};
export default promotionsActions;
