import axios from 'axios';
import globals from '../../globals';

const defaultFilter = {
  limit: 50,
  skip: 0
}

const actions = {
    GET_ALL_PERSONS: 'GET_ALL_PERSONS',
    GET_PERSON: 'GET_PERSON',
    CLEAR_SELECTED_PERSON: 'CLEAR_SELECTED_PERSON',
    CREATE_PERSON: 'CREATE_PERSON',
    REPORT_ERROR: 'REPORT_ERROR',
    REPORT_SUCCESS: 'REPORT_SUCCESS',
    CLEAR_STATUS: 'CLEAR_STATUS',

    getAllPersons: (filters = defaultFilter) => {
      const queryString = new URLSearchParams(filters).toString()
        return (dispatch) => {
            axios.get(`${globals.API_URL}/persons/all?${queryString}`).then((result) => {
                dispatch({
                    type: actions.GET_ALL_PERSONS,
                    persons: result.data.persons
                })
            });
        }
    },

    getPerson: (email) => {
        return dispatch => {
            axios.get(`${globals.API_URL}/persons/email/${email}`)
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch({
                            type: actions.GET_PERSON,
                            person: resp.data.persons[0]
                        })
                    } else {
                        console.log("Houve um erro interno.");
                    }
                })
                .catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        }
    },

    getPersonAddress: (email) => {
        return dispatch => {
            axios.get(`${globals.API_URL}/persons/email/${email}/address`)
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch({
                            type: actions.GET_PERSON,
                            person: resp.data.person
                        })
                    } else {
                        console.log("Houve um erro interno.");
                    }
                })
                .catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        }
    },

    createPerson: (values) => {
        return (dispatch) => {
            axios.post(`${globals.API_URL}/persons`, values)
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch(actions.getAllPersons());
                        dispatch(actions.reportSuccess("Cliente cadastrado com sucesso!"));
                    } else {
                        dispatch(actions.reportError("Erro ao cadastrar cliente!"));
                    }
                }).catch((error) => {
                    console.log("Houve um erro interno. " + error);
                    dispatch(actions.reportError("Erro ao cadastrar cliente!"));
                })
        }
    },

    reportError: (message) => {
        return (dispatch) => {
          dispatch({
            type: actions.REPORT_ERROR,
            message: message
          })
        }
    },

    reportSuccess: (message) => {
        return (dispatch) => {
            dispatch({
            type: actions.REPORT_SUCCESS,
            message: message
            })
        }
    },

    clearPersonStatus: () => ({ type: actions.CLEAR_STATUS }),
    clearSelectedPerson: () => ({ type: actions.CLEAR_SELECTED_PERSON }),

    updatePerson: (personEmail, newData) => {
        return dispatch => {
            axios.put(`${globals.API_URL}/persons/email/${personEmail}`, newData)
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch(actions.getAllPersons());
                        dispatch(actions.getPerson(personEmail));
                        dispatch(actions.reportSuccess("Cliente atualizado com sucesso!"));
                    } else {
                        dispatch(actions.reportError("Erro ao atualizar cliente!"));
                    }
                })
                .catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        }
    },
    updatePersonAdress: (personEmail, newData) => {
        return dispatch => {
            axios.put(`${globals.API_URL}/persons/email/${personEmail}`, newData)
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch(actions.getAllPersons());
                        dispatch(actions.getPersonAddress(personEmail));
                        dispatch(actions.reportSuccess("Cliente atualizado com sucesso!"));
                    } else {
                        dispatch(actions.reportError("Erro ao atualizar cliente!"));
                    }
                })
                .catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        }
    },

    clearPerson: () => ({ type: actions.GET_PERSON, person: null })
}


export default actions;
