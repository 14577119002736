import characteristicActions from './actions';

const initState = {
  currentCharacteristicsList: [],
  allCharacteristicsType: []
};

export default function characteristicReducer(state = initState, action) {
  switch (action.type) {
    case characteristicActions.GET_ALL_CHARACTERISTICS_TYPE:
      return {
        ...state,
        allCharacteristicsType: action.payload.characteristicTypes,
      };
    case characteristicActions.UPDATE_EDIT_CHARACTERISTIC_TYPES_LIST:
      return {
        ...state,
        allCharacteristicsType: action.payload,
      };
    case characteristicActions.GET_CURRENT_CHARACTERISTICS_LIST:
      return {
        ...state,
        currentCharacteristicsList: action.payload.characteristics,
      };
    case characteristicActions.UPDATE_EDIT_CHARACTERISTICS_LIST:
      return {
        ...state,
        currentCharacteristicsList: action.payload,
      };
    default:
      return state;
  }
}
