import axios from 'axios';
import globals from '../../globals';


const defaultFilter = {
  limit: 50,
  skip: 0
}

const actions = {
  GET_BUDGET: 'GET_BUDGET',
  GET_ALL_BUDGETS: 'GET_ALL_BUDGETS',
  GET_BUDGET_WITH_ALL_INFOS: 'GET_BUDGET_WITH_ALL_INFOS',
  GET_TABLE_BUDGETS: 'GET_TABLE_BUDGETS',
  UPDATE_BUDGET: 'UPDATE_BUDGET',
  CREATE_BUDGET: 'CREATE_BUDGET',
  SELECT_CURRENT_BUDGET: 'SELECT_CURRENT_BUDGET',
  TOGGLE_VIEW: 'BUDGET_TOGGLE_VIEW',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  UPDATE_EDIT_BUDGET: 'BUDGET_UPDATE_EDIT_BUDGET',
  REPORT_ERROR: 'REPORT_ERROR',
  REPORT_SUCCESS: 'REPORT_SUCCESS',
  CLEAR_STATUS: 'CLEAR_STATUS',
  GET_SYSTEM_PRICES: 'GET_SYSTEM_PRICES',
  TOKEN_BUDGET_VALIDATED: 'TOKEN_BUDGET_VALIDATED',
  TOGGLE_RENEWING: 'TOGGLE_RENEWING',
  TOGGLE_CANCELING: 'TOGGLE_CANCELING',
  TOGGLE_REMOVING_PAYMENT_CONFIG: 'TOGGLE_REMOVING_PAYMENT_CONFIG',
  UNLOAD_BUDGETS: 'UNLOAD_BUDGETS',

  getAllBudgets: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/budgets/all`)
        .then((resp) => {
          dispatch({
            type: actions.GET_ALL_BUDGETS,
            payload: {
              budgets: resp.data.budgets
            }
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getTableBudgets: (filters = defaultFilter) => {
    const queryString = new URLSearchParams(filters).toString()
    return (dispatch) => {
      axios.get(`${globals.API_URL}/budgets/all/table?${queryString}`)
        .then((resp) => {
          dispatch({
            type: actions.GET_TABLE_BUDGETS,
            payload: {
              budgets: resp.data.budgets
            }
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  getBudget: (budgetID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/budgets/id/${budgetID}`)
        .then((resp) => {
          dispatch({
            type: actions.GET_BUDGET,
            budget: resp.data.budget
          })
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  getBudgetWithAllInfos: (budgetID) => {
    return (dispatch) => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.get(`${globals.API_URL}/budgets/id/${budgetID}/infos`)
        .then((resp) => {
          dispatch({
            type: actions.GET_BUDGET_WITH_ALL_INFOS,
            budget: resp.data.budget
          })
          dispatch({ type: actions.TOGGLE_LOADING });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  saveBudgetOnCreation: (newBudget) => {
    return (dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.post(`${globals.API_URL}/budgets/saveOnCreation`, newBudget)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento salvo com sucesso!"));
            dispatch({ type: actions.TOGGLE_LOADING });
            console.log("[saveBudgetOnCreation]: Orçamento salvo com sucesso!");
          }
        })
        .catch((error) => {
          dispatch({ type: actions.TOGGLE_LOADING });
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao criar o orçamento!"));
        })
    });
  },

  finishBudgetOnCreation: (newBudget) => {
    return (dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });

      axios.post(`${globals.API_URL}/budgets/finishOnCreation`, newBudget)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento finalizado com sucesso!"));
            dispatch({ type: actions.TOGGLE_LOADING });
            console.log("[finishBudgetOnCreation]: Orçamento finalizado com sucesso!");
          }
        })
        .catch((error) => {
          dispatch({ type: actions.TOGGLE_LOADING });
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao criar o orçamento!"));
        })
    });
  },

  saveBudgetOnEdition: (budgetID, newBudget) => {
    return (dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.put(`${globals.API_URL}/budgets/id/${budgetID}/saveOnEdition`, newBudget)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento salvo com sucesso!"));
            dispatch({ type: actions.TOGGLE_LOADING });
            console.log("[saveBudgetOnEdition]: Orçamento salvo com sucesso!");
          }
        })
        .catch((error) => {
          dispatch({ type: actions.TOGGLE_LOADING });
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o orçamento!"));
        })
    });
  },

  finishBudgetOnEdition: (budgetID, newBudget) => {
    return (dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.put(`${globals.API_URL}/budgets/id/${budgetID}/finishOnEdition`, newBudget)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento finalizado com sucesso!"));
            dispatch({ type: actions.TOGGLE_LOADING });
            console.log("[finishBudgetOnEdition]: Orçamento finalizado com sucesso!");
          }
        })
        .catch((error) => {
          dispatch({ type: actions.TOGGLE_LOADING });
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o orçamento!"));
        })
    });
  },

  configPayment: (budgetID, newConfiguration) => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_LOADING });
      axios.put(`${globals.API_URL}/budgets/id/${budgetID}/configPayment`, newConfiguration)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Pagamento configurado com sucesso!"));
            dispatch({ type: actions.TOGGLE_LOADING });
            dispatch(actions.getBudgetWithAllInfos(budgetID));
            console.log("[configPayment]: Pagamento configurado com sucesso!");
          }
        })
        .catch((error) => {
          dispatch({ type: actions.TOGGLE_LOADING });
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao configurar o pagamento!"));
        })
    }
  },

  createBudget: (values) => {
    return (dispatch => {
      axios.post(`${globals.API_URL}/budgets`, values)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento criado com sucesso!"));
            dispatch(actions.getTableBudgets());
          } else {
            dispatch(actions.reportError("Houve um erro ao criar o orçamento!"));
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao criar o orçamento!"));
        })
    })
  },

  deleteBudget: (budgetID) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/budgets/id/${budgetID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(actions.getTableBudgets());
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },

  updateBudget: (budgetID, newData) => {
    return dispatch => {
      axios.put(`${globals.API_URL}/budgets/id/${budgetID}`, newData)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(actions.reportSuccess("Orçamento atualizado com sucesso!"));
            dispatch(actions.getBudgetWithAllInfos(budgetID))
          } else {
            dispatch(actions.reportError("Houve um erro ao atualizar o orçamento!"));
          }
        }).catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o orçamento!"));
        })
    }
  },

  selectCurrentBudget: id => ({ type: actions.SELECT_CURRENT_BUDGET, id }),

  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),

  editBudget: budget => ({ type: actions.UPDATE_EDIT_BUDGET, budget }),

  reportError: (message) => {
    return (dispatch) => {
      dispatch({
        type: actions.REPORT_ERROR,
        message: message
      })
    }
  },

  reportSuccess: (message) => {
    return (dispatch) => {
      dispatch({
        type: actions.REPORT_SUCCESS,
        message: message
      })
    }
  },

  clearBudgetStatus: () => ({ type: actions.CLEAR_STATUS }),

  getSystemPrices: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/budgets/prices`)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch({
              type: actions.GET_SYSTEM_PRICES,
              prices: resp.data.prices
            })
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    }
  },

  validateBudgetToken: (token) => {
    return dispatch => {
      axios.post(`${globals.API_URL}/budgets/validateBudgetToken/`, { token: token }, { cancelToken: globals.SOURCE_CANCEL.token }, { cache: true })
          .then((resp) => {
              dispatch({ type: 'TOKEN_BUDGET_VALIDATED', valid: resp.data.valid })
          }).catch((error) => {
              console.log("Houve um erro interno. FUNÇÃO: validateBudgetToken. " + error);
              dispatch({ type: 'TOKEN_BUDGET_VALIDATED', valid: false })
          })
    }
  },

  validateProposalID: (id) => {
    return dispatch => {
      axios.post(`${globals.API_URL}/budgets/validateProposalID/`, { id: id }, { cancelToken: globals.SOURCE_CANCEL.token }, { cache: true })
          .then((resp) => {
              dispatch({ type: 'TOKEN_BUDGET_VALIDATED', valid: resp.data.valid })
          }).catch((error) => {
              console.log("Houve um erro interno. FUNÇÃO: validateProposalID. " + error);
              dispatch({ type: 'TOKEN_BUDGET_VALIDATED', valid: false })
          })
    }
  },

  cancelBudget: (budgetID) => {
    return dispatch => {
        dispatch({ type: actions.TOGGLE_CANCELING });
        axios.get(`${globals.API_URL}/budgets/id/${budgetID}/cancel`)
          .then((_) => {
            dispatch(actions.getBudgetWithAllInfos(budgetID));
            dispatch(actions.reportSuccess("Proposta cancelada com sucesso!"));
            dispatch({ type: actions.TOGGLE_CANCELING });
          })
          .catch((error) => {
            console.log("Houve um erro interno. FUNÇÃO: cancelBudget. " + error);
            dispatch(actions.reportError("Houve um erro ao cancelar a proposta!"));
            dispatch({ type: actions.TOGGLE_CANCELING });
          })
    }
  },

  removePaymentConfig: (budgetID) => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_REMOVING_PAYMENT_CONFIG });
      axios.delete(`${globals.API_URL}/budgets/id/${budgetID}/paymentConfig`)
        .then((_) => {
          dispatch(actions.getBudgetWithAllInfos(budgetID));
          dispatch(actions.reportSuccess("Configuração de pagamento removida com sucesso!"));
          dispatch({ type: actions.TOGGLE_REMOVING_PAYMENT_CONFIG });
        })
        .catch((error) => {
          console.log("Houve um erro interno. FUNÇÃO: removePaymentConfig. " + error);
          dispatch(actions.reportError("Houve um erro ao remover a configuração do pagamento!"));
          dispatch({ type: actions.TOGGLE_REMOVING_PAYMENT_CONFIG });
        })
    }
  },

  renewToken: (budgetID, expirationDays) => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_RENEWING });
      axios.post(`${globals.API_URL}/budgets/id/${budgetID}/renew`, { expirationDays: expirationDays })
        .then((_) => {
          dispatch(actions.getBudgetWithAllInfos(budgetID));
          dispatch(actions.reportSuccess("Proposta renovada com sucesso!"));
          dispatch({ type: actions.TOGGLE_RENEWING });
        })
        .catch((error) => {
          console.log("Houve um erro interno. FUNÇÃO: renewToken. " + error);
          dispatch(actions.reportError("Houve um erro ao renovar a proposta!"));
          dispatch({ type: actions.TOGGLE_RENEWING });
        })
    }
  },

  renewProposalID: (budgetID, expirationDays) => {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_RENEWING });
      axios.post(`${globals.API_URL}/budgets/id/${budgetID}/renewProposalID`, { expirationDays: expirationDays })
        .then((_) => {
          dispatch(actions.getBudgetWithAllInfos(budgetID));
          dispatch(actions.reportSuccess("Proposta renovada com sucesso!"));
          dispatch({ type: actions.TOGGLE_RENEWING });
        })
        .catch((error) => {
          console.log("Houve um erro interno. FUNÇÃO: renewProposalID. " + error);
          dispatch(actions.reportError("Houve um erro ao renovar a proposta!"));
          dispatch({ type: actions.TOGGLE_RENEWING });
        })
    }
  },

  unloadBudgets: () => ({ type: actions.UNLOAD_BUDGETS })
};
export default actions;
