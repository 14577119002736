import actions from "./actions";

const initState = {
  allAttendances: [],
  filteredAttendances: [],
  allComments: [],
  page: 0,
  totalAttendances: 0,
  limit: 30,
  selectedTabKey: "1",
  groupLink: undefined,
  tag: undefined,
  selectedAttendance: -1,
  collaboratorList: [],
  filterAttr: { bucket: "Todos" },
  composeAttendance: false,
  replyAttendance: false,
  searchString: "",
  allCollaboratorsByProject: [],
};

export default function attendanceReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_ATTENDANCES:
      return {
        ...state,
        allAttendances: action.payload,
      };
    case actions.GET_GROUP_LINK:
      return {
        ...state,
        groupLink: action.payload,
      };
    case actions.GET_FILTERED_ATTENDANCES:
      return {
        ...state,
        filteredAttendances: action.payload,
      };
    case actions.GET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case actions.GET_ALL_COMMENTS:
      return {
        ...state,
        allComments: action.payload,
      };
    case actions.GET_ALL_COLLABORATORS_BY_PROJECT:
      return {
        ...state,
        allCollaboratorsByProject: action.payload,
      };
    case actions.GET_ALL_CURRENT_REPLIES:
      return {
        ...state,
        allCurrentReplies: action.payload.replies,
      };
    case actions.FILTER_ATTRIBUTE:
      return {
        ...state,
        composeAttendance: false,
        replyAttendance: false,
        selectedAttendance: -1,
        filterAttr: { ...action.filterAttr },
      };
    case actions.SELECTED_ATTENDANCE:
      return {
        ...state,
        replyAttendance: false,
        selectedAttendance: action.selectedAttendance,
        allAttendances: action.allAttendances,
      };
    case actions.COMPOSE_ATTENDANCE:
      return {
        ...state,
        replyAttendance: false,
        composeAttendance: action.composeAttendance,
      };
    case actions.REPLY_ATTENDANCE:
      return {
        ...state,
        replyAttendance: action.replyAttendance,
      };
    case actions.SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case actions.SET_TAB_KEY:
      return {
        ...state,
        selectedTabKey: action.payload,
      };
    case actions.GET_TOTAL_ATTENDANCES:
      return {
        ...state,
        totalAttendances: action.totalAttendances,
      };
    default:
      return state;
  }
}
