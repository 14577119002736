import globals from '../../globals';
import axios from 'axios';


const airConditioningAdditionalActions = {
    GET_ALL_AIRCONDITIONING_ADDITIONALS: 'GET_ALL_AIRCONDITIONING_ADDITIONALS',

    getAllAirConditioningAdditionals: () => {
        return (dispatch) => {
            axios.get(`${globals.API_URL}/airConditioningAdditionals/all`)
                .then((resp) => {
                    dispatch({
                        type: airConditioningAdditionalActions.GET_ALL_AIRCONDITIONING_ADDITIONALS,
                        payload: {
                            airConditioningAdditionals: resp.data.airConditioningAdditionals
                        }
                    });
                }).catch((error) => {
                    console.log("Houve um erro interno. " + error);
                })
        };
    }
};



export default airConditioningAdditionalActions;