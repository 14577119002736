import globals from '../../globals';
import axios from 'axios'

const areaTypeActions = {
  GET_ALL_AREA_TYPES: 'GET_ALL_AREA_TYPES',
  UPDATE_EDIT_AREA_TYPES_LIST: 'UPDATE_EDIT_AREA_TYPES_LIST',

  // AÇÕES REFERENTE AOS TIPOS DE ÁREAS
  getAllAreaTypes: () => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/areaTypes/all`)
        .then((resp) => {
          dispatch({
            type: areaTypeActions.GET_ALL_AREA_TYPES,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newAreaType: () => {
    const newAreaType = {
      name: "Sem Nome",
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/areaTypes/`, newAreaType)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(areaTypeActions.getAllAreaTypes())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  updateEditAllAreaTypes: (areaTypesList) => {
    return (dispatch) => {
      dispatch({
        type: areaTypeActions.UPDATE_EDIT_AREA_TYPES_LIST,
        payload: areaTypesList,
      });
    };
  },
  editAllAreaTypes: (areaTypesList) => {
    return (dispatch) => {
      areaTypesList.forEach(areaType => {
        dispatch(areaTypeActions.editAreaType(areaType.typeID, areaType))
      });
    };
  },
  editAreaType: (typeID, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/areaTypes/id/${typeID}`, values)
        .then((resp) => {
          console.log("Tipo de característica atualizada com sucesso: " + values.name);
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteAreaType: (areaType) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/areaTypes/id/${areaType.typeID}`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(areaTypeActions.getAllAreaTypes())
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  }
};
export default areaTypeActions;
