
import globals from '../../globals';
import axios from 'axios'

const galleryActions = {
  GET_CURRENT_MEDIAS_LIST: 'GET_CURRENT_MEDIAS_LIST',
  UPDATE_EDIT_MEDIAS_LIST: 'UPDATE_EDIT_MEDIAS_LIST',

  getCurrentMediasListTeam: (teamID) => {
    return (dispatch) => {
      axios.get(`${globals.API_URL}/medias/all/${teamID}/teams`)
        .then((resp) => {
          dispatch({
            type: galleryActions.GET_CURRENT_MEDIAS_LIST,
            payload: resp.data,
          });
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  newMediaTeam: (teamID, index) => {
    const newMedia = {
      index: index,
      source: `https://imagem.123projetei.com/ecommerce/teams/${teamID}/images/team_${teamID}_image_sem_imagem.jpg`,
      title: "Sem Imagem",
      description: "Essa imagem ainda não tem descrição.",
      teamID: teamID
    };
    return (dispatch) => {
      axios.post(`${globals.API_URL}/medias/`, newMedia)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(galleryActions.getCurrentMediasListTeam(teamID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  editAllMediasTeam: (mediasList) => {
    return (dispatch) => {
      mediasList.forEach(media => {
        dispatch(galleryActions.editMediaTeam(media.mediaID, media))
      });
    };
  },
  editMediaTeam: (mediaID, values) => {
    return (dispatch) => {
      axios.put(`${globals.API_URL}/medias/id/${mediaID}/teams`, values)
        .then((resp) => {
          console.log("Mídia atualizada com sucesso: " + values.title);
          dispatch(galleryActions.getCurrentMediasListTeam(values.teamID))
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  deleteMediaTeam: (media) => {
    return (dispatch) => {
      axios.delete(`${globals.API_URL}/medias/id/${media.mediaID}/teams`)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(galleryActions.getCurrentMediasListTeam(media.teamID))
          }
        }).catch((error) => {
          console.log("Houve um erro interno. " + error);
        })
    };
  },
  // AÇÕES REFERENTE ÀS MEDIAS EM GERAL
  updateEditAllMediasIndex: (mediaID, oldIndex, newIndex, mediasList) => {
    for (let i = 0; i < mediasList.length; i++) {
      if (mediasList[i].mediaID != mediaID && mediasList[i].index == newIndex) {
        mediasList[i].index = oldIndex
      }
    }
    return (dispatch) => {
      dispatch({
        type: galleryActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
  updateEditAllMedias: (mediasList) => {
    return (dispatch) => {
      dispatch({
        type: galleryActions.UPDATE_EDIT_MEDIAS_LIST,
        payload: mediasList,
      });
    };
  },
};
export default galleryActions;
