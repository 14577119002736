import actions from './actions';

const initState = {
  renderEditPopUpLoading: false,
  allPopUps: [],
  currentPopUp: {},
  backendError_newPopUp: false,
  backendSuccess_newPopUp: false,
  backendError_editPopUp: false,
  backendSuccess_editPopUp: false
}

export default function popUpReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_POP_UPS:
      return {
        ...state,
        backendError_newPopUp: false,
        backendSuccess_newPopUp: false,
        backendError_editPopUp: false,
        backendSuccess_editPopUp: false,
        allPopUps : action.payload.popups
      }
    case actions.LOADING_CURRENT_POP_UP:
      return {
        ...state,
        renderEditPopUpLoading: true
      };
    case actions.GET_CURRENT_POP_UP:
      return {
        ...state,
        renderEditPopUpLoading: false,
        currentPopUp : action.payload.popup
      };
    case actions.NEW_POP_UP_RESET:
      return {
        ...state,
        backendError_newPopUp: false,
        backendSuccess_newPopUp: false,
      };
    case actions.NEW_POP_UP_LOADING:
      return {
        ...state,
        backendError_newPopUp: false,
        backendSuccess_newPopUp: false,
      };
    case actions.NEW_POP_UP_SUCCESS:
      return {
        ...state,
        backendError_newPopUp: false,
        backendSuccess_newPopUp: true,
      };
    case actions.NEW_POP_UP_ERROR:
      return {
        ...state,
        backendError_newPopUp: true,
        backendSuccess_newPopUp: false,
      };
    case actions.EDIT_POP_UP_LOADING:
      return {
        ...state,
        backendError_editPopUp: false,
        backendSuccess_editPopUp: false,
      };
    case actions.EDIT_POP_UP_SUCCESS:
      return {
        ...state,
        backendError_editPopUp: false,
        backendSuccess_editPopUp: true,
      };
    case actions.EDIT_POP_UP_ERROR:
      return {
        ...state,
        backendError_editPopUp: true,
        backendSuccess_editPopUp: false,
      };
    default:
      return state;
  }
}