import actions from './actions';



const initState = {
  allHydrosanitaryAdditionals: []
};



export default function hydrosanitaryAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_HYDROSANITARY_ADDITIONALS:
            return {
                ...state,
                allHydrosanitaryAdditionals: action.payload.hydrosanitaryAdditionals,
            };
        default:
            return state;
    }
}