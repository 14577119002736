import axios from "axios";
import globals from "../../globals";

const baseName = "WHATSAPP_GROUPS";
const ax = axios.create({
  baseURL: globals.API_URL,
});

const actions = {
  GET_ALL: `GET_ALL_${baseName}`,
  UPDATE_GROUPS: `UPDATE_${baseName}`,
  IS_LOADING: `${baseName}_LOADING`,
  IS_ERROR: `${baseName}_ERROR`,
  IS_SUCCESS: `${baseName}_SUCCESS`,
  CLEAR_ERRORS: `${baseName}_CLEAR_ERRORS`,
  GROUP_SELECTED: `${baseName}_GROUP_SELECTED`,
  GET_INSTANCE_INFO: `${baseName}_GET_INSTANCE_INFO`,

  getAllWhatsAppGroups: ({ page = 1 }) => {
    return (dispatch, getState) => {
      if (!page) {
        page = getState().WhatsAppGroups.currentPage;
      }
      console.time("Loading time:");
      dispatch({ type: actions.IS_LOADING });
      ax.get(`/whatsAppGroups?page=${page}`)
        .then((resp) => {
          console.timeEnd("Loading time:");
          const groups = resp.data.groups.map((group) => ({
            ...group,
            key: `group-${group.groupID}`,
          }));
          const totalPages = resp.data.lastPage;
          const currentPage = resp.data.currentPage;
          const total = resp.data.total;

          dispatch({
            type: actions.GET_ALL,
            payload: { groups, totalPages, currentPage, total },
          });
          dispatch({ type: actions.CLEAR_ERRORS });

          return resp.data.groups;
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("houve um erro"));
        });
    };
  },

  addMemberToGroup: ({ participantPhone, groupID, chatID }) => {
    return (dispatch) => {
      if (participantPhone.length < 10 || participantPhone.length > 13) {
        return dispatch(
          actions.reportError(
            "O número de telefone deve conter entre 10 a 13 números"
          )
        );
      }
      dispatch({ type: actions.IS_LOADING });
      ax.post(`/whatsAppGroups/${groupID}/addMember`, {
        participantPhone,
        chatID,
      })
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200 && resp.data.success) {
            dispatch(actions.getAllWhatsAppGroups({}));
            dispatch(actions.reportSuccess("Membro adicionado com sucesso!"));
          } else {
            dispatch(actions.reportError(resp.data.message));
          }
        })
        .catch((error) => {
          console.error("Houve um erro interno. ", error.response?.data);
          dispatch(actions.reportError(error.response?.data?.message));
        });
    };
  },

  syncGroupInfo: ({ groupID }) => {
    return (dispatch) => {
      dispatch({ type: actions.IS_LOADING });
      ax.put(`/whatsAppGroups/${groupID}/syncGroup`)
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            dispatch(actions.reportSuccess("Grupo criado com sucesso!"));
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o grupo!"));
        })
        .finally(() => {
          dispatch(actions.getAllWhatsAppGroups({}));
        });
    };
  },

  addClientToGroup: ({ participantPhone, groupID, chatID }) => {
    return (dispatch) => {
      if (participantPhone.length < 10 || participantPhone.length > 13) {
        return dispatch(
          actions.reportError(
            "O número de telefone deve conter entre 10 a 13 números"
          )
        );
      }
      dispatch({ type: actions.IS_LOADING });
      ax.post(`/whatsAppGroups/${groupID}/addMember?client=true`, {
        participantPhone,
        chatID,
      })
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200 && resp.data.success) {
            dispatch(actions.reportSuccess("Membro adicionado com sucesso!"));
          } else {
            dispatch(actions.reportError(resp.data.message));
          }
        })
        .catch((error) => {
          console.error("Houve um erro interno. ", error.response?.data);
          dispatch(actions.reportError(error.response?.data?.message));
        })
        .finally(() => dispatch(actions.getAllWhatsAppGroups({})));
    };
  },

  createWhatsAppGroup: ({ phones, groupName, messageText }) => {
    return (dispatch) => {
      dispatch({ type: actions.IS_LOADING });
      ax.post(`/whatsAppGroups/new`, {
        phones,
        groupName,
        messageText,
      })
        .then((resp) => {
          if (resp.status === 201) {
            dispatch(actions.reportSuccess("Grupo criado com sucesso!"));
          } else {
            dispatch(
              actions.reportError(
                "Houve um erro ao criar o grupo: ",
                resp.data.message
              )
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
          dispatch(actions.reportError("Houve um erro ao criar o grupo."));
        })
        .finally(() => dispatch(actions.getAllWhatsAppGroups({})));
    };
  },

  promoteMemberToAdmin: ({ participantPhone, groupID, chatID, whatsAppID }) => {
    return (dispatch) => {
      dispatch({ type: actions.IS_LOADING });
      ax.put(`/whatsAppGroups/${groupID}/promoteMember`, {
        participantPhone,
        groupID,
        chatID,
        whatsAppID,
      })
        .then((resp) => {
          if (resp.status === 200 && resp.data.success) {
            dispatch(actions.reportSuccess("Usuário promovido com sucesso!"));
          } else {
            dispatch(
              "Houve um erro: " + actions.reportError(resp.data.message)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o grupo!"));
        })
        .finally(() => dispatch(actions.getAllWhatsAppGroups({})));
    };
  },

  promoteMemberToClient: ({
    participantPhone,
    groupID,
    chatID,
    whatsAppID,
  }) => {
    return (dispatch) => {
      dispatch({ type: actions.IS_LOADING });
      ax.put(`/whatsAppGroups/${groupID}/promoteMember?client=true`, {
        participantPhone,
        chatID,
        whatsAppID,
      })
        .then((resp) => {
          if (resp.status === 200 && resp.data.success) {
            dispatch(actions.reportSuccess("Cliente adicionado com sucesso!"));
          } else {
            dispatch(
              "Houve um erro: " + actions.reportError(resp.data.message)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o grupo!"));
        })
        .finally(() => dispatch(actions.getAllWhatsAppGroups({})));
    };
  },

  demoteWhatsAppGroupMember: ({
    participantPhone,
    groupID,
    chatID,
    whatsAppID,
  }) => {
    return (dispatch) => {
      dispatch({ type: actions.IS_LOADING });
      ax.put(`/whatsAppGroups/${groupID}/demoteMember`, {
        participantPhone,
        groupID,
        chatID,
        whatsAppID,
      })
        .then((resp) => {
          if (resp.status === 200 && resp.data.success) {
            dispatch(actions.reportSuccess("Usuário rebaixado com sucesso!"));
          } else {
            dispatch(
              "Houve um erro: " + actions.reportError(resp.data.message)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o grupo!"));
        })
        .finally(() => dispatch(actions.getAllWhatsAppGroups({})));
    };
  },

  removeMemberFromGroup: ({
    participantPhone,
    groupID,
    chatID,
    whatsAppID,
  }) => {
    return (dispatch) => {
      console.log({ participantPhone, groupID, chatID, whatsAppID });
      dispatch({ type: actions.IS_LOADING });
      ax.put(`/whatsAppGroups/${groupID}/removeMember`, {
        participantPhone,
        groupID,
        chatID,
        whatsAppID,
      })
        .then((resp) => {
          if (resp.status === 200 && resp.data.success) {
            dispatch(actions.reportSuccess("Usuário removido com sucesso!"));
          } else {
            dispatch(
              "Houve um erro: " + actions.reportError(resp.data.message)
            );
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno: " + error);
          dispatch(actions.reportError("Houve um erro ao atualizar o grupo!"));
        })
        .finally(() => dispatch(actions.getAllWhatsAppGroups({})));
    };
  },

  reportError: (message) => {
    return (dispatch) => {
      dispatch({
        type: actions.IS_ERROR,
        payload: { message },
      });
    };
  },

  reportSuccess: (message) => {
    return (dispatch) => {
      dispatch({
        type: actions.IS_SUCCESS,
        payload: { message },
      });
    };
  },

  selectGroup: (index) => {
    return (dispatch) => {
      dispatch({ type: actions.GROUP_SELECTED, payload: { index } });
    };
  },

  getInstanceStatus: () => {
    return (dispatch) => {
      ax.get(`/whatsAppGroups/melissaStatus`)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: actions.GET_INSTANCE_INFO,
              payload: { instanceInfo: res.data },
            });
          } else {
            dispatch(
              "Houve um erro: " + actions.reportError(res.data?.message)
            );
            dispatch({
              type: actions.GET_INSTANCE_INFO,
              payload: {
                instanceInfo: {
                  status: {
                    accountStatus: "Chat API Error",
                  },
                  phoneInfo: {
                    id: "???????????@c.us",
                    battery: "???????",
                    locale: "??????",
                    name: "??????",
                    wa_version: "???????",
                    device: {},
                    avatar: "????",
                  },
                },
              },
            });
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar dados da instância", error);
          dispatch(
            actions.reportError(
              "Erro ao buscar informações sobre a Melissa. Caso não "
            )
          );
        });
    };
  },

  rebootInstance: () => {
    return (dispatch) => {
      dispatch({
        type: actions.IS_LOADING,
      });
      ax.post(`/whatsAppGroups/rebootInstance`)
        .then((res) => {
          if (res.status === 200) {
            console.info(res.data);
            actions.reportSuccess(
              "A instância está sendo reiniciada, por favor aguarde alguns segundos"
            );
          } else {
            dispatch("Houve um erro: " + actions.reportError(res.data));
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar dados da instância", error);
          dispatch(actions.reportError("Erro ao Reiniciar instância"));
        });
    };
  },
};
export default actions;
