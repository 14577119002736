import actions from './actions';



const initState = {
  allInteriorDesignAdditionals: []
};



export default function interiorDesignAdditionalReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_INTERIOR_DESIGN_ADDITIONALS:
            return {
                ...state,
                allInteriorDesignAdditionals: action.payload.interiorDesignAdditionals,
            };
        default:
            return state;
    }
}