import globals from "../../globals";
import axios from "axios";
import moment from "moment";

const attendanceActions = {
  GET_ALL_ATTENDANCES: "GET_ALL_ATTENDANCES",
  GET_FILTERED_ATTENDANCES: "GET_FILTERED_ATTENDANCES",
  GET_ALL_COMMENTS: "GET_ALL_COMMENTS",
  GET_GROUP_LINK: "GET_GROUP_LINK",
  SET_TAB_KEY: "SET_TAB_KEY",
  LOADING_GET_ALL_ATTENDANCES: "LOADING_GET_ALL_ATTENDANCES",
  FILTER_ATTRIBUTE: "FILTER_ATTRIBUTE",
  SELECTED_ATTENDANCE: "SELECTED_ATTENDANCE",
  COMPOSE_ATTENDANCE: "COMPOSE_ATTENDANCE",
  REPLY_ATTENDANCE: "REPLY_ATTENDANCE",
  SEARCH_STRING: "SEARCH_STRING",
  GET_PAGE: "GET_PAGE",
  GET_TOTAL_ATTENDANCES: "GET_TOTAL_ATTENDANCES",
  GET_LIMIT: "GET_LIMIT",
  GET_ALL_COLLABORATORS_BY_PROJECT: "GET_ALL_COLLABORATORS_BY_PROJECT",
  getGroupLink: (projectCode) => {
    return (dispatch, getState) => {
      axios
        .get(`${globals.API_URL}/whatsappgroups/${projectCode}/inviteLink`)
        .then((resp) => {
          const { inviteLink } = resp.data;
          dispatch({
            type: attendanceActions.GET_GROUP_LINK,
            payload: inviteLink,
          });
        })
        .catch(
          dispatch({
            type: attendanceActions.GET_GROUP_LINK,
            payload: undefined,
          })
        );
    };
  },
  getAllAttendances: () => {
    let dateCheck;
    return (dispatch, getState) => {
      const tabKey = getState().Attendances.selectedTabKey;
      switch (tabKey) {
        case "1": {
          // Seleciona todos os atendimentos que são de responsabilidade de um único projetista
          axios
            .get(
              `${globals.API_URL}/attendances/allByCollaboratorProject/${
                getState().Auth.user.collaborator.email
                // }?page=${getState().Attendances.page}&limit=${
                //   getState().Attendances.limit
              }`
            )
            .then((resp) => {
              const attendancesToUpdate = resp.data.map((att) => {
                var dateNow = new Date();
                if (att.active) att.bucket = "Ativos";
                else att.bucket = "Inativos";
                att.stringCreatedAt = att.createdAt;
                att.createdAt = new Date(
                  new Date(att.createdAt).getTime() + 3 * 3600 * 1000
                );
                dateCheck = (dateNow - moment(att.createdAt)) / 1000 / 3600;
                if (att.bucket === "Inativos") {
                  att.tags = "Encerrados";
                } else if (dateCheck < 24) {
                  att.tags = "Até 1 dia útil";
                } else if (dateCheck >= 24 && dateCheck < 48) {
                  att.tags = "Até 2 dias úteis";
                } else if (dateCheck >= 48 && dateCheck < 72) {
                  att.tags = "Até 3 dias úteis";
                } else if (dateCheck >= 72 && dateCheck < 96) {
                  att.tags = "Até 4 dias úteis";
                } else if (dateCheck >= 96) {
                  att.tags = "Mais de 4 dias úteis";
                }
                return att;
              });
              dispatch({
                type: attendanceActions.GET_ALL_ATTENDANCES,
                payload: attendancesToUpdate,
              });
              dispatch({
                type: attendanceActions.SET_TAB_KEY,
                payload: "1",
              });
              dispatch(attendanceActions.changePage(0));
              dispatch(
                attendanceActions.getTotalAttendances(
                  getState().Auth.user.collaborator.email
                )
              );
            })
            .catch((error) => {
              console.log("Houve um erro interno. " + error);
            });
          break;
        }
        case "2": {
          // Seleciona todos os atendimentos que são de projetos relacionados ao projetista
          axios
            .get(
              `${globals.API_URL}/attendances/allRelatedAttendances/${
                getState().Auth.user.collaborator.email
                // }?page=${getState().Attendances.page}&limit=${
                //   getState().Attendances.limit
              }`
            )
            .then((resp) => {
              const filterAtt = resp.data.filter((att) =>
                att.ClientProject.CollaboratorProjects.find(
                  (a) => a.email === getState().Auth.user.collaborator.email
                )
              );

              const attendancesToUpdate = filterAtt.map((att) => {
                att.CollaboratorProject = {
                  ...att.ClientProject.CollaboratorProjects.find(
                    (a) => a.collaboratorProjectID === att.collaboratorProjectID
                  ),
                };
                // console.log("to no map");
                // console.log(att.ClientProject.CollaboratorProjects);
                var dateNow = new Date();
                if (att.active) att.bucket = "Ativos";
                else att.bucket = "Inativos";
                att.stringCreatedAt = att.createdAt;
                att.createdAt = new Date(
                  new Date(att.createdAt).getTime() + 3 * 3600 * 1000
                );
                dateCheck = (dateNow - moment(att.createdAt)) / 1000 / 3600;
                if (att.bucket === "Inativos") {
                  att.tags = "Encerrados";
                } else if (dateCheck < 24) {
                  att.tags = "Até 1 dia útil";
                } else if (dateCheck >= 24 && dateCheck < 48) {
                  att.tags = "Até 2 dias úteis";
                } else if (dateCheck >= 48 && dateCheck < 72) {
                  att.tags = "Até 3 dias úteis";
                } else if (dateCheck >= 72 && dateCheck < 96) {
                  att.tags = "Até 4 dias úteis";
                } else if (dateCheck >= 96) {
                  att.tags = "Mais de 4 dias úteis";
                }
                return att;
              });
              dispatch({
                type: attendanceActions.GET_ALL_ATTENDANCES,
                payload: attendancesToUpdate,
              });
              dispatch(attendanceActions.changePage(0));
              dispatch(
                attendanceActions.getTotalAttendances(
                  getState().Auth.user.collaborator.email
                )
              );
            })
            .catch((error) => {
              console.log("Houve um erro interno. " + error);
            });
          break;
        }

        case "3": // Seleciona todos os atendimentos
        default: {
          axios
            .get(
              `${globals.API_URL}/attendances/all`
              // ?page=${
              //   getState().Attendances.page
              // }&limit=${getState().Attendances.limit}`
            )
            .then((resp) => {
              const attendancesToUpdate = resp.data.map((att) => {
                var dateNow = new Date();
                if (att.active) att.bucket = "Ativos";
                else att.bucket = "Inativos";
                att.stringCreatedAt = att.createdAt;
                att.createdAt = new Date(
                  new Date(att.createdAt).getTime() + 3 * 3600 * 1000
                );
                dateCheck = (dateNow - moment(att.createdAt)) / 1000 / 3600;

                if (att.bucket === "Inativos") {
                  att.tags = "Encerrados";
                } else if (dateCheck < 24) {
                  att.tags = "Até 1 dia útil";
                } else if (dateCheck >= 24 && dateCheck < 48) {
                  att.tags = "Até 2 dias úteis";
                } else if (dateCheck >= 48 && dateCheck < 72) {
                  att.tags = "Até 3 dias úteis";
                } else if (dateCheck >= 72 && dateCheck < 96) {
                  att.tags = "Até 4 dias úteis";
                } else if (dateCheck >= 96) {
                  att.tags = "Mais de 4 dias úteis";
                }
                return att;
              });
              dispatch({
                type: attendanceActions.GET_ALL_ATTENDANCES,
                payload: attendancesToUpdate,
              });
              dispatch(attendanceActions.getTotalAttendances());
              dispatch(attendanceActions.makeAttendancePage());
            })
            .catch((error) => {
              console.log("Houve um erro interno. " + error);
            });
          break;
        }
      }
    };
  },
  makeAttendancePage: () => {
    return (dispatch, getState) => {
      const { allAttendances } = getState().Attendances;

      dispatch({
        type: attendanceActions.GET_FILTERED_ATTENDANCES,
        payload: allAttendances,
      });
    };
  },
  getAttendanceComments: (attendanceID) => {
    return (dispatch) => {
      axios
        .get(`${globals.API_URL}/attendances/${attendanceID}/comments/`)
        .then((resp) => {
          dispatch({
            type: attendanceActions.GET_ALL_COMMENTS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  getAllCollaboratorsByProject: (projectCode) => {
    return (dispatch) => {
      axios
        .get(
          `${globals.API_URL}/attendances/allCollaboratorbyProject/${projectCode}`
        )
        .then((resp) => {
          const { CollaboratorProjects } = resp.data;
          const allCollaboratorsByProject = CollaboratorProjects.map((coll) => {
            const { collaboratorProjectID, type, email, Collaborator } = coll;
            return {
              collaboratorProjectID,
              type,
              email,
              name: Collaborator.name,
            };
          });
          dispatch({
            type: attendanceActions.GET_ALL_COLLABORATORS_BY_PROJECT,
            payload: allCollaboratorsByProject,
          });
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  filterAction: (newFilterAttr) => {
    return (dispatch, getState) => {
      const filterAttr = getState().Attendances.filterAttr;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          filterAttr.bucket = newFilterAttr.bucket;
          delete filterAttr.tag; // = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
          // filterAttr.bucket = "Todos";
        }
      }
      dispatch({
        type: attendanceActions.FILTER_ATTRIBUTE,
        filterAttr,
      });
    };
  },
  selectAttendance: (selectedAttendance) => {
    return (dispatch, getState) => {
      const allAttendances = getState().Attendances.allAttendances;
      const actualAttendance = allAttendances.find(
        (attendance) => attendance.attendanceID === selectedAttendance
      );
      dispatch(attendanceActions.getAttendanceComments(selectedAttendance));

      dispatch(
        attendanceActions.getGroupLink(
          actualAttendance.ClientProject.projectCode
        )
      );
      dispatch(
        attendanceActions.getAllCollaboratorsByProject(
          actualAttendance.ClientProject.projectCode
        )
      );
      dispatch({
        type: attendanceActions.SELECTED_ATTENDANCE,
        selectedAttendance,
        allAttendances,
      });
    };
  },

  changeComposeAttendance: (composeAttendance) => ({
    type: attendanceActions.COMPOSE_ATTENDANCE,
    composeAttendance,
  }),
  changeSearchString: (searchString) => ({
    type: attendanceActions.SEARCH_STRING,
    searchString,
  }),

  finishAttendance: (attendanceID, status) => {
    return (dispatch, getState) => {
      axios
        .put(`${globals.API_URL}/attendances/finish/${attendanceID}/${status}`)
        .then((resp) => {
          if (resp.status === 200) {
            const today = new Date();
            dispatch(
              attendanceActions.newReply(attendanceID, {
                title: `Atendimento Finalizado`,
                content: `Atendimento finalizado por ${
                  getState().Auth.user.collaborator.name
                }, em ${today.getDate()}/${String(
                  today.getMonth() + 1
                ).padStart(2, "0")}/${today.getFullYear()}`,
                deletable: false,
                authorEmail: getState().Auth.user.collaborator.email,
              })
            );
            dispatch(attendanceActions.getAllAttendances());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  updateAttendanceStatus: (attendanceID, status) => {
    return (dispatch, getState) => {
      axios
        .put(`${globals.API_URL}/attendances/update/${attendanceID}/${status}`)
        .then((resp) => {
          const today = new Date();
          dispatch(
            attendanceActions.newReply(attendanceID, {
              title: `Status Atualizado`,
              content: `Status do tendimento atualizado para "${status}", por ${
                getState().Auth.user.collaborator.name
              }, em ${today.getDate()}/${String(today.getMonth() + 1).padStart(
                2,
                "0"
              )}/${today.getFullYear()}`,
              deletable: false,
              authorEmail: getState().Auth.user.collaborator.email,
            })
          );
          dispatch(attendanceActions.getAllAttendances());
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  changeReplyAttendance: (replyAttendance) => ({
    type: attendanceActions.REPLY_ATTENDANCE,
    replyAttendance,
  }),
  newReply: (id, values) => {
    return (dispatch) => {
      axios
        .post(`${globals.API_URL}/attendances/${id}/comment/`, values)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch({
              type: attendanceActions.REPLY_ATTENDANCE,
              replyAttendance: false,
            });
            dispatch(attendanceActions.getAllAttendances());
          }
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  deleteComment: (values) => {
    return (dispatch) => {
      axios
        .delete(
          `${globals.API_URL}/attendances/comment/${values.attendanceCommentID}`,
          values
        )
        .then(() => {
          dispatch({
            type: attendanceActions.REPLY_ATTENDANCE,
            replyAttendance: false,
          });
          dispatch(attendanceActions.getAllAttendances());
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  changeCollaboratorProject: (attendanceID, newCollaborator) => {
    const today = new Date();
    return (dispatch, getState) => {
      axios
        .put(
          `${globals.API_URL}/attendances/changeCollaborator/${attendanceID}/${newCollaborator.collaboratorProjectID}`
        )
        .then(() => {
          const attendanceToUpdate = getState().Attendances.allAttendances.find(
            (a) => a.attendanceID === getState().Attendances.selectedAttendance
          );

          dispatch(
            attendanceActions.newReply(attendanceID, {
              title: `Transferência de atendimento`,
              content: `De: ${
                attendanceToUpdate.CollaboratorProject.Collaborator.name
              }\nPara: ${newCollaborator.name}\nAutor: ${
                getState().Auth.user.collaborator.name
              }, em ${today.getDate()}/${String(today.getMonth() + 1).padStart(
                2,
                "0"
              )}/${today.getFullYear()}`,
              deletable: false,
              authorEmail: getState().Auth.user.collaborator.email,
            })
          );
          dispatch(attendanceActions.getAllAttendances());
        })
        .catch((error) => {
          console.log("Houve um erro interno. " + error);
        });
    };
  },
  selectTabKey: (selectedTabKey) => {
    return (dispatch) => {
      dispatch({ type: attendanceActions.SET_TAB_KEY, selectedTabKey });
    };
  },
  changePage: (page) => {
    return (dispatch) => {
      dispatch({ type: attendanceActions.GET_PAGE, payload: page });
      // dispatch(attendanceActions.getAllAttendances());
    };
  },
  getTotalAttendances: (email) => {
    return (dispatch, getState) => {
      dispatch({
        type: attendanceActions.GET_TOTAL_ATTENDANCES,
        totalAttendances: getState().Attendances.allAttendances.length,
      });
      // email
      //   ? axios
      //       .get(
      //         `${globals.API_URL}/attendances/countAttendances?email=${email}`
      //       )
      //       .then((resp) => {
      //         dispatch({
      //           type: attendanceActions.GET_TOTAL_ATTENDANCES,
      //           totalAttendances: resp.data.totalAttendances,
      //         });
      //       })
      //   : axios
      //       .get(`${globals.API_URL}/attendances/countAttendances`)
      //       .then((resp) => {
      //         dispatch({
      //           type: attendanceActions.GET_TOTAL_ATTENDANCES,
      //           totalAttendances: resp.data.totalAttendances,
      //         });
      //       });
    };
  },
};
export default attendanceActions;
