import partnersActions from "./actions";

const initialState = {
  allPhysicalPartners: [],
  currentPhysicalPartner: null,
  currentLegalPartner: null,
  allPlans: null,
  allLegalPartners: []
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case partnersActions.GET_ALL_PARTNER_PLANS:
      return {
        ...state,
        allPlans: action.payload.partnerPlans,
      };
    case partnersActions.GET_ALL_PARTNER_PLANS_LOADING:
      return {
        ...state,
        allPlans: null,
      };
    case partnersActions.GET_ALL_PHYSICAL_PARTNERS:
      return {
        ...state,
        allPhysicalPartners: action.payload.physicalPartners,
      };
    case partnersActions.GET_ALL_LEGAL_PARTNERS:
      return {
        ...state,
        allLegalPartners: action.payload.legalPartners,
      };
    case partnersActions.GET_CURRENT_PHYSICAL_PARTNER:
      return {
        ...state,
        currentPhysicalPartner: action.payload.physicalPartner,
      };
    case partnersActions.GET_CURRENT_LEGAL_PARTNER:
      return {
        ...state,
        currentLegalPartner: action.payload.legalPartner,
      };
    case partnersActions.GET_CURRENT_PHYSICAL_PARTNER_LOADING:
      return {
        ...state,
        currentPhysicalPartner: null,
      };
    case partnersActions.GET_CURRENT_LEGAL_PARTNER_LOADING:
      return {
        ...state,
        currentLegalPartner: null,
      };
    default:
      return state;
  }
}
