import productTypeActions from './actions';

const initState = {
  allProductTypes: [], 
  renderLoading : false,
  currentProductType : {},
  currentProductTypesList : []
};

export default function interiorDesignProductTypeReducer(state = initState, action) {
  switch (action.type) {
    case productTypeActions.GET_ALL_PRODUCT_TYPES:
      return {
        ...state,
        allProductTypes: action.payload.types,
        renderLoading : false
      };
    case productTypeActions.GET_CURRENT_PRODUCT_TYPE_LIST:
      return {
        ...state,
        currentProductTypesList : action.payload.types
      };
    case productTypeActions.GET_CURRENT_PRODUCT_TYPE_LOADING:
      return {
        ...state,
        currentProductType: {}
      };
    case productTypeActions.GET_CURRENT_PRODUCT_TYPE:
      return {
        ...state,
        currentProductType: action.payload.type,
      };
    case productTypeActions.GET_ALL_PRODUCT_TYPES_LOADING:
      return {
        ...state,
        renderLoading : true,
      };
    case productTypeActions.UPDATE_EDIT_PRODUCT_TYPES_LIST:
      return {
        ...state,
        currentProductTypesList: action.payload,
      };
    default:
      return state;
  }
}