import actions from './actions';



const initState = {
  allCoupons: [],
  tag: 'Disponíveis',
  selectedCoupon: null,
  filterAttr: { bucket: 'Todos', tag: 'Disponíveis' },
  composeCoupon: false,
  searchString: '',
  editView: false,
  createView: false,
  couponError: false,
  couponSuccess: false
};


export default function couponReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_COUPONS:
      return {
        ...state,
        allCoupons: action.payload.coupons,
      };

    case actions.FILTER_ATTRIBUTE:
      return {
        ...state,
        composeCoupon: false,
        editView: false,
        createView: false,
        filterAttr: { ...action.filterAttr },
      };

    case actions.SELECTED_COUPON:
      return {
        ...state,
        editView: false,
        createView: false,
        selectedCoupon: action.selectedCoupon
      };

    case actions.COMPOSE_COUPON:
      return {
        ...state,
        replyCoupon: false,
        composeCoupon: action.composeCoupon,
      };

    case actions.SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString
      };

    case actions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
      };

    case actions.CREATE_VIEW:
      return {
        ...state,
        createView: action.view,
      };

    case actions.REPORT_SUCCESS:
      return {
        ...state,
        couponSuccess: true,
        couponError: false
      }

    case actions.REPORT_ERROR:
      return {
        ...state,
        couponSuccess: false,
        couponError: true
      }

    case actions.CLEAR_STATUS:
      return {
        ...state,
        couponError: false,
        couponSuccess: false
      }

    default:
      return state;
  }
}